
import { defineComponent } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import LoadDetailMap from '@/components/loads/detail/LoadDetailMap.vue';
import LoadDetailTrackingStop from '@/components/loads/detail/tracking/LoadDetailTrackingStop.vue';
import LoadDetailTranckingStopPoint from '@/components/loads/detail/tracking/LoadDetailTranckingStopPoint.vue';

export default defineComponent({
  name: 'LoadDetailTracking',
  components: { LoadDetailTranckingStopPoint, LoadDetailTrackingStop, LoadDetailMap, PlanButton },
})
