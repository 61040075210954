
import { defineComponent, computed } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import moment from 'moment'

export default defineComponent({
  name: 'PlanCarrierRfpQuoteSubHeader',
  components: { PlanIcon, PlanAvatar, PlanButtonIcon },
  setup () {
    const store = useStore()

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const openChat = () => {
      store.commit('LoadsStore/loadId', store.getters['CarrierStore/getLoadData'].id);
      store.commit('ChatsStore/setChatId', 9317)
      store.commit('GlobalStore/setOpenChat', true)
    }

    const shipperOwner = computed(() => {
      return {
        user: store.getters['CarrierStore/getLoadData'].shipperOwner.name,
        company: store.getters['CarrierStore/getLoadData'].shipperOwner.surname
      }
    })

    const stopsData = computed(() => {
      let pickup = ''
      let pickupDate = ''
      let delivery = ''
      let deliveryDate = ''

      store.getters['CarrierStore/getLoadData'].stops.forEach((stop: any) => {
        if (stop.type === 'PICKUP_INITIAL') {
          pickupDate = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? moment(stop.executionDate).format('MMM DD, YYYY') : moment(stop.executionDate).format('DD MMM, YYYY')
          pickup = `${stop.location.city}, ${stop.location.state.iso} ${stop.location.state.country.iso}`
        }
        if (stop.type === 'DELIVERY_FINAL') {
          deliveryDate = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? moment(stop.executionDate).format('MMM DD, YYYY') : moment(stop.executionDate).format('DD MMM, YYYY')
          delivery = `${stop.location.city}, ${stop.location.state.iso} ${stop.location.state.country.iso}`
        }
      })

      return {
        pickup: pickup,
        pickupDate: pickupDate,
        delivery: delivery,
        deliveryDate: deliveryDate
      }
    })

    return {
      actualWidth,
      openChat,
      shipperOwner,
      stopsData
    }
  }
})
