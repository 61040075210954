import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "plan-default-windows" }
const _hoisted_2 = { class: "plan-default-windows-modal" }
const _hoisted_3 = { class: "plan-default-windows-modal-nav" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex justify-start items-center"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "w-full h-full flex justify-center items-center"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "plan-default-windows-modal-header"
}
const _hoisted_9 = { class: "w-full flex justify-start items-start" }
const _hoisted_10 = {
  key: 1,
  class: "plan-default-windows-modal-sticky"
}
const _hoisted_11 = { class: "w-full flex justify-start items-start" }
const _hoisted_12 = {
  key: 2,
  class: "mt-8 mb-4"
}
const _hoisted_13 = {
  key: 3,
  class: "plan-default-windows-modal-content"
}
const _hoisted_14 = {
  key: 1,
  class: "plan-default-windows-modal-content-right w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_plan_default_windows_tab = _resolveComponent("plan-default-windows-tab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
      class: "plan-default-windows-bg"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.$slots['nav'])
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "nav")
            ]))
          : _createCommentVNode("", true),
        (_ctx.showNavRightButtons)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "nav-right")
            ]))
          : (_openBlock(), _createBlock(_component_plan_button_icon, {
              key: 2,
              onOnClick: _ctx.close,
              type: "white-transparent",
              icon: "close"
            }, null, 8, ["onOnClick"]))
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_plan_loading)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_ctx.$slots['header'])
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _renderSlot(_ctx.$slots, "header")
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.$slots['sticky'])
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _renderSlot(_ctx.$slots, "sticky")
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.tabs && _ctx.tabs.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_plan_default_windows_tab, {
                    onChangeTab: _ctx.changeTab,
                    align: "around",
                    tabs: _ctx.tabs
                  }, null, 8, ["onChangeTab", "tabs"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.$slots['content'])
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _renderSlot(_ctx.$slots, "content")
                ]))
              : _createCommentVNode("", true),
            (_ctx.$slots['content-left'] || _ctx.$slots['content-right'])
              ? (_openBlock(), _createElementBlock("div", {
                  key: 4,
                  class: _normalizeClass(["flex justify-between items-start gap-5 p-5", _ctx.windowWidth < 1280 ? 'flex-col':''])
                }, [
                  (_ctx.$slots['content-left'])
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["plan-default-windows-modal-content-left", _ctx.windowWidth < 1280 ? 'w-full':'w-8/12'])
                      }, [
                        _renderSlot(_ctx.$slots, "content-left")
                      ], 2))
                    : _createCommentVNode("", true),
                  (_ctx.$slots['content-right'])
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _renderSlot(_ctx.$slots, "content-right")
                      ]))
                    : _createCommentVNode("", true)
                ], 2))
              : _createCommentVNode("", true)
          ]))
    ])
  ]))
}