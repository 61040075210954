
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import LoadAssignConfirmation from '@/modules/carrier/rfp/LoadAssignConfirmation/LoadAssignConfirmation.vue';
import LoadAssignExpired from '@/modules/carrier/rfp/LoadAssignConfirmation/LoadAssignExpired.vue';
import RefusedOffer from '@/modules/carrier/rfp/LoadAssignConfirmation/RefusedOffer.vue';
// Types
import { LoadStatus } from '@/services/loads/LoadsTypes';

export default defineComponent({
  components: {
    LoadAssignConfirmation,
    LoadAssignExpired,
    RefusedOffer,
  },
  mixins: [BaseComponent],
  data() {
    return {
      currentStatus: '',
      currentAssignConfirmation: {} as any,
    };
  },
  async created(): Promise<void> {
    this.fetchAllInfo();
  },
  computed: {
    currentLane(): any {
      return this.$store.getters['RFPStore/getCurrentLane'];
    },
  },
  methods: {
    async fetchAllInfo() {
      const id = this.$route.params.id;
      const hash: any = this.$route.params.hash;
      const code: any = this.$route.params.code;
      if (hash && code) {
        await this.$store
          .dispatch('UserStore/hashValidation', {
            hashId: hash,
            secureCode: code,
          })
          .then(() => {
            this.$store
              .dispatch('RFPStore/getAssignConfirmation', this.$route.params.assignId)
              .then((response) => {
                this.currentAssignConfirmation = response;
                this.handleCurrentStatus(
                  this.currentAssignConfirmation.status,
                  this.currentAssignConfirmation.shipment?.id,
                );

                this.$store
                  .dispatch('RFPStore/getLaneByIdCarrier', {
                    projectId: this.currentAssignConfirmation?.quote?.lane?.id,
                    page: 1,
                  })
                  .then(() => {
                    //
                  })
                  .catch((error) => {
                    console.error('Error fetching lanes:', error);
                  });
              });
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.title);
            this.$store.commit('UserStore/setShowHash', true);
          });
      }
    },
    handleCurrentStatus(status, shipment) {
      switch (status) {
        case LoadStatus.WAITING:
          this.currentStatus = status;
          break;
        case LoadStatus.EXPIRED:
          this.currentStatus = status;
          break;
        case LoadStatus.DECLINE:
          this.currentStatus = status;
          break;
        case LoadStatus.CONFIRMED:
          this.currentStatus = status;
          //   https://dev-planning.cargobot.io/carrier/shipment/4797/38c91523f29a6212/7ysEVY1cwjhqS9UfoyPbYHahFwHQL3sp  carrierQuoteCode
          this.$router.push({
            name: 'carrierQuoteCode',
            params: { id: shipment, hash: this.$route.params.hash, code: this.$route.params.code },
          });

          break;
        default:
          this.currentStatus = '';
          break;
      }
    },
  },
});
