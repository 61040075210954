
import { defineComponent, ref } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';

import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  XCircleIcon,
} from '@heroicons/vue/24/outline';
// import TimePicker from 'vue3-timepicker';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import DatePickerComponent from '@/components/datepicker/DatePickerComponent.vue';
import CustomMultiselect from '@/components/forms/CustomMultiselect.vue';
import GoogleAutocomplete from '@/components/forms/GoogleAutocomplete.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import MultiCommodity from '@/components/multiCommodity/MultiCommodity.vue';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';
import PlanButtonGroup from '@/components/buttons/PlanButtonGroup.vue';
import PlanInputHour from '@/components/forms/PlanInputHour.vue';
import StopData from '@/modules/planning/newLoad/_components/form/StopData.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins';
// Styles
import '@/resources/assets/styles/scss/custom.scss';
import '@vuepic/vue-datepicker/dist/main.css';
// Utils
import { locationString, locationObject, objectLocationToAddress } from '@/utils/formatLocation';
import { hourRange, minutesRange, formatTimeToObject, compareTimes } from '@/utils/formatTime';

interface loadSaved {
  loadId: number;
  stops: any[];
  billLocationMaster: any;
  billNameMaster: any;
}

export default defineComponent({
  name: 'StopsForm',
  components: {
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    ErrorMessage,
    Field,
    Form,
    MultiCommodity,
    XCircleIcon,
    StopData,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    loadId: {
      type: Number,
      required: true,
    },
    stopToModify: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedAppointment: 0,
      updateKey: false,
      currentStopSelected: {} as any,
      billData: {
        billLocationMaster: {
          address: '',
          city: '',
          lat: '',
          long: '',
          state: {
            id: undefined as any,
            iso: '',
            name: '',
          },
          zip: '',
        },
        billNameMaster: '',
        placeValue: '',
      },
      city: [] as any,
      clickConfirmed: false,
      companyResults: [] as any[],
      componentKeyUpdate: false,
      currentIndex: 0,
      datesValid: [] as any[],
      index: 0,
      isBillAddress: false,
      isInvalid: true,
      isSending: false,
      isSendingRequest: false,
      isSendingSubmit: false,
      loadSaved: {} as loadSaved,
      multiStopsSelected: [] as any,
      options: [],
      palletsErrors: [] as any,
      pendingRequests: 0,
      query: '',
      searchText: '',
      selectedCompanys: [] as any[],
      showTime: true,
      stops: [] as any[],
      stopClicked: 0,
      timeFormat: 'hh:mm a',
      timeouts: [] as any[],
      updateWeight: true,
      validating: false,
      validStops: [] as any,
    };
  },
  setup() {
    const time = ref({
      hours: new Date().getHours(),
      minutes: new Date().getMinutes(),
    });
    return {
      time,
    };
  },
  mounted() {
    this.fetchValidStops();
    this.$store.dispatch('LoadsStore/updateShipmentNextStep', false);
  },
  created() {
    this.$store.dispatch('LoadsStore/packageTypes');
    let localStorageArray = JSON.parse(localStorage.getItem('loadStops') || '[]');
    let loadExistsInLocalStorage = localStorageArray.some(
      (item) => item.loadId === this.currentLoad.id,
    );

    if (loadExistsInLocalStorage && !this.currentLoad.isConsigneeFilled) {
      this.loadSaved = localStorageArray.find((item) => item.loadId === this.currentLoad.id);
      this.stops = this.loadSaved.stops;
      this.getMultiStopSaved();
      this.stopClicked = this.stopToModify;
      if (this.loadSaved.billLocationMaster) {
        this.billData.billLocationMaster = this.loadSaved.billLocationMaster;
        this.billData.billNameMaster = this.loadSaved.billNameMaster;
        this.billData.placeValue = this.objectLocationToAddress(this.loadSaved.billLocationMaster);
      }

      if (this.loadSaved.billLocationMaster) {
        this.isBillAddress = true;
      }
    } else {
      if (this.currentLoad.isConsigneeFilled) {
        this.stops = this.getStops();
        this.getMultiStopSaved();
        this.stopClicked = this.stopToModify;
        if (this.currentLoad.billLocationMaster) {
          this.billData.billLocationMaster = this.currentLoad.billLocationMaster;
          this.billData.billNameMaster = this.currentLoad.billNameMaster;
          this.billData.placeValue = this.objectLocationToAddress(
            this.currentLoad.billLocationMaster,
          );
        }
      } else {
        this.stops = this.getStops();
        this.getMultiStopSaved();
        this.stopClicked = this.stopToModify;
        let loadStops = {
          loadId: this.currentLoad.id,
          stops: this.stops,
          billLocationMaster: this.currentLoad.billLocationMaster,
          billNameMaster: this.currentLoad.billNameMaster,
        };
        localStorageArray.push(loadStops);
        localStorage.setItem('loadStops', JSON.stringify(localStorageArray));
      }
      if (this.currentLoad.billLocationMaster) {
        this.isBillAddress = true;
      }
    }
    const orderedStops = this.deepClone(
      this.currentLoad.stops.sort((a, b) => a.sequence - b.sequence),
    );
    setTimeout(() => {
      orderedStops.forEach((stop, index) => {
        (this.$refs[`stop-${index}`] as any)[0].setStopData(stop);
      });
    }, 100);
  },
  computed: {
    appointments(): any {
      return [
        { label: this.$t('appt'), value: 'APPT' },
        { label: this.$t('fcfs'), value: 'FCFS' },
        { label: this.$t('label.none').toUpperCase(), value: 'NONE' },
      ];
    },

    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    actualDate(): any {
      return new Date();
    },
    checkIsDone(): any {
      return (i) => {
        const item = this.orderedStops[i];
        return (
          item &&
          item.location &&
          item.location.city &&
          item.executionDate &&
          item.warehouseTemplate &&
          item.commodities &&
          item.commodities[0] &&
          item.commodities[0].commodityName &&
          item.commodities[0].nPallets &&
          item.commodities[0].packageType.id &&
          item.commodities[0].weight
        );
      };
    },
    commodityUnitTypes(): any {
      return this.$store.getters['LoadsStore/packageTypes'].FTL;
    },
    companyList(): any {
      return this.$store.getters['UserStore/getCompany'];
    },
    compareTimes(): any {
      return compareTimes;
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    deliveries(): any {
      return this.currentLoad.stops.filter(
        (stop) => stop.type === 'DELIVERY' || stop.type === 'DELIVERY_FINAL',
      );
    },
    deliveriesMulti(): any {
      return this.currentLoad.stops.filter(
        (stop) => stop.type === 'DELIVERY' || stop.type === 'DELIVERY_FINAL',
      );
    },
    deliveryList(): any {
      const array = [] as any;
      this.deliveries.forEach((stop) => {
        if (stop.type === 'DELIVERY' || stop.type === 'DELIVERY_FINAL') {
          array.push(stop.location.city);
        }
      });
      return array;
    },
    formatTimeToObject() {
      return formatTimeToObject;
    },
    hourRange(): any {
      return hourRange;
    },
    modifyIsAllowed() {
      return this.currentLoad.status === 'QUOTING' || this.currentLoad.status === 'CONFIRMED';
    },
    totalCommodityMaster(): any {
      let result = 0;
      this.currentLoad?.commodityMasters.forEach((commodity) => {
        result += Number(commodity.weight);
      });
      if (result === 0) {
        result = this.currentLoad?.commodityMaster.weight ?? 0;
      }
      return result;
    },
    isOverloadDeliveries(): any {
      const currentDeliveries = this.orderedStops?.filter(
        (stop) => stop.type === 'DELIVERY_FINAL' || stop.type === 'DELIVERY',
      );

      const totalWeight = currentDeliveries.reduce((acc, curr) => {
        const commodityWeights = curr.commodities.map((commodity) => commodity?.weight || 0);
        return acc + commodityWeights.reduce((sum, weight) => sum + weight, 0);
      }, 0);

      return this.totalCommodityMaster === totalWeight;
    },
    isOverloadPicks(): any {
      const currentPicks = this.orderedStops?.filter(
        (stop) => stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP',
      );

      const totalWeight = currentPicks.reduce((acc, curr) => {
        const commodityWeights = curr.commodities.map((commodity) => commodity?.weight || 0);
        return acc + commodityWeights.reduce((sum, weight) => sum + weight, 0);
      }, 0);

      return this.totalCommodityMaster === totalWeight;
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
    locationObject(): any {
      return locationObject;
    },
    locationString(): any {
      return locationString;
    },
    maxDate(): any {
      const stop = this.stops.find((stop) => stop.type === 'DELIVERY_FINAL');
      return stop.executionDate;
    },
    maxWeight(): any {
      return this.currentLoad.commodityMaster.weight;
    },
    minDate(): any {
      const initialPickup = this.stops.find((stop) => stop.type === 'PICKUP_INITIAL');
      return this.formatDateStringReverse(initialPickup.executionDate);
    },
    minutesRange(): any {
      return minutesRange;
    },
    objectLocationToAddress(): any {
      return objectLocationToAddress;
    },
    orderedStops(): any {
      return this.stops;
    },
    pickups(): any {
      return this.currentLoad.stops.filter(
        (stop) => stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP',
      );
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
  },
  methods: {
    changeFromHour(value: string) {
      this.currentStopSelected.executionWindowStartTime = value;
    },
    changeToHour(value: string) {
      this.currentStopSelected.executionWindowEndTime = value;
    },

    changeAppointments(index) {
      const tabTotalsMap = ['APPT', 'FCFS', 'NONE'];
      this.currentStopSelected.appointmentType = tabTotalsMap[index];
    },
    changePhone(type, phone, index) {
      switch (type) {
        case 'prefix':
          this.orderedStops[index].phonePrefix = phone.prefix;
          break;
        case 'number':
          this.orderedStops[index].phoneNumber = phone.number;
          break;
        default:
          return;
      }
    },
    async changePONumber(params) {
      await this.$store
        .dispatch('LoadsStore/modifyLoad', { ...this.currentLoad, ...params })
        .catch((err) => {
          console.log(err);
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    },
    updateCommodityMasters(data, item) {
      item.commodities = [...data];
      if (this.orderedStops.length === 2) {
        this.orderedStops[1].commodities = this.orderedStops[0].commodities;
      }
      this.updateStops();
    },
    async checkDates(stop, date) {
      this.updateStops();
      stop.executionDate = this.formatDateStringReverse(date);
      this.stops.forEach((stop, index) => {
        if (this.minDate > stop.executionDate && stop.executionDate) {
          stop.executionDate = this.minDate;
          (this.$refs[`stop-${index}`] as any)[0].updateDate(this.stops[0].executionDate);
        }
      });
    },
    checkIfDeliveryIsNotSelected(firstArray, secondArray) {
      let citiesLeft = [] as any[];

      for (let i = 0; i < firstArray.length; i++) {
        const id = firstArray[i].id;
        const objectExists = secondArray.some((obj) => obj.id === id);

        if (!objectExists) {
          citiesLeft.push(
            firstArray[i].location.city + ' - ' + this.formatDate(firstArray[i].executionDate),
          );
        }
      }

      if (citiesLeft.length > 0) {
        BaseComponent.methods?.showToastError(this.$t('cities-left-rc') + citiesLeft.join(', '));
        return true;
      } else {
        return false;
      }
    },
    companiesNames() {
      // let i = 0;
      // this.orderedStops.forEach((item: any) => {
      //   if (item.companyName !== undefined) {
      //     this.selectedCompanys[i] = item.companyName;
      //     i++;
      //   }
      // });
    },
    compareNPallets() {
      const messages = [] as any;
      const currentPicks = this.orderedStops?.filter(
        (stop) => stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP',
      );
      const currentDeliveries = this.orderedStops?.filter(
        (stop) => stop.type === 'DELIVERY_FINAL' || stop.type === 'DELIVERY',
      );

      // Step 1: Create an empty array
      const extractedPicks = [];
      const extractedDeliveries = [];

      const extractCommodities = (stops, extractedArray) => {
        stops.forEach((stop) => {
          stop.commodities.forEach((commodity) => {
            const matchingItem = this.commodityUnitTypes.find(
              (item) => item.id === commodity?.packageType?.id,
            );
            if (matchingItem) {
              extractedArray.push({
                nPallets: commodity.nPallets,
                units: matchingItem.fullName,
              });
            }
          });
        });
      };

      extractCommodities(currentPicks, extractedPicks);
      extractCommodities(currentDeliveries, extractedDeliveries);

      const palletsMap = {} as any;

      for (const item of extractedPicks) {
        const { nPallets, units } = item;
        palletsMap[units] = (palletsMap[units] || 0) + parseInt(nPallets, 10);
      }

      for (const item of extractedDeliveries) {
        const { nPallets, units } = item;

        if (palletsMap[units]) {
          palletsMap[units] -= nPallets;
        } else if (units) {
          messages.push(this.$t('unit-not-exit', { unit: units }));
        }
      }

      // Compare the nPallets for each unit
      for (const unit in palletsMap) {
        if (palletsMap[unit] > 0) {
          messages.push(this.$t('add-unit-deliveries', { nPallets: palletsMap[unit], unit }));
        } else if (palletsMap[unit] < 0) {
          messages.push(this.$t('remove-unit-deliveries', { nPallets: -palletsMap[unit], unit }));
        }
      }
      return messages;
    },
    convertDate(dateString) {
      const date = new Date(dateString);
      const convertedDate = date.toISOString().slice(0, 10);
      return convertedDate;
    },
    convertExecutionDatesToDates(stops) {
      for (let obj of stops) {
        let executionDate = obj.executionDate;
        let date = new Date(executionDate);
        obj.executionDate = date;
      }

      return stops;
    },
    deleteStop() {
      let localStorageArray = JSON.parse(localStorage.getItem('loadStops') || '[]');
      let indexToDelete = localStorageArray.findIndex(
        (item) => item.loadId === this.currentLoad.id,
      );

      if (indexToDelete !== -1) {
        localStorageArray.splice(indexToDelete, 1);
        localStorage.setItem('loadStops', JSON.stringify(localStorageArray));
      }
    },
    fetchValidStops() {
      this.orderedStops.forEach(async (item: any, index) => {
        this.validStops[index] = await this.stopValid(index);
      });
    },
    async findShipper(query, zip, index, item) {
      if (this.isSuperAdminRoute) {
        BaseComponent.methods?.showToastError(this.$t('access-denied-no-action'));
        return;
      }
      if (!query.text) return;
      if (this.timeouts[index]) {
        clearTimeout(this.timeouts[index]);
      }

      try {
        this.pendingRequests++;

        if (query.text) {
          item.companyName = query.text;
          this.isSendingRequest = true;
          const response = await this.$store.dispatch('LoadsStore/getWarehouses', {
            zip: zip,
            name: query.text,
          });

          const newData = response.map((obj) => {
            const { ...rest } = obj;
            return { value: `${obj.companyWarehouse} - ${obj.address}`, ...rest };
          });

          this.options = newData;

          this.companyResults[index] = newData;
        }
      } catch (error) {
        //
      } finally {
        this.pendingRequests--;
        this.isSendingRequest = false;
      }
    },
    getDefaultDelivery(stop: any) {
      if (
        (stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP') &&
        this.deliveries.length === 1
      ) {
        return '/api/stops/' + this.deliveries[0].id;
      }
      return null;
    },
    getDefaultDeliveryObject(stop: any) {
      if (
        (stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP') &&
        this.deliveries.length === 1
      ) {
        return [this.deliveries[0]];
      }
      return [];
    },
    getMultiStopSaved() {
      for (let i = 0; i < this.stops.length; i++) {
        if (this.stops[i].multiStop.length > 0) {
          if (this.stops[i].multiStop && this.stops[i].multiStop[0]) {
            this.stops[i].deliveryStop = `/api/stops/${this.stops[i].multiStop[0].id}`;
          }
          this.multiStopsSelected.push(...this.stops[i].multiStop);
        }
      }
    },
    getStops(): any {
      let stopsArray: any[] = [];
      if (!this.currentLoad.stops) return;
      this.currentLoad.stops.forEach((stop, index) => {
        this.datesValid.push({
          date: false,
          timeStart: false,
          timeEnd: false,
        });
        let obj = {
          id: stop.id,
          type: stop.type,
          portStop: stop?.portStop,
          airportStop: stop?.airportStop,
          // multiStopCities: stop?.multiStopCities ? stop.multiStopCities : '',
          multiStop:
            stop?.multiStop.length > 0 ? stop.multiStop : this.getDefaultDeliveryObject(stop),
          lumperFeePrice: stop?.lumperFeePrice ? stop.lumperFeePrice : null,
          lumperFee: stop.lumperFeePrice ? true : false,
          executionDate: this.currentLoad.isConsigneeFilled
            ? stop.executionDate
            : stop.executionDate,
          displayDate: stop.executionDate ? new Date(stop.executionDate) : '',
          executionWindowStartTime: stop.executionWindowStartTime
            ? stop.executionWindowStartTime
            : undefined,
          contact: stop.contact || '',
          executionWindowEndTime: stop.executionWindowEndTime ? stop.executionWindowEndTime : '',
          companyName: stop.companyName ? stop.companyName : '',
          observations: stop.observations ? stop.observations : '',
          phoneNumber: stop.phoneNumber ? stop.phoneNumber : '',
          phonePrefix: stop.phonePrefix ? stop.phonePrefix : '1',
          appointmentType:
            stop.executionWindowStartTime === undefined
              ? 'NONE'
              : stop.executionWindowEndTime === undefined
              ? 'APPT'
              : 'FCFS',
          location: {
            ...stop.location,
          },
          commodities: stop.commodities.map((commodity) => ({
            id: commodity.id || null,
            nPallets: commodity.nPallets || '',
            packageType: commodity.packageType || { id: -1 },
            weight:
              this.pickups.length > 1 || this.deliveries.length > 1 || commodity.weight
                ? commodity.weight
                : this.currentLoad.commodityMaster.weight,
            commodityName:
              commodity.commodityName || this.currentLoad.commodityMaster.commodityName,
          })),
          deliveryStop: stop.deliveryStop
            ? '/api/stops/' + stop.deliveryStop.id
            : this.getDefaultDelivery(stop),
          poNumber: stop.poNumber ? stop.poNumber : '',
          warehouseTemplate: stop.warehouseTemplate ? stop.warehouseTemplate : null,
        };

        if (obj.commodities.length === 0) {
          obj.commodities = this.currentLoad.commodityMasters.map((commodity) => ({
            id: null,
            packageType: commodity.packageType || { id: -1 },
            nPallets: commodity.nPallets,
            commodityName: commodity.commodityName,
            weight: commodity.weight,
          }));
        }

        stopsArray.push(obj);
      });

      return stopsArray;
    },
    getZipcodeCountry(itemLocation) {
      return itemLocation?.location?.state?.country ? itemLocation?.location?.state?.country : null;
    },
    handleShipperSelection(event, stop, i) {
      if (event !== null) {
        stop.companyName = event.item.companyWarehouse;
        stop.location.address = event.item.address;
        stop.observations = event.item.observations;
        stop.phoneNumber = event.item.phoneNumber;
        stop.phonePrefix = event.item.phonePrefix;
        stop.airportStop = event.item.airportStop;
        stop.portStop = event.item.portStop;
        this.options = [];
        this.updateStops();
      }
    },
    handleStopClicked(id, index, item) {
      this.currentStopSelected = item;

      this.updateKey = !this.updateKey;
      if (this.stopClicked === id) {
        this.stopClicked = 0;
      } else {
        this.stopClicked = id;
      }

      setTimeout(() => {
        (this.$refs['stop' + index] as any)[0].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 500);
      this.fetchValidStops();
    },
    setStopLocation(stop, addressData) {
      stop.location = this.locationObject(addressData);
    },
    onPlaceChanged(place: any, stop: any, id: any) {
      const states: any = this.$store.getters['LoadsStore/getStates'];
      let stateObj = states.find((state) => state.iso === place.administrative_area_level_1);

      if (!stateObj && place.administrative_area_level_2) {
        stateObj = states.find((state) => state.name === place.administrative_area_level_2);
      }
      if (!stop.billLocationMaster) {
        stop.location = {
          address: stop.location.address,
          lat: place.latitude,
          long: place.longitude,
          zip: place.postal_code,
          city: place.locality,
          state: {
            id: stateObj.id,
            name: place.administrative_area_level_2,
            iso: place.administrative_area_level_1,
          },
        };
      }

      if (place.postal_code && place.locality && stop.billLocationMaster) {
        stop.billLocationMaster = {
          address: stop?.billLocationMaster?.address,
          ...this.locationObject(place),
        };
        stop.placeValue = this.locationString(place);
      } else {
        (this.$refs[id] as any).clear();
      }
    },
    openNext(id) {
      const stopId = id.split('/').pop();
      this.fetchValidStops();
      this.stopClicked = +stopId;
    },
    portOrAirport(pick, stop) {
      if (pick === 'port') {
        stop.airportStop = false;
      }
      if (pick === 'airport') {
        stop.portStop = false;
      }
    },
    setDeliveryData(stop: any) {
      this.updateWeight = false;
      const id = stop.deliveryStop.split('/').pop();
      const actualStop = this.orderedStops.find((stop) => +stop.id === +id);
      // if (!stop.multiStop.some((item) => item.id === actualStop.id)) {
      //   stop.multiStop.push(actualStop)
      // }
      actualStop.poNumber = stop.poNumber;
      actualStop.commodities = [
        {
          id: stop.commodities[0] ? stop.commodities[0].id : null,
          nPallets: stop.commodities[0] ? stop.commodities[0].nPallets : '',
          packageType: stop.commodities[0] ? stop.commodities[0].packageType : '',
          weight: stop.commodities[0]?.weight,
          commodityName: stop.commodities[0]
            ? stop.commodities[0].commodityName
            : this.currentLoad.commodityMaster.commodityName,
        },
      ];
      setTimeout(() => {
        this.updateWeight = true;
      }, 300);
    },
    setStops(item, city, idx) {
      if (item.multiStop.some((item) => item.id === city.id)) {
        // item.multiStop = item.multiStop.filter((item) => item.multiStop.id === city.id);
        if (item?.multiStop.length !== undefined) {
          if (item?.multiStop.length === 1) {
            item.deliveryStop = '';
          }
        }
        this.multiStopsSelected.splice(idx - 1, 1);
        item.multiStop.splice(idx - 1, 1);
      } else {
        const id = item.deliveryStop.split('/').pop();
        const actualStop = this.orderedStops.find((stop) => +stop.id === +id);

        if (!item.multiStop.some((item) => item.id === actualStop.id)) {
          item.multiStop.push(actualStop);
          this.multiStopsSelected.push(actualStop);
        }
      }
    },
    async stopValid(index) {
      if (this.$refs['form' + index]) {
        const validation = await (this.$refs['form' + index] as any)[0].validate();
        if (!validation?.valid) {
          return false;
        }
      }
      return true;
    },
    updateCompany(stop, company) {
      stop.companyName = company;
      this.updateStops();
    },
    updateHour(item) {
      this.updateStops();
      if (item.executionWindowEndTime !== null) {
        if (typeof item.executionWindowEndTime === 'string')
          item.executionWindowEndTime = item.executionWindowEndTime;
        const itemCompare = this.compareTimes(
          item.executionWindowStartTime,
          item.executionWindowEndTime,
        );
        if (itemCompare) {
          item.executionWindowEndTime = item.executionWindowStartTime;
          this.showTime = false;
          setTimeout(() => {
            this.showTime = true;
          }, 1);
        }
      }
    },
    updateLumperFee(stop, isLumperFee, price) {
      if (isLumperFee) {
        stop.lumperFeePrice = price;
      } else {
        stop.lumperFeePrice = null;
      }
    },
    updateObservations(event, stop) {
      stop.observations = event.target.innerText;
    },
    updatePoNumber(event, stop) {
      stop.poNumber = event.target.innerText;
    },
    countrySelected(country, stop) {
      stop.defaultCountrySelected = country;
    },
    updateStops() {
      if (!this.currentLoad.isConsigneeFilled) {
        let localStorageArray = JSON.parse(localStorage.getItem('loadStops') || '[]');
        let findLoadTosave = localStorageArray.find((item) => item.loadId === this.currentLoad.id);
        if (findLoadTosave) {
          findLoadTosave.stops = this.stops;
          localStorage.setItem('loadStops', JSON.stringify(localStorageArray));
        }
      }
    },
    updateWarehouse(
      stop,
      address,
      companyName,
      lumperFee,
      lumperFeePrice,
      observations,
      poNumber,
      phoneNumber,
      phonePrefix,
      contactName,
      warehouseId,
      executionWindowStartTime,
      executionWindowEndTime,
    ) {
      stop.companyName = companyName;
      stop.location.address = address;
      stop.lumperFee = lumperFee;
      stop.lumperFeePrice = lumperFeePrice;
      stop.observations = observations;
      stop.poNumber = poNumber;
      stop.phoneNumber = phoneNumber;
      stop.phonePrefix = phonePrefix;
      stop.contact = contactName;
      stop.executionWindowStartTime = executionWindowStartTime;
      stop.executionWindowEndTime = executionWindowEndTime;
      warehouseId !== null
        ? (stop.warehouseTemplate = { id: warehouseId })
        : (stop.warehouseTemplate = null);
    },
    async validateConsignee() {
      if (this.isSuperAdminRoute) {
        return;
      }
      if (this.isSendingSubmit) return;
      if (this.pendingRequests !== 0) return;
      if (this.checkIfDeliveryIsNotSelected(this.deliveries, this.multiStopsSelected)) {
        return;
      }

      this.validating = true;
      this.clickConfirmed = true;
      this.isSendingSubmit = true;
      this.fetchValidStops();
      for (let index = 0; index < this.orderedStops.length; index++) {
        const validation = await (this.$refs['form' + index] as any)[0].validate();
        if (!validation.valid) {
          this.isSendingSubmit = false;
        }
      }
      this.palletsErrors = this.compareNPallets();

      if (this.palletsErrors.length > 0) {
        this.isSendingSubmit = false;
        return;
      }

      if (!this.isSendingSubmit || !this.isOverloadDeliveries || !this.isOverloadPicks) {
        this.isSendingSubmit = false;
        BaseComponent.methods?.showToastError(this.$t('field-are-required'));
        return;
      }

      this.isInvalid = false;
      this.orderedStops.forEach((stop) => {
        // stop.executionDate = this.formatDateStringReverse(this.formatDate(stop.executionDate));
        this.stops.forEach((item) => {
          if (stop.id === item.id) {
            item.warehouseTemplate = stop.warehouseTemplate;
          }
        });
        stop.commodities.forEach((commodity) => {
          commodity.nPallets = Number(commodity.nPallets);
          commodity.weight = Number(commodity.weight);
        });

        // if (stop.multiStop.length > 0) {
        //   stop.multiStop.forEach((multiStop) => {
        //     multiStop.executionDate = this.formatDateStringReverse(multiStop.executionDate);
        //   });
        // }
        //
        // stop.executionDate = this.formatDateStringReverse(stop.executionDate);

        if (stop.appointmentType !== 'NONE') {
          if (stop.appointmentType === 'APPT') {
            stop.executionWindowEndTime = null;
            stop.executionWindowStartTime = stop.executionWindowStartTime
              ? stop.executionWindowStartTime
              : null;
          } else {
            stop.executionWindowStartTime = stop.executionWindowStartTime
              ? stop.executionWindowStartTime
              : null;

            stop.executionWindowEndTime = stop.executionWindowEndTime
              ? stop.executionWindowEndTime
              : null;
          }
        } else {
          stop.executionWindowStartTime = null;
          stop.executionWindowEndTime = null;
        }
      });
      let params: any = { stops: this.stops };
      if (this.isBillAddress) {
        params = { ...this.billData, ...params };
        delete params.placeValue;
      }

      if (this.modifyIsAllowed) {
        await this.changePONumber(params);
      }
      await this.$store
        .dispatch('LoadsStore/fillLoadConsignee', {
          id: this.loadId,
          params: params,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('message-consignee-validated'));
          this.deleteStop();
          this.$store.dispatch('LoadsStore/loadById', this.loadId);
          this.$emit('close');
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err);
          this.$emit('close');
        })
        .finally(() => {
          this.isSendingSubmit = false;
          this.validating = false;
        });
    },
  },
  watch: {
    currentLoad() {
      this.stops = this.getStops();
    },
    orderedStops() {
      this.companiesNames();
    },
    validPhoneNumberPaste(evt) {
      const regex = /(\d+)/g;
      let pastedValue = evt.clipboardData.getData('text');
      let numbersFound = pastedValue.match(regex);
      return numbersFound.join('');
    },
  },
});
