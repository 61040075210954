
import { defineComponent, reactive, toRefs } from 'vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputTextArea from '@/components/forms/PlanTextArea.vue';
import PlanInputLocation from '@/components/forms/PlanInputLocation.vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { objectLocationToAddress } from '@/utils/formatLocation';
import { useI18n } from 'vue-i18n';

interface planCarrierUpdatePosition {
  initialLocation: {
    lat: number;
    lon: number;
    zip: string;
    country: string;
  };
  loadingInitialLocation: boolean,
  loadingPostNotifyPosition: boolean,
  observations: string,
  placeId: string,
  showModal: boolean;
}

export default defineComponent({
  name: 'PlanCarrierUpdatePosition',
  components: { PlanIcon, PlanInputLocation, PlanInputTextArea, PlanButton, PlanCarrierDefaultModal, PlanButtonIcon },
  setup () {
    const store = useStore()
    const translate = useI18n()

    const data = reactive<planCarrierUpdatePosition>({
      initialLocation: {
        lat: 0,
        lon: 0,
        zip: '',
        country: '',
      },
      loadingInitialLocation: false,
      loadingPostNotifyPosition: false,
      observations: '',
      placeId: '',
      showModal: false
    })

    const changeInputLocation = (newData: any) => {
      setTextValue(newData.addressData);
      getPlaceId(newData.placeId);
    }

    const closeModal = () => {
      if (!data.loadingPostNotifyPosition) data.showModal = false
    }

    const getLatitudeLongitude = (position: GeolocationPosition) => {
      data.initialLocation.lat = position.coords.latitude;
      data.initialLocation.lon = position.coords.longitude;
      getLocationsByGoogle();
    }

    const getLocation = () => {
      if (navigator.geolocation) {
        data.loadingInitialLocation = true;
        navigator.geolocation.getCurrentPosition(getLatitudeLongitude, showError);
      }
    }

    const getLocationsByGoogle = () => {
      store
        .dispatch('LoadsStore/getCoordinatesGoogle', {
          lat: data.initialLocation.lat,
          lng: data.initialLocation.lon,
        })
        .then((response) => {
          if (response.data.length > 0) {
            data.placeId = response.data[0]['place_id'];
            response.data[0]['address_components'].forEach((addressComponent: any) => {
              if (addressComponent.types.includes('postal_code')) {
                data.initialLocation.zip = addressComponent['short_name'];
              }
              if (addressComponent.types.includes('country')) {
                data.initialLocation.country = addressComponent['short_name'];
              }
            });
          }
        })
        .finally(() => {
          data.loadingInitialLocation = false;
        });
    }

    const getPlaceId = (newPlaceId: string) => {
      store
        .dispatch('LoadsStore/getPlaceDetailsGoogle', {
          placeId: newPlaceId,
        })
        .then((response) => {
          data.placeId = response.data['place_id'];
          data.initialLocation.lat = response.data.geometry.location.lat;
          data.initialLocation.lon = response.data.geometry.location.lng;
        });
    }

    const openModal = () => {
      data.showModal = true
    }

    const postNotifyPosition = async () => {
      data.loadingPostNotifyPosition = true;
      const params = {
        shipment: { id: store.getters['CarrierStore/getLoadData'].shipment?.id },
        location: {
          googlePlaceId: data.placeId,
          language: store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'en' : 'es',
        },
        observations: data.observations,
      };

      await store.dispatch('LoadsStore/postNotifyPosition', params)
      await store.dispatch('LoadsStore/getTrackingHistory', store.getters['CarrierStore/getLoadData'].shipment?.id)
        .then((response) => {
          store.commit('CarrierStore/setTrackingHistory', response)
        })
      await store.dispatch('UserStore/showToast', translate.t('success-update-position'))

      data.observations = '';
      data.loadingPostNotifyPosition = false;
      closeModal()
    };

    const setTextValue = (location: any) => {
      data.initialLocation.zip = location;
    }

    const showError = (error: GeolocationPositionError) => {
      let errorMessage = '';
      switch (error.code) {
        case error.PERMISSION_DENIED:
          errorMessage = 'error.PERMISSION_DENIED';
          break;
        case error.POSITION_UNAVAILABLE:
          errorMessage = 'error.POSITION_UNAVAILABLE';
          break;
        case error.TIMEOUT:
          errorMessage = 'error.TIMEOUT';
          break;
      }
    }

    getLocation()

    return {
      ...toRefs(data),
      changeInputLocation,
      closeModal,
      openModal,
      postNotifyPosition
    }
  }
})
