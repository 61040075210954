
import { computed, defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Spin from '@/components/spin/AnimateSpin.vue';
// Resources
import i18n from '@/resources/locales';
// Router
import router from '@/router';
// Services
import store from '@/services/store';
import PlanImportContactsLoading from '@/modules/planning/contacts/ContactsImport/PlanImportContactsLoading.vue';

export default defineComponent({
  name: 'ShowImportFileContainer',
  components: {
    ChevronLeftIcon,
    Spin,
  },

  mixins: [BaseComponent],
  emits: ['hideImportFile', 'change'],
  setup: function (props, { emit }) {
    // GLOBALS
    const $t = i18n.global.t;
    const route = useRoute();
    const router = useRouter();
    const loading = ref(false);

    // DATA
    const selectedFile = ref('') as any;
    const goback = () => {
      router.go(-1);
    };

    const downloadExcel = async () => {
      let blob = await store.dispatch('UserStore/getContactsExcel');
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'contacts-template.xlsx';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };

    const handleFileChange = (event) => {
      selectedFile.value = event.target.files[0];
      uploadExcel();
    };

    const editingLanes = computed(() => {
      return store.getters['RFPStore/getCurrentEditingLanes'];
    });

    const editingLanesLengh = computed(() => {
      return editingLanes.value.length;
    });

    /** Computed */
    const currentContacts = computed(() => {
      return store.getters['UserStore/getCurrentContacts'];
    });

    const onClickFile = () => {
      const fileInput = document.getElementById('file-input');
      if (fileInput) {
        fileInput.click();
      }
    };

    const uploadExcel = () => {
      if (!selectedFile.value) {
        BaseComponent.methods?.showToastSuccess($t('no-files-selected'));
        return;
      }

      const fileName = selectedFile.value.name.toLowerCase();
      const validExtensions = ['.xlsx'];

      const isValidFile = validExtensions.some((ext) => fileName.endsWith(ext));

      if (!isValidFile) {
        BaseComponent.methods?.showToastError($t('invalid-file'));
        return;
      }

      const formData = new FormData();
      formData.append('file', selectedFile.value);

      loading.value = true;
      store
        .dispatch('UserStore/postContactsExcel', formData)

        .then((response) => {
          if (response.length === 0) {
            BaseComponent.methods?.showToastError($t('file-is-empty'));
          }
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          loading.value = false;
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      route,
      router,
      editingLanesLengh,
      editingLanes,
      downloadExcel,
      goback,
      handleFileChange,
      loading,
      onClickFile,
      selectedFile,
      uploadExcel,
    };
  },
});
