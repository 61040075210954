
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { XCircleIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import CompanyModal from '@/components/modals/CompanyModal.vue';
import DirectAssignModal from '@/components/modals/DirectAssignModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import AddDocuments from '@/modules/planning/newLoad/_components/AddDocuments.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins';
// Modules
import AddCustomer from '@/modules/planning/newLoad/_components/AddCustomer.vue';
import MultiCommodityItem from '@/modules/planning/newLoad/_components/MultiCommodityItem.vue';
import StopData from '@/modules/planning/newLoad/_components/form/StopData.vue';
// Utils
import { convertObjectToArray } from '@/utils/formatData';
import { locationString, locationObject } from '@/utils/formatLocation';
import { compareTimes, formatTimeToObject, hourRange, minutesRange } from '@/utils/formatTime';
import { saveAs } from 'file-saver';

export default defineComponent({
  name: 'ShipmentDataStep',
  components: {
    AddCustomer,
    AddDocuments,
    DirectAssignModal,
    ErrorMessage,
    Field,
    Form,
    MultiCommodityItem,
    StopData,
    XCircleIcon,
    CompanyModal,
    PlanButton,
    PlanButtonIcon,
  },
  mixins: [BaseComponent, CountryMixins],
  emits: [
    'close',
    'changeAdditionalStops',
    'changeCurrentView',
    'setLoad',
    'setLoadHeader',
    'updateWarehouse',
  ],
  props: {
    type: {
      type: String,
      default: null,
    },
    allowed: {
      type: Boolean,
      default: true,
      required: false,
    },
    warehousesAllowed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      mediaObjectUrls: [] as any,
      currentFormtype: '',
      arrayStopSaved: [] as any,
      isAdditionalStops: false,
      isAssigning: false,
      isCreating: false,
      isModify: false,
      isValid: false,
      isValidating: false,
      FILES: [] as any,
      load: {
        observations: '',
        poNumber: '',
        stops: [
          {
            type: 'PICKUP_INITIAL',
            executionDate: '',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            observations: '',
            deliveryStop: null as any,
            lumperFeePrice: null as null | number,
            multiStopIdentifierArray: [],
            lumperFee: false,
            multiStopIdentifier: '',
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            defaultCountrySelected: '',
            poNumber: '',
          },
          {
            type: 'DELIVERY_FINAL',
            executionDate: '',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            multiStopIdentifier: '',
            observations: '',
            lumperFeePrice: null as null | number,
            multiStopIdentifierArray: [],
            lumperFee: false,
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            deliveryStop: null as any,
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            defaultCountrySelected: '',
            poNumber: '',
          },
          {
            type: 'PICKUP',
            executionDate: '',
            location: {
              lat: 0,
              long: 0,
              zip: 0,
              city: '',
              state: { id: 1 },
            },
            portStop: false,
            airportStop: false,
            observations: '',
            lumperFeePrice: null as null | number,
            multiStopIdentifierArray: [],
            multiStopIdentifier: '',
            deliveryStop: null as any,
            lumperFee: false,
            executionWindowEndTime: null,
            executionWindowStartTime: null,
            companyName: '',
            address: '',
            phoneNumber: '',
            phonePrefix: '',
            defaultCountrySelected: '',
            poNumber: '',
          },
        ],
        commodityMaster: {
          weight: null as null | number,
          commodityName: '',
        },
        maxTemp: null,

        minTemp: null,
        specialRequirement: {
          teamDriver: false,
          bondedPermission: false,
          TWICCard: false,
          tsa: false,
          hazardousLoad: false,
          specialPermissions: false,
          specialPermissionsDescription: '',
          tir: false,
        },
        status: 'DRAFT',
        trailerType: { id: null },
      } as any,
      notSaved: false,
      showTime: true,
      showAddDocuments: false,
      showAddDocumentsModal: false,
      showDirectAssignModal: false,
      specialRequirements: false,
      updateWeight: true,
      seePlaceHolder: true,
      observations: '',
      showCompanyModal: false,
    };
  },
  computed: {
    additionalDocuments(): any {
      return this.$store.getters['LoadsStore/getLoadAditionalDocuments'];
    },
    currentCountry(): any {
      return this.$store.getters['CountryStore/getCountry'];
    },
    warehousesItemsModified(): any {
      return this.$store.getters['LoadsStore/warehousesItemsModified'];
    },
    additionalStops(): any {
      return this.load?.stops.filter(
        (stop) => stop?.type === 'PICKUP' || stop?.type === 'DELIVERY',
      );
    },
    compareTimes() {
      return compareTimes;
    },
    currentDate(): any {
      return new Date();
    },
    currentEditingLoad(): any {
      return this.$store.getters['LoadsStore/currentLoadEditing'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    finalDate(): any {
      return this.initialFinalStop[1].executionDate;
    },
    formatTimeToObject() {
      return formatTimeToObject;
    },
    getTextareaClass() {
      const locale = this.getLocale.substring(0, 2);
      if (locale === 'es') {
        return 'textareaES';
      }
      return 'textarea';
    },
    loadHasMediaObjects(): any {
      if (
        this.load &&
        this.load?.mediaObjects?.length &&
        typeof this.load?.mediaObjects?.length !== 'undefined'
      ) {
        return true;
      } else {
        return null;
      }
    },
    hasSpecialRequirementTrue(): any {
      if (
        this.load &&
        this.load.specialRequirement &&
        typeof this.load.specialRequirement !== 'undefined'
      ) {
        return Object.values(this.load.specialRequirement).some((value) => value === true);
      } else {
        return null;
      }
    },
    hasPortAirPortStopTrue(): any {
      if (this.load && this.load.stops && typeof this.load.stops !== 'undefined') {
        return this.load.stops.some((item) => item.portStop === true || item.airportStop === true);
      } else {
        return null;
      }
    },
    hasPortAirPortStopAdditionalTrue(): any {
      if (
        this.additionalStops &&
        this.additionalStops &&
        typeof this.additionalStops !== 'undefined'
      ) {
        return this.additionalStops.some(
          (item) => item.portStop === true || item.airportStop === true,
        );
      } else {
        return null;
      }
    },
    hourRange() {
      return hourRange;
    },
    initialFinalStop(): any {
      return this.load?.stops.filter(
        (stop) => stop?.type === 'PICKUP_INITIAL' || stop?.type === 'DELIVERY_FINAL',
      );
    },
    isStopSaved(): any {
      this.additionalStops.forEach((stop, i) => {
        if (
          stop?.location?.city === '' ||
          stop?.executionDate === '' ||
          stop?.executionDate === null
        ) {
          this.arrayStopSaved[i] = false;
        }
      });
      return this.arrayStopSaved;
    },
    isValidLastElement(): any {
      if (this.additionalStops.length > 0 && this.isAdditionalStops) {
        const lastElement = this.additionalStops[this.additionalStops.length - 1];
        const hasValidDateStop = lastElement?.executionDate && lastElement?.executionDate !== '';
        const hasValidLocationCity =
          lastElement?.location &&
          lastElement?.location?.city &&
          lastElement?.location?.city !== '';
        return hasValidDateStop && hasValidLocationCity;
      }
      return true;
    },
    isVehicleRefrigerated(): any {
      if (this.load?.trailerType?.id !== null) {
        const trailer = this.trailerTypes.find((trail) => trail.id === this.load?.trailerType?.id);
        return trailer?.type === 'REFRIGERATED' || trailer?.type === 'REFRIGERATEDOPTIONAL';
      } else {
        return false;
      }
    },
    loadCloned(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    loadId(): any {
      return this.$store.getters['LoadsStore/loadId'];
    },
    loadTemplateById(): any {
      return this.$store.getters['LoadsStore/getSaveLoadId'];
    },
    locationObject() {
      return locationObject;
    },
    locationString() {
      return locationString;
    },
    initialDate(): any {
      return this.initialFinalStop[0].executionDate;
    },
    minutesRange() {
      return minutesRange;
    },
    newLoadTemplates(): any {
      return this.$store.getters['LoadsStore/getSaveLoads'];
    },
    shipmentId(): any {
      return this.$store.getters['LoadsStore/loadShipmentId'];
    },
    states(): any {
      return this.$store.getters['LoadsStore/getStates'];
    },
    seePlaceHolderComputed(): any {
      return this.seePlaceHolder && this.load.observations === '';
    },
    trailerTypes(): any {
      return this.$store.getters['LoadsStore/getTrailerTypes'];
    },
  },
  beforeMount() {
    if (this.type === 'MODIFY') {
      this.isModify = true;
      if (this.load?.mediaObjects?.length > 0) {
        this.showAddDocuments = true;
      }
    }
    this.$store.commit('LoadsStore/setLoadAditionalDocuments', []);
    this.$store.dispatch('LoadsStore/updateShipmentNextStep', false);
  },
  methods: {
    async viewDocumentUploaded(id, entityClass) {
      const result = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: id,
        class: entityClass,
      });

      const url = URL.createObjectURL(result);
      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-${''}`;
        const blob = new Blob([result], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    },
    async getMediaObjectOfAdditionalFee(mediaobject) {
      const result = await this.$store.dispatch('ShipmentStore/thumbnailBig', {
        id: mediaobject.id,
        class: 'LOAD',
      });

      this.mediaObjectUrls.push({
        url: URL.createObjectURL(result),
        name: mediaobject.name,
        id: mediaobject.id,
      });
    },
    getFileLink(file) {
      let fileLink;

      if (
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'application/pdf'
      ) {
        fileLink = URL.createObjectURL(file);
      } else {
        fileLink = '#';
      }
      return fileLink;
    },
    getDocumentsUploaded() {
      let getDocumentsUploaded = document.getElementById('getDocumentsUploaded');
      if (getDocumentsUploaded) getDocumentsUploaded.click();
    },
    async handleFileChange(event) {
      const files = event.target.files;
      const filesArray: File[] = Array.from(files);
      const acceptedTypes = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/png',
      ];
      const maxSize = 100 * 1024 * 1024;

      const validFiles = filesArray.filter((file) => {
        if (!acceptedTypes.includes(file.type)) {
          BaseComponent.methods?.showToastError(
            this.$t('file-not-valid-type', { file: file.name }),
          );
          return false;
        }

        if (file.size > maxSize) {
          BaseComponent.methods?.showToastError(
            this.$t('file-not-valid-size', { file: file.name }),
          );
          return false;
        }

        return true;
      });

      this.FILES = validFiles;

      if (this.isModify) {
        if (validFiles.length > 0) {
          await Promise.all(
            validFiles.map(async (file) => {
              await this.$store
                .dispatch('LoadsStore/postMediaObjectLoad', {
                  id: this.load.id,
                  file: file,
                })
                .then((response) => {
                  //
                })
                .catch((err) => {
                  console.log(err);
                });
            }),
          ).then(() => {
            this.$store.dispatch('LoadsStore/loadById', this.load?.id).then((response) => {
              this.mediaObjectUrls = [];
              BaseComponent.methods?.showToastSuccess(this.$t('documents-uploaded-done'));
              response?.mediaObjects.forEach(async (mediaObject: any) => {
                await this.getMediaObjectOfAdditionalFee(mediaObject);
              });
            });
          });
        }
      } else {
        if (this.additionalDocuments.length > 0) {
          const existingFiles = this.$store.getters['LoadsStore/getLoadAditionalDocuments'];
          const mergedFiles = [...existingFiles, ...validFiles];
          this.$store.commit('LoadsStore/setLoadAditionalDocuments', mergedFiles);
        } else {
          this.$store.commit('LoadsStore/setLoadAditionalDocuments', validFiles);
        }
      }
    },
    removeFile(file, index) {
      let idDeleteItem = file.id ? file.id : index;

      if (this.isModify && file.id) {
        this.$store.dispatch('LoadsStore/deleteLoadMediaObject', idDeleteItem).then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('ranking-quote-delete'));
          this.mediaObjectUrls.splice(index, 1);
        });
      } else {
        const filesArray = Array.from(this.$store.getters['LoadsStore/getLoadAditionalDocuments']);
        filesArray.splice(index, 1);
        this.$store.commit('LoadsStore/setLoadAditionalDocuments', filesArray);
      }
    },
    handleShowAddDocuments() {
      this.showAddDocumentsModal = true;
    },
    convertObjectToArray,
    accept() {
      this.$emit('changeCurrentView', this.currentFormtype);
    },
    addAdditionalStop() {
      this.load?.stops?.push({
        appoiment: 'NONE',
        executionWindowStartTime: null,
        executionWindowEndTime: null,
        location: {
          lat: 0,
          long: 0,
          zip: 0,
          city: '',
          state: { id: 1 },
        },
        type: 'PICKUP',
        deliveryStop: null as any,
        multiStopIdentifierArray: [],
        portStop: false,
        airportStop: false,
        executionDate: '',
        multiStopIdentifier: '',
        observations: '',
        initialObservations: '',
        lumperFeePrice: null as null | number,
        lumperFee: false,
        companyName: '',
        address: '',
        phoneNumber: '',
        phonePrefix: '',
      });
    },
    async checkDate(stop, date) {
      stop.executionDate = this.formatDateStringReverse(date);
      this.load.stops.forEach((stop, index) => {
        if (this.initialDate > stop.executionDate && stop.executionDate) {
          stop.executionDate = this.initialDate;
          (this.$refs[`stop-${index}`] as any)[0].updateDate(this.load.stops[0].executionDate);
        }
      });
    },
    async formSubmit(type: string) {
      this.notSaved = false;
      this.isValid = await this.validate();


      this.currentFormtype = type;
      this.$store.dispatch('LoadsStore/updateShipmentNextStep', true);

      if (!this.isValid || !this.allowed) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.$emit('setLoadHeader', null);
        return;
      }

      if (type === 'assignCarrier') {
        this.isAssigning = true;
      } else {
        this.isCreating = true;
      }

      // Remove the additional stops if additionalStops is not checked
      if (!this.isAdditionalStops) {
        this.load.stops = this.load?.stops?.filter(
          (stop) => stop?.type !== 'PICKUP' && stop?.type !== 'DELIVERY',
        );
      }

      if (this.isVehicleRefrigerated) {
        if (this.load.minTemp !== undefined && this.load.minTemp !== null) {
          this.load.minTemp = parseFloat(this.load?.minTemp.toString());
        }

        if (this.load.maxTemp !== undefined && this.load.maxTemp !== null) {
          this.load.maxTemp = parseFloat(this.load?.maxTemp.toString());
        }
      } else {
        delete this.load?.minTemp;
        delete this.load?.maxTemp;
      }

      this.load.stops = this.load?.stops.filter((item) => item.location.city !== '');

      if (this.warehousesItemsModified.length > 0 && this.warehousesAllowed) {
        const postPromises = [];

        await Promise.all(
          this.warehousesItemsModified.map((item) => {
            if (item.type === 'PUT') {
              this.$store
                .dispatch('LoadsStore/putWarehouse', { id: item.id, body: item.items })
                .then((response) => {
                  this.load.stops[item.index].warehouseTemplate = { id: response.id };
                });
            } else {
              this.$store.dispatch('LoadsStore/postWarehouse', item.items).then((response) => {
                this.load.stops[item.index].warehouseTemplate = { id: response.id };
              });
            }
          }),
        )
          .then(() => {
            this.$store.commit('LoadsStore/setWarehousesItemsModified', []);
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.title);
          });
      }

      this.load?.commodityMasters?.forEach((commodityMaster) => {
        commodityMaster.nPallets = Number(commodityMaster.nPallets);
      });
      // Update commodityMaster with the first position of commodityMasters
      this.load = {
        ...this.load,
        commodityMaster: {
          ...this.load.commodityMaster,
          commodityName: this.load?.commodityMasters[0]?.commodityName,
          weight: this.load?.commodityMasters[0]?.weight,
        },
      };

      this.$emit('setLoad', this.load);

      if (type === 'CARRIER' && this.warehousesAllowed) {
        this.showDirectAssignModal = true;
      } else {
        this.$emit('changeCurrentView', type);
      }
    },
    onFocus() {
      this.seePlaceHolder = false;
    },
    onBlur() {
      if (this.load.observations.length === 0) {
        this.seePlaceHolder = true;
      }
    },
    onChangeAdditionalStops() {
      // Add additional stop if not exists
      if (this.load?.stops?.length <= 2) {
        this.addAdditionalStop();
      }
      this.$emit('changeAdditionalStops', this.isAdditionalStops);
    },
    portOrAirport(pick, stop) {
      if (pick === 'port') {
        stop.airportStop = false;
        stop.portStop = !stop.portStop;
        // THIS ONLY FOR USA
        if (this.currentCountry.iso === 'US') {
          if (!this.hasPortAirPortStopTrue && !this.hasPortAirPortStopAdditionalTrue) {
            this.load.specialRequirement.TWICCard = false;
            if (!this.hasSpecialRequirementTrue) {
              this.specialRequirements = false;
            }
          } else {
            this.specialRequirements = true;
            this.load.specialRequirement.TWICCard = true;
          }
        }
      }

      if (pick === 'airport') {
        stop.portStop = false;
        stop.airportStop = !stop.airportStop;
        // THIS ONLY FOR USA
        if (this.currentCountry.iso === 'US') {
          if (!this.hasPortAirPortStopTrue && !this.hasPortAirPortStopAdditionalTrue) {
            this.load.specialRequirement.TWICCard = false;
            if (!this.hasSpecialRequirementTrue) {
              this.specialRequirements = false;
            }
          } else {
            this.specialRequirements = true;
            this.load.specialRequirement.TWICCard = true;
          }
        }
      }
    },
    removeAdditionalStop(index: number) {
      const itemFound = this.warehousesItemsModified.findIndex((item) => item.index === index + 2);

      if (itemFound !== -1) {
        this.warehousesItemsModified.splice(itemFound, 1);
      }

      // Removes the stop save controller
      this.isStopSaved.splice(index, 1);
      // Remove the stop from the load
      const position = this.type === 'MODIFY' ? 1 : 2;
      this.load?.stops?.splice(index + position, 1);
      if (this.load?.stops?.length <= 2) {
        this.isAdditionalStops = false;
        this.$emit('changeAdditionalStops', this.isAdditionalStops);
      }
    },
    resetStopsCommodityMasters() {
      this.load?.stops.map((item) => {
        if (item?.commodityMaster?.length > 0) {
          item.commodityMaster = [];
        }
        return { ...item };
      });
    },
    setLoad(load) {
      this.isModify = true;
      this.load = load;
      if (load.stops.length > 2) {
        this.isAdditionalStops = true;
        this.$emit('changeAdditionalStops', this.isAdditionalStops);
      }
      const orderedStops = this.deepClone(this.load.stops.sort(this.sortStopsToForm));
      setTimeout(() => {
        orderedStops.forEach((stop, index) => {
          (this.$refs[`stop-${index}`] as any)[0].setStopData(stop);
        });
      }, 100);
    },
    setStopLocation(stop, addressData) {
      stop.location = this.locationObject(addressData);
    },
    sortStopsToForm(a, b) {
      if (a.type === 'PICKUP_INITIAL') return -1;
      if (b.type === 'PICKUP_INITIAL') return 1;
      if (a.type === 'DELIVERY_FINAL') return -1;
      if (b.type === 'DELIVERY_FINAL') return 1;
      return a.sequence - b.sequence;
    },
    updateHour(stop, startTime, endTime, selectedAppointment) {
      if (selectedAppointment !== 'NONE') {
        if (selectedAppointment === 'APPT') {
          stop.executionWindowStartTime = startTime ? startTime : null;
        } else {
          stop.executionWindowStartTime = startTime ? startTime : null;
          stop.executionWindowEndTime = endTime ? endTime : null;
        }
      } else {
        stop.executionWindowStartTime = null;
        stop.executionWindowEndTime = null;
      }
    },
    updateLumperFee(stop, isLumperFee, price) {
      if (isLumperFee) {
        stop.lumperFeePrice = price;
      } else {
        stop.lumperFeePrice = null;
      }
    },
    updateObservations(event, stop) {
      stop.observations = event.target.innerText;
    },
    updatePoNumber(event, stop) {
      stop.poNumber = event.target.innerText;
    },
    countrySelected(country, stop) {
      stop.defaultCountrySelected = country;
    },
    updateCommodityMasters(data) {
      this.resetStopsCommodityMasters();

      if (this.load.hasOwnProperty('commodityMasters')) {
        this.load.commodityMasters = [...data];
        return;
      }
      this.load = {
        ...this.load,
        commodityMasters: [...data],
      };
    },
    updateWarehouse(
      stop,
      address,
      companyName,
      lumperFee,
      lumperFeePrice,
      observations,
      poNumber,
      phoneNumber,
      phonePrefix,
      contactName,
      warehouseId,
      executionWindowStartTime,
      executionWindowEndTime,
    ) {
      stop.companyName = companyName;
      stop.location.address = address;
      stop.lumperFee = lumperFee;
      stop.lumperFeePrice = lumperFeePrice;
      stop.observations = observations;
      stop.poNumber = poNumber;
      stop.phoneNumber = phoneNumber;
      stop.phonePrefix = phonePrefix;
      stop.contact = contactName;
      stop.executionWindowStartTime = executionWindowStartTime;
      stop.executionWindowEndTime = executionWindowEndTime;
      warehouseId !== null
        ? (stop.warehouseTemplate = { id: warehouseId })
        : (stop.warehouseTemplate = null);
    },
    async validate() {
      this.isValidating = true;
      let isValidAdditionalStop = true;
      const validation = await (this.$refs.form as any).validate();


      if (!validation.valid) {
        this.isValidating = false;
        return false;
      }
      return true;
    },
    updateGeneralObservations(event) {
      const innerText = event.target.innerText;
      this.load.observations = innerText;
    },
    checkSpecialRequirement() {
      this.hasSpecialRequirementTrue ? (this.specialRequirements = true) : '';
    },
  },
  watch: {
    loadHasMediaObjects() {
      this.load?.mediaObjects.forEach(async (mediaObject: any) => {
        await this.getMediaObjectOfAdditionalFee(mediaObject);
      });

      this.showAddDocuments = true;
    },
    hasSpecialRequirementTrue() {
      this.checkSpecialRequirement();
    },
    load: {
      handler(newVal) {
        if (newVal.specialRequirement == undefined || newVal.specialRequirement == '') {
          newVal.specialRequirement = {
            teamDriver: false,
            bondedPermission: false,
            TWICCard: false,
            tsa: false,
            hazardousLoad: false,
            specialPermissions: false,
            specialPermissionsDescription: '',
            tir: false,
          };
        }
      },
      deep: true,
    },
    'load.commodityMasters': function (newValue, oldValue) {
      // if (JSON.stringify(this.load?.commodityMasters) !== JSON.stringify(newValue)) {
      this.resetStopsCommodityMasters();
      // }
    },
  },
});
