import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "w-full flex justify-end items-center" }
const _hoisted_3 = { class: "flex justify-start items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_calendar = _resolveComponent("plan-calendar")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex flex-col",
    onMouseenter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isHover = true)),
    onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isHover = false))
  }, [
    (_ctx.label.length > 0)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(_ctx.simple ? '' : 'plan-form')
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.mandatory)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_popper, {
        show: _ctx.showPopper,
        arrow: "",
        offsetDistance: "0",
        interactive: "",
        class: "w-full"
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_plan_calendar, {
              onChangeDate: _ctx.changeDate,
              locale: _ctx.i18n.global.locale.value,
              "allow-format-date": _ctx.allowFormatDate,
              id: "12",
              min: _ctx.min,
              max: _ctx.max
            }, null, 8, ["onChangeDate", "locale", "allow-format-date", "min", "max"])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["w-full flex items-center plan-gap-sm", 
            _ctx.simple
              ? 'justify-start'
              : 'justify-start plan-b-white border plan-border-gray-100 plan-rounded-sm plan-p-sm w-full'
          ]),
            style: {"min-width":"150px"},
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPopper = true))
          }, [
            _createVNode(_component_plan_icon, {
              name: "input-calendar",
              size: "15",
              "is-svg": "",
              "icon-color": "#4F5264"
            }),
            _createElementVNode("p", {
              class: _normalizeClass({
              'plan-t-prim-100': _ctx.selectedDate === 'mm-dd-yyyy' || _ctx.selectedDate === 'dd-mm-yyyy' || _ctx.selectedDate === 'mm-dd-aaaa' || _ctx.selectedDate === 'dd-mm-aaaa', 
              'font-bold':
                _ctx.selectedDate !== 'mm-dd-yyyy' &&
                _ctx.selectedDate !== 'dd-mm-yyyy' &&
                _ctx.selectedDate !== 'mm-dd-aaaa' &&
                _ctx.selectedDate !== 'dd-mm-aaaa',
            })
            }, _toDisplayString(_ctx.selectedDateTranslated(_ctx.selectedDate)), 3),
            (
              !_ctx.hideClose &&
              _ctx.selectedDate !== 'mm-dd-yyyy' &&
              _ctx.selectedDate !== 'dd-mm-yyyy' &&
              _ctx.selectedDate !== 'mm-dd-aaaa' &&
              _ctx.selectedDate !== 'dd-mm-aaaa'
            )
              ? (_openBlock(), _createBlock(_component_plan_button_icon, {
                  key: 0,
                  onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedDate = _ctx.defaultDateLabel)),
                  icon: "close",
                  size: "16",
                  type: "transparent-gray-800",
                  style: {"padding":"0.1rem"}
                }))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _: 1
      }, 8, ["show"]),
      (_ctx.isComplete && !_ctx.simple && !_ctx.hideValidation)
        ? (_openBlock(), _createBlock(_component_plan_icon, {
            key: 0,
            style: {"position":"absolute","margin-right":"-6px"},
            size: "15",
            name: "fill-circle-check",
            "is-svg": "",
            "icon-color": "#00DB50"
          }))
        : _createCommentVNode("", true)
    ])
  ], 32))
}