
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanInputDate from '@/components/forms/PlanInputDate.vue';
import PlanInputCalendar from '@/components/forms/PlanInputCalendar.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import PlanInputText from '@/components/forms/PlanInputText.vue';

export default defineComponent({
  name: 'PlanUpdateMultipleLanesSpecialRequirements',
  components: { PlanInputText, PlanInputCheckbox },
  emits: ['change'],
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      showForm: false,
      teamDriver: false,
      bondedPermission: false,
      tsa: false,
      twicCard: false,
      hazardousLoad: false,
      specialPermits: false,
      specialPermitsDescription: ''
    })

    const change = () => {
      emit('change', {
        isActive: data.showForm,
        teamDriver: data.teamDriver,
        bondedPermission: data.bondedPermission,
        tsa: data.tsa,
        twicCard: data.twicCard,
        hazardousLoad: data.hazardousLoad,
        specialPermits: data.specialPermits,
        specialPermitsDescription: data.specialPermitsDescription
      })
    }

    const changeBondedPermission = (value: boolean) => {
      data.bondedPermission = value
      change()
    }

    const changeHazordousLoad = (value: boolean) => {
      data.hazardousLoad = value
      change()
    }

    const changeShowForm = (value: boolean) => {
      data.showForm = value
      change()
    }

    const changeSpecialPermits = (newValue) => {
      data.specialPermits = newValue
      change()
    }

    const changeTsa = (newValue) => {
      data.tsa = newValue
      change()
    }

    const changeTeamDriver = (newValue) => {
      data.teamDriver = newValue
      change()
    }

    const changeTwicCard = (newValue) => {
      data.twicCard = newValue
      change()
    }

    return {
      ...toRefs(data),
      change,
      changeBondedPermission,
      changeHazordousLoad,
      changeShowForm,
      changeSpecialPermits,
      changeTeamDriver,
      changeTsa,
      changeTwicCard
    }
  }
})
