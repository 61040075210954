import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-1 ml-0 xl:ml-[10rem] bg-white h-full flex items-center justify-center"
}
const _hoisted_2 = {
  key: 1,
  class: "flex-1 ml-0 xl:ml-[10rem] bg-white h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileContainer = _resolveComponent("FileContainer")!
  const _component_ContactsTable = _resolveComponent("ContactsTable")!

  return (_ctx.currentContacts.length === 0)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(_component_FileContainer, { onHideImportFile: _ctx.hideImportFile }, null, 8, ["onHideImportFile"])
      ]))
    : (_openBlock(), _createElementBlock("section", _hoisted_2, [
        _createVNode(_component_ContactsTable, {
          currentContacts: _ctx.currentContacts,
          onDelete: _ctx.deleteItem
        }, null, 8, ["currentContacts", "onDelete"])
      ]))
}