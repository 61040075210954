
import { defineComponent } from 'vue';
import { DocumentIcon } from '@heroicons/vue/24/outline';
import { saveAs } from 'file-saver';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Spin from '@/components/spin/AnimateBlackSpin.vue';
import CardTooltipOptions from '@/components/card/CardTooltipOptions.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanModalShare from '@/components/modals/PlanModalShare.vue';
import UploadDocuments from '@/modules/carrier/modals/UploadDocuments.vue';
// Utils
import { dateStringGetHours, dateStringGetLocalHours } from '@/utils/formatTime';
import PlanDefaultCityInformation from '@/components/defaults/PlanDefaultCityInformation.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
export default defineComponent({
  name: 'ConsigneeAccepted',
  components: {
    PlanDefaultCityInformation,
    DocumentIcon,
    Spin,
    CardTooltipOptions,
    PlanModalShare,
    UploadDocuments,
  },
  mixins: [BaseComponent, CountryMixins],
  props: {
    loadId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imgBOL: '' as any,
      rateConfirmationFile: '' as any,
      isSending: false,
      isGetBOL: [] as boolean[][],
      isViewRC: false,
      file: null as any,
      isUploadingFile: false,
      seeSendModal: false,
      emails: '',
      loadingSendFiles: false,
      fileType: '',
      stopID: '',
      showUploadDocumentsModal: false,
      loadSelected: null as any,
      deliveryStopID: '',
    };
  },
  created() {
    this.isGetBOL = Array.from({ length: this.picks.length }, () => []);
  },
  computed: {
    isEurope(): boolean {
      const country = this.dataCountries?.find(
        (country) => this.currentUser?.company?.country?.id === country?.id,
      );
      return country?.region?.name === 'EUROPE';
    },
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    BOLsList(): any {
      let arrayBOLS: any = [];
      this.currentLoad.stops.forEach((stop) =>
        stop.mediaObjects.filter((media) => {
          if (media.type === 'BOL') arrayBOLS.push({ media, stop });
        }),
      );
      return arrayBOLS;
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    currentStatus(): any {
      return this.currentLoad ? this.currentLoad.status : null;
    },
    dateRC(): any {
      if (
        this.currentLoad?.shipment?.mediaObjects &&
        this.currentLoad?.shipment?.mediaObjects.length > 0
      ) {
        const date = this.currentLoad?.shipment?.mediaObjects?.find(
          (item: any) => item.type === 'RATE_CONFIRMATION',
        ).updatedAt;
        return this.formatDate(date);
      } else {
        return null;
      }
    },
    dateStringGetHours(): any {
      return dateStringGetHours;
    },
    dateStringGetLocalHours() {
      return dateStringGetLocalHours;
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
    picks(): any {
      return this.currentLoad.stops
        .filter((stop) => stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP')
        .sort((a, b) => a.sequence - b.sequence);
    },
    rateConfirmation(): any {
      return this.currentLoad?.shipment?.mediaObjects?.find(
        (item: any) => item.type === 'RATE_CONFIRMATION',
      );
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
    },
    handleUploadDocumentsModal(load) {
      this.loadSelected = load;
      this.showUploadDocumentsModal = true;
    },
    changeIsGetBOL(i, j, value) {
      this.isGetBOL[i][j] = value;
    },
    async downloadRateConfirmation() {
      this.isViewRC = true;
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: this.rateConfirmation.id,
        class: this.rateConfirmation.entityClass,
      });

      const url = URL.createObjectURL(response);
      const newWindow = window.open(url, '_blank');
      let prefix =
        this.currentLoad.shipment.type === 'Alpine_LTL'
          ? 'BOL'
          : !this.isEuropeCompany()
          ? 'RC'
          : 'OC';
      const fileName = `${prefix}-${this.currentLoad.identifier}`;
      const blob = new Blob([response], { type: 'application/pdf' });
      saveAs(blob, fileName, { autoBom: true });

      this.isViewRC = false;
    },
    async downloadRateConfirmationWithoutSign() {
      this.isViewRC = true;
      let response;
      let formatType = 'application/pdf';
      if (this.currentLoad.shipment.type !== 'Alpine_LTL') {
        formatType = 'image/jpeg';
        response = await this.$store.dispatch('ShipmentStore/thumbnailWithoutSign', {
          shipment: this.currentLoad.shipment.id,
        });
      } else {
        response = await this.$store.dispatch('LoadsStore/getLTLBOL', this.currentLoad.id);
      }

      const url = URL.createObjectURL(response);
      const newWindow = window.open(url, '_blank');
      let prefix =
        this.currentLoad.shipment.type === 'Alpine_LTL'
          ? 'BOL'
          : !this.isEuropeCompany()
          ? 'RC'
          : 'OC';
      const fileName = `${prefix}-${this.currentLoad.identifier}`;
      const blob = new Blob([response], { type: formatType });
      saveAs(blob, fileName, { autoBom: true });

      this.isViewRC = false;
    },
    async getBOL(id, delId, i, j, type) {
      this.changeIsGetBOL(i, j, true);
      const result = await this.$store.dispatch('ShipmentStore/getBOLAutogenerated', {
        stop: id,
        deliveryStop: delId,
      });
      this.imgBOL = document.createElement('a');
      this.imgBOL.href = URL.createObjectURL(result);
      const url = URL.createObjectURL(result);
      const newWindow = window.open(url, '_blank');

      this.changeIsGetBOL(i, j, false);

      let bolType = this.$t(type.toLowerCase());
      let prefix = !this.isEuropeCompany() ? 'BOL' : 'CMR';
      const fileName = `${prefix}-${bolType.toUpperCase() + '-' + this.currentLoad.identifier}`;
      const blob = new Blob([result], { type: 'application/pdf' });
      saveAs(blob, fileName, { autoBom: true });
    },
    openModalIncidence(stop) {
      this.$store.dispatch('LoadsStore/stopSelected', stop);
      setTimeout(() => {
        this.$store.dispatch('LoadsStore/showIncidenceModal', true);
      }, 100);
    },
    resendRate() {
      if (this.isSuperAdminRoute) {
        return;
      }
      this.isSending = !this.isSending;
      if (!this.dateRC) {
        this.$store
          .dispatch('LoadsStore/postEmailForRate', {
            shipment: this.currentLoad.shipment.id,
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('message-rate-confirmation-resend'));
            this.$store.dispatch(
              `LoadsStore/${this.isSuperAdminRoute ? 'loadByIdAdmin' : 'loadById'}`,
              this.currentLoad.id,
            );
            this.isSending = false;
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            this.isSending = false;
          });
      }
    },
    sendDocumentToEmail(emails) {
      this.loadingSendFiles = true;
      this.$store
        .dispatch('ShipmentStore/sendDocumentToEmail', {
          loadId: this.currentLoad.id,
          type: this.fileType,
          emails: emails,
          stopID: this.stopID,
          deliveryStopID: this.deliveryStopID,
        })
        .then((response) => {
          this.fileType = '';
          this.stopID = '';
          this.deliveryStopID = '';
          this.emails = '';
          this.loadingSendFiles = false;
          this.seeSendModal = false;
        })
        .catch((err) => {
          this.loadingSendFiles = false;
          console.log(err);
        });
    },
    isSend(stopID, deliveryStopID, mediaObjects) {
      if (mediaObjects !== null) {
        this.fileType = mediaObjects[0].type;
      } else {
        this.fileType = 'BOL';
        this.stopID = stopID;
        this.deliveryStopID = deliveryStopID;
      }
      this.seeSendModal = true;
    },
    closeSendModal() {
      this.fileType = '';
      this.stopID = '';
      this.emails = '';
      this.deliveryStopID = '';
      this.seeSendModal = false;
    },
  },
});
