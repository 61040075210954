
import { defineComponent, reactive, toRefs } from 'vue';

interface loadFormSpecialRequirements {
  showRequirements: boolean,
  teamDriver: boolean,
  bondedPermission: boolean,
  tsaCard: boolean,
  twicCard: boolean,
  hazardousLoad: boolean,
  specialPermits: boolean
}

export default defineComponent({
  name: 'LoadFormSpecialRequirements',
  setup () {
    const data = reactive<loadFormSpecialRequirements>({
      showRequirements: false,
      teamDriver: false,
      bondedPermission: false,
      tsaCard: false,
      twicCard: false,
      hazardousLoad: false,
      specialPermits: false
    })

    return {
      ...toRefs(data)
    }
  }
})
