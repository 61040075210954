
import { defineComponent, ref } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import AddCarrierModal from '@/modules/planning/userProfile/AddCarrierModal.vue';
import FindCarrierByLoadVue from '@/modules/planning/userProfile/FindCarrierByLoad.vue';
import InputAmericanFormat from '@/components/forms/InputAmericanFormat.vue';
import Spin from '@/components/spin/AnimateSpin.vue';

interface User {
  id: number;
  name: string;
  company: string;
  owner: {
    name: string;
    surname: string;
  };
}

export default defineComponent({
  name: 'DirectAssignStep',
  components: {
    AddCarrierModal,
    ErrorMessage,
    Field,
    FindCarrierByLoadVue,
    Form,
    InputAmericanFormat,
    Spin,
  },
  mixins: [BaseComponent],
  props: {
    load: {
      type: Object,
      required: true,
    },
    allowMultipleLoad: {
      type: Boolean,
      default: false,
    },
    totalLoadsMultiple: {
      type: String,
      default: null,
    },
    warehousesAllowed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showSpinner: false,
      showFindCarriermodalVisible: false,
      showSpinnerNet: false,
      useContactPage: 1,
      netWorkContactPage: 1,
      modalVisible: false,
      sendingAction: false,
      allowed: true,
      name: '',
      price: null,
      user: '',
      userSelected: ref<User | null>(null),
      validating: false,
      responseStops: [] as any,
      searchTimeout: null as any,
      currentContactsData: [] as any,
    };
  },
  computed: {
    additionalDocuments() {
      return this.$store.getters['LoadsStore/getLoadAditionalDocuments'];
    },
    isBillingCompanyEmpty() {
      for (const key in this.billingCompanyData) {
        if (this.billingCompanyData[key] !== '') {
          return false;
        }
      }
      return true;
    },
    creditAvailable(): any {
      return this.companyCredit.creditGranted - this.companyCredit.creditLocked;
    },
    companyCredit(): any {
      return this.$store.getters['CreditStore/getCredit'];
    },
    currentContacts(): User[] {
      // return this.$store.getters['UserStore/carrierContactList'];
      return this.currentContactsData;
    },
    currentEditingLoad(): any {
      return this.$store.getters['LoadsStore/currentLoadEditing'];
    },
    deliveries(): any {
      return this.responseStops.filter(
        (stop) => stop.type === 'DELIVERY' || stop.type === 'DELIVERY_FINAL',
      );
    },
    filteredItems(): User[] {
      if (this.currentContacts) {
        return this.currentContacts.filter((item) =>
          item.name.toLowerCase().includes(this.name.toLowerCase()),
        );
      }
      return [];
    },
    getDataCustomersSelected(): any {
      return this.$store.getters['CustomerStore/getCustomersSelectedData'];
    },
    networtContacts(): User[] {
      return this.$store.getters['UserStore/carrierNetwork'];
    },
    paginationMaxItems(): number {
      return (
        +this.$store.getters['GlobalStore/getGlobalConfig']?.PAGINATION_DEFAULT_MAX_RESULTS ?? 0
      );
    },
    pickups(): any {
      return this.load.stops.filter(
        (stop) => stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP',
      );
    },
    shipmentId(): number {
      return this.$store.getters['LoadsStore/loadShipmentId'];
    },
    billingCompanyData(): any {
      const { name, email, taxId, country, state, city, address, zip } =
        this.$store.getters['UserStore/getBillingCompany'];

      return { name, email, taxId, country, state, city, address, zip };
    },
    userForChat(): any {
      return this.$store.state.ChatsStore.userForChat;
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
  },
  created() {
    if (!this.currentUser) {
      this.$store.dispatch('UserStore/currentUser');
    }
  },
  methods: {
    async assignUser(response, route) {
      let shipmentId = response.shipment.id;
      await this.$store.dispatch('LoadsStore/loadId', response.id);
      let userCompany = this.userSelected?.id;

      const baseObject = {
        shipments: [{ id: shipmentId }],
        company: { id: userCompany },
        price: this.price ? +this.price : null,
      };

      this.$store
        .dispatch('UserStore/assignLoad', baseObject)
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('carrier-assigned'));
          this.$store.dispatch('LoadsStore/showModalCarrierAssigned', true);
          this.nextRoute(route);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.dispatch('LoadsStore/currentLoadEditing', null);
          this.allowed = true;
          this.sendingAction = false;
        });
    },
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    findUser(newValue: string) {
      // Clear the timeout if it has already been set
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set a new timeout to execute the search after 500ms
      this.searchTimeout = setTimeout(() => {
        if (newValue.length > 2) {
          this.$store
            .dispatch('UserStore/assignDirect', {
              page: this.useContactPage,
              name: newValue,
              maxResults: 100,
            })
            .then((response) => {
              this.currentContactsData = response;
            });
          // this.$store.dispatch('UserStore/carrierNetwork', {
          //   page: this.netWorkContactPage,
          //   name: newValue,
          //   type: 'CARRIER|BROKER',
          // });
        }
      }, 500);
    },
    getDefaultDelivery(stop: any) {
      if (
        (stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP') &&
        this.deliveries.length === 1
      ) {
        return '/api/stops/' + this.deliveries[0].id;
      }
      return null;
    },
    getDefaultDeliveryObject(stop: any) {
      if (
        (stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP') &&
        this.deliveries.length === 1
      ) {
        return [this.deliveries[0]];
      }
      return [];
    },
    getStops(stops, response): any {
      let stopsArray: any[] = [];
      if (!stops) return;

      stops.forEach((stop) => {
        let obj = {
          id: stop.id,
          type: stop.type,
          portStop: stop?.portStop,
          airportStop: stop?.airportStop,
          lumperFeePrice: stop?.lumperFeePrice ? stop.lumperFeePrice : null,
          executionDate: stop.executionDate,
          displayDate: stop.executionDate ? new Date(stop.executionDate) : '',
          executionWindowStartTime: stop.executionWindowStartTime,
          multiStop:
            stop?.multiStop.length > 0 ? stop.multiStop : this.getDefaultDeliveryObject(stop),
          executionWindowEndTime: stop.executionWindowEndTime,
          companyName: stop.companyName ? stop.companyName : '',
          observations: stop.observations ? stop.observations : '',
          phoneNumber: stop.phoneNumber ? stop.phoneNumber : '',
          phonePrefix: stop.phonePrefix ? stop.phonePrefix : '1',
          appointmentType:
            stop.executionWindowStartTime === undefined
              ? 'NONE'
              : stop.executionWindowEndTime === undefined
              ? 'APPT'
              : 'FCFS',
          location: {
            ...stop.location,
          },
          deliveryStop: this.getDefaultDelivery(stop),
          poNumber: stop.poNumber ? stop.poNumber : '',
        };

        stopsArray.push(obj);
      });
      return stopsArray;
    },
    loadMoreContacts(type) {
      if (type === 'contacts') {
        this.useContactPage += 1;
        this.showSpinner = true;
      } else if (type === 'networtContacts') {
        this.netWorkContactPage += 1;
        this.showSpinnerNet = true;
      }
      this.findUser(this.name);
    },
    nextRoute(route: string) {
      this.$router.push(route);
    },
    openChat(event, user) {
      event.stopPropagation();
      if (JSON.stringify(this.userForChat) !== JSON.stringify(user)) {
        this.$store.commit('GlobalStore/setOpenChat', false);
        setTimeout(() => {
          this.$store.commit('ChatsStore/setUserForChat', user);
          this.$store.commit(
            'ChatsStore/setChatId',
            user.generalChatsWithNetworkOwner[0] ? user.generalChatsWithNetworkOwner[0].id : null,
          );
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
    selectUser(user) {
      this.userSelected = user;
    },
    setCompanySearch(company) {
      this.userSelected = company;
    },
    toggleUser(user: User | null, add: boolean) {
      if (add) {
        this.userSelected = user;
      } else {
        this.userSelected = null;
      }
    },
    async validateForm(route: string) {
      if (!this.allowed) return;
      this.validating = true;
      const validation = await (this.$refs.form as any).validate();
      if (!validation.valid) {
        return;
      }

      this.allowed = false;
      this.sendingAction = true;

      let copyload = this.deepClone(this.load);

      if (copyload.stops.length > 2) {
        copyload.stops.map((load) => {
          load.commodities = load.commodityMaster;
        });
      } else {
        copyload.stops.map((load) => {
          load.commodities = copyload.commodityMasters;
        });
      }

      copyload.commodityMasters.map((commodity) => {
        if (commodity.id) {
          delete commodity.id;
        }
      });

      copyload.commodityMaster = copyload.commodityMasters[0];

      await this.$store
        .dispatch('LoadsStore/createLoad', { load: copyload, isMultiple: this.allowMultipleLoad })
        .then(async (response) => {
          if (this.additionalDocuments.length > 0) {
            await Promise.all(
              this.additionalDocuments.map(async (file) => {
                await this.$store
                  .dispatch('LoadsStore/postMediaObjectLoad', {
                    id: response.id,
                    file: file,
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }),
            );
          }
          await this.$store.dispatch('LoadsStore/loadId', response.id);

          if (!this.isBillingCompanyEmpty) {
            let params: any = {
              billNameMaster: this.billingCompanyData.name,
              billLocationMaster: {
                address: this.billingCompanyData.address,
                city: this.billingCompanyData.city,
                zip: this.billingCompanyData.zip,
                state: { id: this.billingCompanyData.state },
              },
              billIdentificationNumberMaster: this.billingCompanyData.taxId,
              billContactEmailMaster: this.billingCompanyData.email,
            };
            await this.$store
              .dispatch('LoadsStore/putBillContactInfo', {
                id: response.id,
                params: params,
              })
              .then(() => {
                this.getUserInfo();
                BaseComponent.methods?.showToastSuccess(this.$t('message-consignee-validated'));
              })
              .catch((err) => {
                BaseComponent.methods?.showToastError(err?.response?.data?.detail);
              });
          }

          //CREATING THE BATCH OF THE TEMPLATE ONLY IF ITS MULTIPLE
          // CHECK IF ITS MULTIPLE LOAD

          // Assign all the customers selected to the load
          const dataCustomersSelected = this.getDataCustomersSelected;
          if (dataCustomersSelected.length > 0) {
            let promises = [] as any;
            dataCustomersSelected.forEach((item) => {
              promises.push(
                this.$store.dispatch('LoadsStore/apiPostLoadCustomerAssign', {
                  id: response.id,
                  customerId: item.id,
                }),
              );
            });
            Promise.all(promises)
              .then(() => console.log('Assigned all the customers to the load!'))
              .catch(() => console.log('Error trying to assign all the customers to the load!'));
          }

          if (this.allowMultipleLoad) {
            await this.$store
              .dispatch('LoadsStore/loadBatchesTemplate', {
                id: response.id,
                amount:
                  this.allowMultipleLoad && parseInt(this.totalLoadsMultiple) > 0
                    ? parseInt(this.totalLoadsMultiple)
                    : 1,
              })
              .then((response) => {
                // NOW CREATED A NEW ARRAY WITH ALL SHIPMENTS IDS

                const shipmentObjects = response.batch.map((item) => ({ id: item.shipment.id }));
                if (shipmentObjects) {
                  this.$store
                    .dispatch('LoadsStore/directBatchAssign', {
                      company: { id: this.userSelected?.id },
                      shipments: shipmentObjects,
                      price: this.price ? +this.price : null,
                    })
                    .then(async () => {
                      BaseComponent.methods?.showToastSuccess(
                        this.$t('multiple-loads-created', { count: this.totalLoadsMultiple }),
                      );
                      this.nextRoute('/planning');
                    })
                    .catch((err) => {
                      BaseComponent.methods?.showToastError(err?.response?.data?.title);
                      if (err?.response?.data?.errorCode === 'C-18') {
                        if (this.creditAvailable === 0) {
                          this.$store.commit('CreditStore/setNoCreditModal', true);
                        } else if (this.price && +this.price > this.creditAvailable) {
                          this.$store.commit('CreditStore/setPlanimatikCreditModal', true);
                        }
                      }
                    })
                    .finally(() => {
                      this.$store.dispatch('LoadsStore/currentLoadEditing', null);
                      this.allowed = true;
                      this.sendingAction = false;
                    });
                }
              });
          } else {
            this.$store
              .dispatch('LoadsStore/directBatchAssign', {
                company: { id: this.userSelected?.id },
                shipments: [{ id: response.shipment.id }],
                price: this.price ? +this.price : null,
              })
              .then(async () => {
                BaseComponent.methods?.showToastSuccess(this.$t('load-created-correctly'));

                if (this.warehousesAllowed) {
                  this.$store.dispatch('LoadsStore/showModalQuoteSended', true);
                } else {
                  this.$store.dispatch('LoadsStore/showModalCarrierAssigned', true);
                }
                this.nextRoute('/planning');
              })
              .catch((err) => {
                BaseComponent.methods?.showToastError(err?.response?.data?.title);
                if (err?.response?.data?.errorCode === 'C-18') {
                  if (this.creditAvailable === 0) {
                    this.$store.commit('CreditStore/setNoCreditModal', true);
                  } else if (this.price && +this.price > this.creditAvailable) {
                    this.$store.commit('CreditStore/setPlanimatikCreditModal', true);
                  }
                }
              })
              .finally(() => {
                this.$store.dispatch('LoadsStore/currentLoadEditing', null);
                this.allowed = true;
                this.sendingAction = false;
              });
          }
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.title);
        });

      // BaseComponent.methods?.showToastSuccess(this.$t('load-created-correctly'));
      // this.assignUser(response, route);
      // this.$emit('close', 'DATA');
      // });
    },
    getUserInfo() {
      this.$store.dispatch('UserStore/getUser', { id: this.currentUser.id }).then((response) => {
        this.$store.commit('UserStore/setBillingCompany', {
          name: '',
          email: '',
          taxId: '',
          country: '',
          state: '',
          city: '',
          address: '',
          zip: '',
        });
      });
    },
  },
  watch: {
    name: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.findUser(newValue);
      },
    },
  },
});
