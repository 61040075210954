import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-g-md" }
const _hoisted_2 = { class: "flex flex-col plan-gap-sm" }
const _hoisted_3 = { class: "flex justify-between items-center" }
const _hoisted_4 = { class: "plan-t-prim-200 font-bold" }
const _hoisted_5 = { class: "flex justify-between items-center" }
const _hoisted_6 = { class: "plan-t-prim-200 font-bold" }
const _hoisted_7 = { class: "flex justify-between items-center" }
const _hoisted_8 = { class: "plan-t-prim-200 font-bold" }
const _hoisted_9 = { class: "flex justify-between items-center" }
const _hoisted_10 = { class: "plan-t-prim-200 font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_load_detail_documents_table = _resolveComponent("load-detail-documents-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.docs?.load_docs?.length) + " " + _toDisplayString(_ctx.$t('load_docs')), 1),
        (_ctx.docs?.load_docs?.length > 0)
          ? (_openBlock(), _createBlock(_component_plan_button_icon, {
              key: 0,
              onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showLoadDocs = !_ctx.showLoadDocs)),
              icon: _ctx.showLoadDocs ? 'chevron-up':'chevron-down',
              type: "transparent-gray-800"
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showLoadDocs)
        ? (_openBlock(), _createBlock(_component_load_detail_documents_table, {
            key: 0,
            items: _ctx.docs?.load_docs
          }, null, 8, ["items"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.docs?.stop_docs?.length) + " " + _toDisplayString(_ctx.$t('stop_docs')), 1),
        (_ctx.docs?.stop_docs?.length > 0)
          ? (_openBlock(), _createBlock(_component_plan_button_icon, {
              key: 0,
              onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showStopDocs = !_ctx.showStopDocs)),
              icon: _ctx.showStopDocs ? 'chevron-up':'chevron-down',
              type: "transparent-gray-800"
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showStopDocs)
        ? (_openBlock(), _createBlock(_component_load_detail_documents_table, {
            key: 1,
            items: _ctx.docs?.stop_docs
          }, null, 8, ["items"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.docs?.other_docs?.length) + " " + _toDisplayString(_ctx.$t('other_docs')), 1),
        (_ctx.docs?.other_docs?.length > 0)
          ? (_openBlock(), _createBlock(_component_plan_button_icon, {
              key: 0,
              onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showOtherDocs = !_ctx.showOtherDocs)),
              icon: _ctx.showOtherDocs ? 'chevron-up':'chevron-down',
              type: "transparent-gray-800"
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showOtherDocs)
        ? (_openBlock(), _createBlock(_component_load_detail_documents_table, {
            key: 2,
            items: _ctx.docs?.other_docs
          }, null, 8, ["items"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.docs?.invoice_docs?.length) + " " + _toDisplayString(_ctx.$t('invoice_docs')), 1),
        (_ctx.docs?.invoice_docs?.length > 0)
          ? (_openBlock(), _createBlock(_component_plan_button_icon, {
              key: 0,
              onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showInvoiceDocs = !_ctx.showInvoiceDocs)),
              icon: _ctx.showInvoiceDocs ? 'chevron-up':'chevron-down',
              type: "transparent-gray-800"
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showInvoiceDocs)
        ? (_openBlock(), _createBlock(_component_load_detail_documents_table, {
            key: 3,
            items: _ctx.docs?.invoice_docs
          }, null, 8, ["items"]))
        : _createCommentVNode("", true)
    ])
  ]))
}