import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex flex-col plan-gap-sm" }
const _hoisted_3 = {
  key: 0,
  class: "flex justify-end items-center plan-gap-xs plan-t-prim-100 plan-t-xs italic"
}
const _hoisted_4 = { class: "flex justify-between items-start w-full" }
const _hoisted_5 = { class: "flex justify-start" }
const _hoisted_6 = { class: "flex justify-end space-x-4" }
const _hoisted_7 = { class: "p-4 rounded-lg mt-4" }
const _hoisted_8 = { class: "font-bold mb-2 mt-2" }
const _hoisted_9 = { class: "text-gray-700 mb-4" }
const _hoisted_10 = { class: "flex flex-row gap-2" }
const _hoisted_11 = { class: "w-1/2 mb-4" }
const _hoisted_12 = {
  for: "email",
  class: "block text-gray-700 font-bold"
}
const _hoisted_13 = { class: "w-1/2 mb-4" }
const _hoisted_14 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_15 = { class: "inline-flex items-center" }
const _hoisted_16 = { class: "ml-2 text-gray-700" }
const _hoisted_17 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_tracking_history_modal = _resolveComponent("tracking-history-modal")!
  const _component_plan_google_map = _resolveComponent("plan-google-map")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_input_location = _resolveComponent("plan-input-location")!
  const _component_plan_input_text_area = _resolveComponent("plan-input-text-area")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_phone_number_selector = _resolveComponent("phone-number-selector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isShipper)
      ? (_openBlock(), _createBlock(_component_plan_button, {
          key: 0,
          onOnClick: _ctx.showUpdatePosition,
          type: "primary-dark",
          label: _ctx.$t('update-manual')
        }, null, 8, ["onOnClick", "label"]))
      : (_openBlock(), _createBlock(_component_plan_button, {
          key: 1,
          onOnClick: _ctx.showUpdatePosition,
          type: "primary",
          label: _ctx.$t('update-position'),
          "show-icon": "",
          "icon-type": "svg",
          icon: "icInRoute",
          "full-rounded": "",
          class: "m-1"
        }, null, 8, ["onOnClick", "label"])),
    (_ctx.showUpdatePositionModal)
      ? (_openBlock(), _createBlock(_component_plan_default_modal, {
          key: 2,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showUpdatePositionModal = false)),
          title: _ctx.$t('update-position'),
          size: "sm-auto",
          "hide-close-button": "",
          "without-sidebar": ""
        }, {
          "header-buttons": _withCtx(() => [
            (!_ctx.isShipper)
              ? (_openBlock(), _createBlock(_component_plan_button, {
                  key: 0,
                  onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTrackingHistoryModal = true)),
                  type: "primary-transparent",
                  label: _ctx.$t('tracking-history'),
                  "show-icon": "",
                  icon: "clock",
                  "icon-color": "#0097FF"
                }, null, 8, ["label"]))
              : _createCommentVNode("", true)
          ]),
          content: _withCtx(() => [
            (_ctx.showTrackingHistoryModal)
              ? (_openBlock(), _createBlock(_component_tracking_history_modal, {
                  key: 0,
                  onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showTrackingHistoryModal = false))
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('share-position-with-shipper')), 1),
              _createVNode(_component_plan_google_map, {
                "new-coordinates-selected": _ctx.newCoordinatesSelected,
                lat: _ctx.initialLocation.lat,
                lon: _ctx.initialLocation.lon,
                height: 300
              }, null, 8, ["new-coordinates-selected", "lat", "lon"]),
              (_ctx.loadingInitialLocation)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_plan_icon, {
                      name: "spinner",
                      "icon-color": "#8A8B97",
                      size: "15",
                      "is-svg": "",
                      rotate: ""
                    }),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('getting-your-location')) + "..", 1)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_plan_input_location, {
                onChange: _ctx.changeInputLocation,
                "hide-label": "",
                "pre-country": _ctx.initialLocation.country,
                "pre-location": _ctx.initialLocation.zip
              }, null, 8, ["onChange", "pre-country", "pre-location"]),
              _createVNode(_component_plan_input_text_area, {
                "model-value": _ctx.observations,
                "onUpdate:model-value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.observations) = $event)),
                placeholder: _ctx.$t('add-observations'),
                rows: 4
              }, null, 8, ["model-value", "placeholder"])
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_plan_button, {
                  onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showUpdatePositionModal = false)),
                  disabled: _ctx.loadingPostNotifyPosition,
                  type: "cancel",
                  label: _ctx.$t('cancel')
                }, null, 8, ["disabled", "label"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                (!_ctx.isDriver && _ctx.shipment?.driver?.id)
                  ? (_openBlock(), _createBlock(_component_plan_button, {
                      key: 0,
                      onOnClick: _ctx.handleShowRequestModal,
                      type: "primary-outline",
                      label: _ctx.$t('driver-request-data')
                    }, null, 8, ["onOnClick", "label"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_plan_button, {
                  onOnClick: _ctx.postNotifyPosition,
                  loading: _ctx.loadingPostNotifyPosition,
                  disabled: _ctx.placeId === '',
                  type: "primary",
                  label: _ctx.$t('send'),
                  style: {"min-width":"100px"}
                }, null, 8, ["onOnClick", "loading", "disabled", "label"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.showUpdateCarrierData)
      ? (_openBlock(), _createBlock(_component_plan_default_modal, {
          key: 3,
          onClose: _ctx.handleCloseUpdate,
          title: _ctx.$t('driver-info-title'),
          size: "sm-auto",
          "hide-close-button": "",
          "without-sidebar": ""
        }, {
          "header-buttons": _withCtx(() => []),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t('contact-information-shipper').toLocaleUpperCase()), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(!_ctx.isEuropeCompany() ? _ctx.$t('driver-info-text-usa') : _ctx.$t('driver-info-text-eu')), 1),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('email')), 1),
                  _createVNode(_component_Field, {
                    class: "h-[42px] min-h-[42px] max-h-[42px] shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
                    id: "email",
                    type: "email",
                    modelValue: _ctx.driver.email,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.driver.email) = $event)),
                    name: "email",
                    placeholder: "name@mail.com",
                    rules: "max:256|email"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "email",
                    class: "text-rose-500"
                  })
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_phone_number_selector, {
                    borderColor: 'border',
                    "classes-for-labels": 'block text-gray-700 font-bold',
                    isCarrier: true,
                    textStyles: 'text-gray-700 leading-tight',
                    prefix: _ctx.driver.phonePrefix,
                    number: _ctx.driver.phone,
                    onChangePhone: _ctx.changePhone
                  }, null, 8, ["prefix", "number", "onChangePhone"])
                ])
              ]),
              (_ctx.driver.email || _ctx.driver.phone)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("label", _hoisted_15, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.driver.showContactInformation) = $event)),
                        type: "checkbox",
                        class: "form-checkbox text-blue-600"
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.driver.showContactInformation]
                      ]),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('allow-driver-info')), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.handleCloseUpdate,
                type: "cancel",
                label: _ctx.$t('cancel')
              }, null, 8, ["onOnClick", "label"]),
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.UpdateCarrier,
                loading: _ctx.loadingPostNotifyPosition,
                disabled: !_ctx.checkDriver,
                type: "primary",
                label: _ctx.$t('send'),
                style: {"min-width":"100px"}
              }, null, 8, ["onOnClick", "loading", "disabled", "label"])
            ])
          ]),
          _: 1
        }, 8, ["onClose", "title"]))
      : _createCommentVNode("", true)
  ]))
}