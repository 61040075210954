
import { defineComponent } from 'vue';
import { saveAs } from 'file-saver';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import LoadLogs from '@/components/loads/LoadLogs.vue';
import GoogleMap from '@/components/map/GoogleMap.vue';
import LoadDetails from '@/modules/planning/loadDetail/_components/LoadDetails.vue';
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
import ModalLocationUpdate from './LocationUpdateModal.vue';
import ModalMailToCarrier from './MailToCarrierModal.vue';
import ShowIncidenceModal from '@/modules/planning/userProfile/ShowIncidenceModal.vue';
import PlanDefaultCityInformation from '@/components/defaults/PlanDefaultCityInformation.vue';
import UploadDocuments from '@/modules/carrier/modals/UploadDocuments.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanRfpHistoryTracking from '@/components/rfp/PlanRfpHistoryTracking.vue';
import UpdatePositionModal from '@/modules/carrier/quotationRequest/_componentes/UpdatePositionModal.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';
interface ICourse {
  showUploadModal: boolean;
  // showModalSendLocation: boolean;
  loadSelectedForDocuments: number;
  truckLocation: string;
  showModalUpdate: boolean;
  showModalUpdateMail: boolean;
  showModalRequest: boolean;
  textRequestPosition: string;
  fetchedImageOriginal: any;
  showUploadDocumentsModal: boolean;
  loadSelected: any;
  showMap: boolean;
  stopSelected: any;
  isReSendingEmail: number;
  file: any;
  isUploadingFile: any;
  needUpdateTrackingHistory: boolean;
}

export default defineComponent({
  name: 'ShipperLoadDetailCourse',
  components: {
    UpdatePositionModal,
    PlanButton,
    PlanRfpHistoryTracking,
    PlanDefaultCityInformation,
    GoogleMap,
    LoadDetails,
    ModalConfirm,
    ModalLocationUpdate,
    ModalMailToCarrier,
    ShowIncidenceModal,
    UploadDocuments,
    LoadLogs,
  },
  mixins: [BaseComponent, CountryMixins],
  data(): ICourse {
    return {
      showModalUpdateMail: false,
      showUploadModal: false,
      loadSelectedForDocuments: 0,
      truckLocation: '',
      showModalUpdate: false,
      showModalRequest: false,
      fetchedImageOriginal: null,
      textRequestPosition: this.$t('request-location-text'),
      showMap: true,
      stopSelected: null,
      showUploadDocumentsModal: false,
      loadSelected: null as any,
      isReSendingEmail: 0,
      file: null as any,
      isUploadingFile: false,
      needUpdateTrackingHistory: false
    };
  },
  computed: {
    isEurope(): boolean {
      const country = this.dataCountries?.find(
        (country) => this.currentUser?.company?.country?.id === country?.id,
      );
      return country?.region?.name === 'EUROPE';
    },
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    truncatedObservations() {
      const maxLength = 50;
      const observations = this.currentLoad.shipment.currentTracking.observations;
      return observations;
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    orderedStops(): any {
      let load = this.currentLoad;
      return load.stops;
    },
  },
  watch: {
    currentLoad() {
      if (this.currentLoad?.shipment?.currentTracking.id !== undefined) {
        this.showMap = false;
        setTimeout(() => {
          this.showMap = true;
        }, 5);
      }
    },
  },
  methods: {
    truncateString(string) {
      if (string && string.length > 10) {
        return string.substring(0, 11) + '...';
      } else {
        return string;
      }
    },
    fetch() {
      this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
    },

    handleUploadDocumentsModal(load) {
      this.loadSelected = load;
      this.showUploadDocumentsModal = true;
    },
    showAccessDenied() {
      BaseComponent.methods?.showToastError(this.$t('access-denied-no-action'));
    },
    resendEmails(id) {
      if (this.isSuperAdminRoute) {
        return;
      }
      this.isReSendingEmail = Number(id);
      this.$store
        .dispatch('UserStore/resendEmail', {
          id: id,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('email-sent-correctly'));
          this.isReSendingEmail = 0;
          this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
        });
    },
    openModalIncidence(stop) {
      this.$store.dispatch('LoadsStore/stopSelected', stop);
      setTimeout(() => {
        this.$store.dispatch('LoadsStore/showIncidenceModal', true);
      }, 100);
    },
    getLastDocumentUploaded(index): any {
      const biggestObject = this.orderedStops[index].mediaObjects.reduce((acc, cur) => {
        return cur.id > acc.id ? cur : acc;
      });

      return biggestObject;
    },
    async download(stop, mediaObjectId, mediaObjectType) {
      this.handleUploadDocumentsModal(stop);
    },
    requestLocation() {
      if (this.isSuperAdminRoute) {
        return;
      }
      this.showModalRequest = true;
    },
    submitRequest(data: number) {
      if (!data) {
        this.showModalRequest = false;
      } else {
        this.$store
          .dispatch('ShipmentStore/requestPosition', this.currentLoad.shipment.id)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('requested-location'));
            this.showModalRequest = false;
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            this.showModalRequest = false;
          });
      }
    },
    showIncidentsModal(load) {
      const result = this.currentLoad.stops.find((obj) => obj.sequence === +load.sequence);
      this.stopSelected = result;
      this.showUploadModal = true;
    },
    locationUpdate(location: any) {
      if (location !== null) {
        this.showMap = false;
        this.$store
          .dispatch('ShipmentStore/notifyPosition', location)
          .then(() => {
            this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id).then(() => {
              BaseComponent.methods?.showToastSuccess(
                this.$t('toast-new-location', { location: location.location.city }),
              );
              this.showModalUpdate = false;
              this.showMap = true;
            });
          })
          .catch((err) => {
            if (err?.response?.status === 422) {
              BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            } else {
              BaseComponent.methods?.showToastError(this.$t('message-location-not-correct'));
            }
            this.showModalUpdate = false;
          });
      } else {
        this.showModalUpdate = false;
      }
    },
  },
});
