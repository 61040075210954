import loads from '@/services/loads/_api';

export default {
  async annotations(context: any, data: any) {
    await loads.annotations(data);
    // context.commit('setAnnotations', response);
  },
  async apiPostLoadCustomerAssign(context: any, payload: any) {
    const { id, customerId } = payload;
    const params = { customerId: customerId };
    return await loads.postLoadCustomerAssign(id, params);
  },
  async apiPostLoadCustomerDeAssign(context: any, payload: any) {
    const { id, customerId } = payload;
    const params = { customerId: customerId };
    return await loads.postLoadCustomerDeAssign(id, params);
  },
  async postNotifyPosition(context: any, data: any) {
    const response = await loads.postNotifyPosition(data);
    return response;
  },
  async postMediaObjectLoad(context: any, data: object) {
    const response = await loads.postMediaObjectLoad(data);

    return response;
  },
  async cancelLoad(context: any, id: any) {
    return await loads.cancelLoad(id);
  },

  //BOL and Documents
  async sendEmailDriver(context: any, id: any) {
    return await loads.sendEmailDriver(id);
  },
  //Request Notify Position to Driver
  async sendEmailDriverNotifyPosition(context: any, id: any) {
    return await loads.sendEmailDriverNotifyPosition(id);
  },

  async sendEmailDriverIncident(context: any, id: any) {
    return await loads.sendEmailDriverIncident(id);
  },

  async savePreviousFilters(context: any, filters) {
    context.commit('setAppliedFilters', filters);
  },

  async carrierBidData(context: any, data: object) {
    context.commit('carrierBidData', data);
  },
  async clearLoadsStore(context: any) {
    context.commit('clearLoadsStore');
  },
  async checkRateConfirmation(context: any, data: object) {
    return await loads.checkRateConfirmation(data);
  },
  async createLoad(context: any, data: object) {
    return await loads.createLoad(data);
  },
  async createLoadLTL(context: any, data: object) {
    return await loads.createLoadLTL(data);
  },
  async currentBid(context: any, data: number) {
    context.commit('currentBid', data);
  },
  async currentLoadEditing(context: any, data: object) {
    context.commit('currentLoadEditing', data);
  },
  async deleteEmailCategory(context: any, data: object) {
    return await loads.deleteEmailCategory(data);
  },
  async deleteLanes(context: any, data: object) {
    return await loads.deleteLanes(data);
  },
  async deleteLoad(context: any, id: any) {
    return await loads.deleteLoad(id);
  },
  async deleteSaveLoad(context: any, id: any) {
    return await loads.deleteSaveLoad(id);
  },
  async deleteWarehouse(context: any, id: any) {
    return await loads.deleteWarehouse(id);
  },
  async directBatchAssign(context: any, data: object) {
    return await loads.directBatchAssign(data);
  },
  async downloadExcelTemplateImportWarehouses(context: any) {
    return await loads.downloadExcelTemplateImportWarehouses();
  },
  async draftConsignee(context: any, data: object) {
    return await loads.draftConsignee(data);
  },
  async findCarriers(context: any, data: object) {
    const response = await loads.findCarriers(data);
    context.commit('filteredCarriers', response.data);
    return response;
  },
  async fillLoadConsignee(context: any, data: object) {
    return await loads.fillLoadConsignee(data);
  },
  async putBillContactInfo(context: any, data: object) {
    return await loads.putBillContactInfo(data);
  },
  async putRateGoal(context: any, data: object) {
    return await loads.putRateGoal(data);
  },
  async getAutocompleteGoogle(context: any, data: any) {
    return await loads.getAutocompleteGoogle(data.zip, data.country);
  },
  async getCoordinatesGoogle(context: any, data: any) {
    return await loads.getCoordinatesGoogle(data.lat, data.lng);
  },
  async getEmailCategories(context: any) {
    return await loads.getEmailCategories();
  },
  async getEmailCategoriesAndTemplates(context: any) {
    return await loads.getEmailCategoriesAndTemplates();
  },
  async getEmailTemplatesFromPlanimatik(context: any) {
    return await loads.getEmailTemplatesFromPlanimatik();
  },
  async getPlaceDetailsGoogle(context: any, data: any) {
    return await loads.getPlaceDetailsGoogle(data.placeId);
  },
  async getClaimsTooltip(context: any, loadId: number) {
    return await loads.getClaimsTooltip(loadId);
  },
  async getTotalClaims(context: any) {
    const response = await loads.getTotalClaims();
    context.commit('setTotalClaims', response.total);
  },
  async getdensityClass(context: any, data: any) {
    const response = await loads.getdensityClass(data);
    context.commit('densityClass', response);
  },
  async getDocsList(context: any, data: any) {
    const response = await loads.getDocsList(data);
    context.commit('setDocsList', response);
  },
  async getFeeExplanations(context: any) {
    const response = await loads.getFeeExplanations();
    context.commit('feeExplanations', response);
  },
  async getLanes(context: any, data: object) {
    const response = await loads.getLanes(data);
    context.commit('getLanes', response);
    return response;
  },
  async getLimitedAcces(context: any, data: any) {
    const response = await loads.getLimitedAcces(data);
    context.commit('getLimitedAcces', response);
  },
  async getLoadInfo(context: any, id: object) {
    const response = await loads.loadInfo(id);
    context.commit('setLoadInfo', response.data);
    return response;
  },
  async getLoadsInfo(context: any, query: object) {
    const response = await loads.loadsInfo(query);
    if (response.data.loads) {
      context.commit('setLoadsInfo', response.data.loads);
    }
    return response;
  },

  async getTrackingLogs(context: any, id: number) {
    const response = await loads.getTrackingLogs(id);
    context.commit('setTrackingLogs', response);
  },

  async getLoadFieldAutocomplete(context: any, data: object) {
    return await loads.getLoadFieldAutocomplete(data);
  },
  async getLTLBOL(context: any, data: object) {
    return await loads.getLTLBOL(data);
  },
  async getLTLQuotes(context: any, data: object) {
    const response = await loads.getLTLQuotes(data);
    context.commit('ltlQuotes', response);
    return response;
  },
  async getLTLQuotesById(context: any, data: any) {
    const response = await loads.getLTLQuotesById(data);
    context.commit('getLTLQuotesById', response);
  },
  async getWarehouses(context: any, payload) {
    const { zip, name } = payload;
    const params = { zipCode: zip, name: name, page: 1, maxResults: 100 };
    const response = await loads.getWarehouses(params);
    context.commit('setWarehouses', response);
    return response;
  },
  async getWarehousesList(context: any, payload) {
    const params = {
      page: payload.page,
      multisearch: payload.multisearch,
      zipCode: payload.zipCode,
      sortBy: payload.sortBy,
      maxResults: payload.maxResults,
      countryId: payload.countryId,
    };

    return await loads.getWarehousesList(params);
  },
  async getNMFC(context: any, data: number) {
    const response = await loads.getNMFC(data);
    context.commit('NMFC', response);
  },
  async getRankingRFP(context: any, data: any) {
    const response = await loads.getRankingRFP(data);
    context.commit('setRankingRFP', response);
  },
  async getSaveLoads(context: any, query: object) {
    const response = await loads.getSaveLoads(query);
    context.commit('setSaveLoads', response);
  },
  async getSaveLoadsSearch(context: any, query: object) {
    const response = await loads.getSaveLoadsSearch(query);
    context.commit('setSaveLoadsSearch', response);
  },
  async getShipmentAverageStats(context: any, data: any) {
    const response = await loads.getShipmentAverageStats(
      data.origin,
      data.destiny,
      data.trailer,
      data.period,
    );
    return response;
  },
  async getShipmentFees(context: any, id: object) {
    const response = await loads.getShipmentFees(id);
    context.commit('setShipmentFees', response);
  },
  async getTrackingHistory(context: any, id: object) {
    return await loads.getTrackingHistory(id);
  },
  async loadBatchesTemplate(context: any, data: object) {
    return await loads.loadBatchesTemplate(data);
  },

  async quotesAverageStats(context: any, data: object) {
    const response = await loads.quotesAverageStats(data);
    context.commit('setQuotesAverageStats', response);
    return response;
  },

  async loadById(context: any, id: number) {
    const response = await loads.loadsById(id);
    context.commit('currentLoad', response);
    return response;
  },
  async loadByIdAdmin(context: any, id: number) {
    const response = await loads.loadsByIdAdmin(id);
    context.commit('currentLoad', response);
    return response;
  },
  async loadId(context: any, id: number) {
    context.commit('loadId', id);
  },

  async deleteLoadMediaObject(context: any, id: number) {
    const response = await loads.deleteLoadMediaObject(id);
    return response;
  },

  async loads(context: any, query: object) {
    // Only keep track of the latest API call
    const currentApiCallId = Symbol();
    context.commit('lastApiCallId', currentApiCallId);
    const response = await loads.loads(query).then((response) => {
      if (context.state.lastApiCallId === currentApiCallId) {
        context.commit('setLoads', response);
      }
    });
  },
  async loadsAdmin(context: any, query: object) {
    // Only keep track of the latest API call
    const currentApiCallId = Symbol();
    context.commit('lastApiCallId', currentApiCallId);
    const response = await loads.loadsAdmin(query).then((response) => {
      if (context.state.lastApiCallId === currentApiCallId) {
        context.commit('setLoads', response);
      }
    });
  },
  async loadsTotals(context: any, query: object) {
    const response = await loads.totals(query);
    context.commit('setTotals', response);
  },
  async loadsTotalsAdmin(context: any, query: object) {
    const response = await loads.totalsAdmin(query);
    context.commit('setTotals', response);
  },
  async newMessageLoad(context: any, data: any) {
    context.commit('newMessageLoad', data);
  },
  async loadShipmentId(context: any, id: any) {
    context.commit('loadShipmentId', id);
  },
  async loadsByDates(context: any, query: object) {
    const response = await loads.loadsByDates(query);
    context.commit('setLoadsByDates', response);
  },
  async loadsByDatesAdmin(context: any, query: object) {
    const response = await loads.loadsByDatesAdmin(query);
    context.commit('setLoadsByDates', response);
  },
  async modifyLoad(context: any, data: object) {
    return await loads.modifyLoad(data);
  },
  async packageTypes(context: any, data: number) {
    const response = await loads.getpackageTypes(data);
    context.commit('packageTypes', response);
  },
  async plannerTotalsDayWeek(context: any, filters: any) {
    const response = await loads.plannerTotalsDayWeek(filters);
    context.commit('plannerTotalsDayWeek', response);
  },
  async getQuickFiltersTotal() {
    const response = await loads.plannerTotalsDayWeek({});
    return response;
  },
  async plannerTotalsDayWeekAdmin(context: any, filters: any) {
    const response = await loads.plannerTotalsDayWeekAdmin(filters);
    context.commit('plannerTotalsDayWeek', response);
  },
  async postCreateAdditionalFee(context: any, data: object) {
    const response = await loads.postCreateAdditionalFee(data);
    return response;
  },
  async postCreateAdditionalFeeMedia(context: any, data: object) {
    const response = await loads.postCreateAdditionalFeeMedia(data);
  },
  async postEmailForRate(context: any, data: object) {
    const response = await loads.postEmail(data);
  },
  async postEmailCategory(context: any, data: object) {
    const response = await loads.postEmailCategory(data);
    return response.data;
  },
  async postLane(context: any, data: any) {
    const response = await loads.postLane(data);
  },
  async postSaveLoads(context: any, data: object) {
    const response = await loads.postSaveLoads(data);
    // context.commit('setSaveLoads', response);
  },
  async postValoration(context: any, data: any) {
    const response = await loads.postValoration(data);
    context.commit('setValoration', response);
  },
  async putValoration(context: any, data: any) {
    const response = await loads.putValoration(data);
    context.commit('setValoration', response);
  },
  async postWarehouse(context: any, data: any) {
    const response = await loads.postWarehouse(data);
    return response;
  },
  async postWarehousesFromExcel(context: any, data: any) {
    const response = await loads.postWarehousesFromExcel(data);
    return response;
  },
  async postWarehousesFromJson(context: any, data: any) {
    const response = await loads.postWarehousesFromJson(data);
    return response;
  },
  async postCreateWarehouse(context: any, data: any) {
    const response = await loads.postCreateWarehouse(data);
    return response;
  },
  async putEmailCategory(context: any, data: any) {
    const response = await loads.putEmailCategory(data);
    return response;
  },
  async putWarehouse(context: any, data: any) {
    const response = await loads.putWarehouse(data);
    return response;
  },
  async updateWarehouse(context: any, data: any) {
    const response = await loads.updateWarehouse(data);
    return response;
  },
  async putSaveLoads(context: any, data: object) {
    const response = await loads.putSaveLoads(data);
    // context.commit('setSaveLoads', response);
  },
  async putApproveAdditionalFee(context: any, id: number) {
    return await loads.putApproveAdditionalFee(id);
  },
  async putDenyAdditionalFee(context: any, id: number) {
    return await loads.putDenyAdditionalFee(id);
  },
  async putLTLBol(context: any, data: object) {
    return await loads.putLTLBol(data);
  },
  async relaunchRankingRFP(context: any, data: any) {
    return await loads.relaunchRankingRFP(data);
  },
  async saveLoadId(context: any, id: any) {
    const response = await loads.getSaveLoadById(id);
    context.commit('setSaveLoadId', response);
  },
  async sendRequestBidEmail(context: any, data: object) {
    return await loads.sendRequestBidEmail(data);
  },
  async setBookNowPrice(context: any, query: any) {
    await loads.setBookNowPrice(query);
  },
  async shipmentRequestBatch(context: any, data: object) {
    const response = loads.shipmentRequestBatch(data);
    return response;
  },
  async showFreightInfoModal(context: any, data: boolean) {
    context.commit('showFreightInfoModal', data);
  },
  async showIncidenceModal(context: any, data: any) {
    context.commit('showIncidenceModal', data);
  },
  showModalCarrierAssigned(context: any, assigned: boolean) {
    context.commit('updateModalCarrierAssigned', assigned);
  },
  showModalQuoteSended(context: any, assigned: boolean) {
    context.commit('updateModalQuoteSended', assigned);
  },
  async showTemplateModal(context: any, data: any) {
    context.commit('showTemplateModal', data);
  },
  async states(context: any) {
    const response = await loads.states();
    context.commit('setStates', response);
  },
  async stopSelected(context: any, data: any) {
    context.commit('stopSelected', data);
  },
  async totalSavedBidsLTL(context: any, id: number) {
    const response = await loads.totalSavedBidsLTL(id);
    context.commit('totalSavedBidsLTL', response);
    return response;
  },
  async trackingUpdateAlert(context: any, data: object) {
    return await loads.trackingUpdateAlert(data);
  },
  async trailers(context: any) {
    const response = await loads.trailers();
    context.commit('setTrailerTypes', response);
  },
  async updateETA(context: any, data: object) {
    return await loads.updateETA(data);
  },
  async updateLanes(context: any, data: object) {
    return await loads.updateLanes(data);
  },
  updateShipmentNextStep({ commit }, value) {
    commit('setShipmentNextStep', value);
  },
};
