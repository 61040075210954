
import PlanDefaultView from '@/components/defaults/PlanDefaultView.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import { defineComponent, reactive, toRefs, computed } from 'vue';
import { ActionContext, useStore } from 'vuex';
import AddWarehouseModal from '@/modules/planning/warehouses/_modals/AddWarehouseModal.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import UpdateWarehouseModal from '@/modules/planning/warehouses/_modals/UpdateWarehouseModal.vue';
import PlanLoading from '@/components/spin/PlanLoading.vue';
import PlanHeaderSearch from '@/components/filters/PlanHeaderSearch.vue';
import PlanDefaultDeleteModal from '@/components/defaults/PlanDefaultDeleteModal.vue';
import PlanDefaultLoadingModal from '@/components/defaults/PlanDefaultLoadingModal.vue';
import { useI18n } from 'vue-i18n';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { IState } from '@/services/user/interfaces';
import { debounce } from 'lodash';
import ImportWarehousesFromExcelModal from '@/modules/planning/warehouses/_modals/ImportWarehousesFromExcelModal.vue';
import * as XLSX from 'xlsx';

interface warehouseList {
  deleteItem: any;
  deleteItemLoading: boolean;
  loadingWarehouses: boolean;
  loadingWarehousesFromJson: boolean,
  page: number;
  query: string;
  showAddWarehouse: boolean;
  showImportWarehouses: boolean,
  showUpdateWarehouse: boolean;
  showWarehousesToImport: boolean,
  totalWarehouses: number;
  warehouses: Array<any>;
  warehousesCannotBeImport: Array<any>;
  warehousesToImport: Array<any>;
  selectedItems: Array<any>;
  selectedWarehousesToImport: Array<any>;
  order: {
    value: string;
    direction: string;
  },
  viewMode: string
}

export default defineComponent({
  name: 'WarehousesList',
  components: {
    ImportWarehousesFromExcelModal,
    PlanIcon,
    PlanDefaultDeleteModal,
    PlanHeaderSearch,
    PlanLoading,
    UpdateWarehouseModal,
    PlanButtonIcon,
    AddWarehouseModal,
    PlanDefaultView,
    PlanButton,
    PlanDefaultTable,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const data = reactive<warehouseList>({
      deleteItem: null,
      deleteItemLoading: false,
      loadingWarehouses: false,
      loadingWarehousesFromJson: false,
      page: 1,
      query: '',
      showAddWarehouse: false,
      showImportWarehouses: false,
      showUpdateWarehouse: false,
      showWarehousesToImport: false,
      totalWarehouses: 0,
      warehouses: [],
      warehousesCannotBeImport: [],
      warehousesToImport: [],
      selectedItems: [],
      selectedWarehousesToImport: [],
      order: {
        value: 'companyWarehouse',
        direction: 'asc',
      },
      viewMode: 'list'
    });

    const headers = computed(() => {
      return [
        { label: t('warehouse'), value: 'companyWarehouse', sortable: true },
        { label: t('address'), value: 'address' },
        { label: t('city'), value: 'zip', sortable: true },
        { label: t('country'), value: 'country', sortable: true },
        { label: t('contact-person'), value: 'contactName' },
        { label: t('phone'), value: 'phone' },
        { label: '', value: 'actions' },
      ];
    })

    const importHeaders = computed(() => {
      return [
        { label: t('warehouse'), value: 'companyWarehouse' },
        { label: t('address'), value: 'address' },
        { label: t('country'), value: 'country' },
        { label: t('zip-code'), value: 'zip' },
        { label: t('contact-person'), value: 'contactName' },
        { label: t('phone'), value: 'phone' }
      ];
    })

    const changeQuery = (newQuery) => {
      data.query = newQuery;
      data.page = 1;
      debouncedWarehouses();
    };

    const changeOrder = (newOrder: any) => {
      data.order = newOrder;
      data.page = 1;
      getWarehouses();
    };

    const changeSelectedWarehousesToImport = (selectedWarehousesToImport: any) => {
      data.selectedWarehousesToImport = selectedWarehousesToImport
    }

    const cleanImport = () => {
      data.selectedWarehousesToImport = []
      data.warehousesToImport = []
      data.viewMode = 'list'
    }

    const closeAddAndUpdateWarehouse = () => {
      data.showAddWarehouse = false;
      store.dispatch('UserStore/showToast', t('warehouse-form.add-success'));
      getWarehouses();
    };

    const closeDeleteModal = () => {
      if (!data.deleteItemLoading) data.deleteItem = null;
    };

    const closeUpdateWarehouse = () => {
      store.commit('LoadsStore/setSelectedWarehouse', {});
      data.showUpdateWarehouse = false;
    };

    const closeUpdatedWarehouse = () => {
      store.commit('LoadsStore/setSelectedWarehouse', {});
      store.dispatch('UserStore/showToast', t('warehouse-form.update-success'));
      getWarehouses();
      data.showUpdateWarehouse = false;
    };

    const createExcelFile = () => {
      const xlsxContent = [
        ["Company", "Address", "Contact", "PhonePrefix", "Phone", "Country", "Zipcode"]
      ]

      data.warehousesCannotBeImport.forEach((warehouse) => {
        xlsxContent.push([warehouse.CompanyName, warehouse.Address, warehouse.Contact, warehouse.PhonePrefix, warehouse.Phone, warehouse.Country, warehouse.Zipcode])
      })

      const worksheet = XLSX.utils.aoa_to_sheet(xlsxContent)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1")
      XLSX.writeFile(workbook, "warehouses-to-review.xlsx");
    }

    const deleteWarehouse = async () => {
      data.deleteItemLoading = true;
      await store.dispatch('LoadsStore/deleteWarehouse', data.deleteItem.id).then(() => {
        getWarehouses();
        data.deleteItemLoading = false;
        data.deleteItem = null;
      });
    };
    const getWarehouses = () => {
      data.loadingWarehouses = true;
      store
        .dispatch('LoadsStore/getWarehousesList', {
          page: data.page,
          multisearch: data.query,
          sortBy: `${data.order.value}[${data.order.direction.toUpperCase()}]`,
          maxResults: 10
        })
        .then((response) => {
          if (data.page === 1) {
            data.warehouses = response.values;
          } else {
            data.warehouses = data.warehouses.concat(response.values);
          }
          data.totalWarehouses = response.total;
          data.loadingWarehouses = false;
        });
    };

    const getWarehousesToImport = (warehouses: any) => {
      data.showImportWarehouses = false
      let successToImport: Array<any> = []
      let errorToImport: Array<any> = []
      warehouses.forEach((warehouse: any) => {
        if (warehouse.validation.passed) {
          successToImport.push(warehouse)
        } else {
          errorToImport.push(warehouse)
        }
      })
      data.warehousesToImport = successToImport
      data.warehousesCannotBeImport = errorToImport
      data.viewMode = 'import'
    }

    const debouncedWarehouses = debounce(getWarehouses, 500);

    const getLocationOfWarehouse = (warehouse: any) => {
      let city = '';
      let country = '';
      if ('location' in warehouse) {
        city =
          warehouse.location.zip +
          ' ' +
          warehouse.location.city +
          ' ' +
          warehouse.location?.state?.iso;
        country = warehouse.location.state?.country?.iso;
      } else {
        city = warehouse.zipCode;
        country = '-';
      }

      return {
        city: city,
        country: country,
      };
    };

    const loadMoreWarehouses = () => {
      data.page++;
      getWarehouses();
    };

    const postCreateWarehouseFromJson = () => {
      data.loadingWarehousesFromJson = true

      store.dispatch('LoadsStore/postWarehousesFromJson', data.selectedWarehousesToImport)
        .then((response) => {
          data.loadingWarehousesFromJson = false
          store.dispatch('UserStore/showToast', t('warehouses-success-generate'))
          cleanImport()
        })
    }

    const showWarehouse = (warehouse: any) => {
      store.commit('LoadsStore/setSelectedWarehouse', warehouse);
      data.showUpdateWarehouse = true;
    };

    const warehouseHasProblems = (warehouse: any) => {
      let resultMessage = false;
      if (warehouse.location === undefined) resultMessage = true;
      return resultMessage;
    };

    getWarehouses();

    return {
      ...toRefs(data),
      headers,
      importHeaders,
      changeQuery,
      changeOrder,
      changeSelectedWarehousesToImport,
      cleanImport,
      closeAddAndUpdateWarehouse,
      closeDeleteModal,
      closeUpdateWarehouse,
      closeUpdatedWarehouse,
      createExcelFile,
      deleteWarehouse,
      getLocationOfWarehouse,
      getWarehousesToImport,
      loadMoreWarehouses,
      postCreateWarehouseFromJson,
      showWarehouse,
      warehouseHasProblems,
      t,
    };
  },
});
