import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_2 = { class: "flex justify-start items-start plan-gap-md" }
const _hoisted_3 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_4 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_5 = { class: "w-full flex justify-center items-end" }
const _hoisted_6 = { class: "w-full flex justify-start items-end plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_select = _resolveComponent("plan-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeCarrierType && _ctx.changeCarrierType(...args))),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.myDataForm.type) = $event)),
          value: "BROKER",
          type: "radio"
        }, null, 544), [
          [_vModelRadio, _ctx.myDataForm.type]
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('broker')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeCarrierType && _ctx.changeCarrierType(...args))),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.myDataForm.type) = $event)),
          value: "CARRIER",
          type: "radio"
        }, null, 544), [
          [_vModelRadio, _ctx.myDataForm.type]
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('carrier')), 1)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start':'justify-start items-end'])
    }, [
      _createVNode(_component_plan_input_text, {
        onChange: _ctx.updateMyDataInStore,
        "model-value": _ctx.myDataForm.firstName,
        "onUpdate:model-value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.myDataForm.firstName) = $event)),
        label: _ctx.$t('firstname'),
        mandatory: ""
      }, null, 8, ["onChange", "model-value", "label"]),
      _createVNode(_component_plan_input_text, {
        onChange: _ctx.updateMyDataInStore,
        "model-value": _ctx.myDataForm.lastName,
        "onUpdate:model-value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.myDataForm.lastName) = $event)),
        label: _ctx.$t('lastname'),
        mandatory: ""
      }, null, 8, ["onChange", "model-value", "label"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start':'justify-start items-end'])
    }, [
      _createVNode(_component_plan_input_text, {
        onChange: _ctx.updateMyDataInStore,
        "model-value": _ctx.myDataForm.email,
        "onUpdate:model-value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.myDataForm.email) = $event)),
        label: _ctx.$t('email'),
        mandatory: ""
      }, null, 8, ["onChange", "model-value", "label"]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_plan_select, {
          onChange: _ctx.changePrefix,
          value: _ctx.myDataForm.prefix,
          items: _ctx.prefixList,
          label: _ctx.$t('prefix'),
          placeholder: "+1",
          "hide-right": "",
          style: {"width":"75px"},
          mandatory: ""
        }, null, 8, ["onChange", "value", "items", "label"]),
        _createVNode(_component_plan_input_text, {
          onChange: _ctx.updateMyDataInStore,
          "model-value": _ctx.myDataForm.phone,
          "onUpdate:model-value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.myDataForm.phone) = $event)),
          label: _ctx.$t('phone'),
          mandatory: "",
          "hide-left": ""
        }, null, 8, ["onChange", "model-value", "label"])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start':'justify-start items-end'])
    }, [
      _createVNode(_component_plan_input_text, {
        onChange: _ctx.updateMyDataInStore,
        "model-value": _ctx.myDataForm.companyName,
        "onUpdate:model-value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.myDataForm.companyName) = $event)),
        label: _ctx.$t('label.company-name'),
        mandatory: ""
      }, null, 8, ["onChange", "model-value", "label"]),
      _createVNode(_component_plan_input_text, {
        onChange: _ctx.updateMyDataInStore,
        "model-value": _ctx.myDataForm.cif,
        "onUpdate:model-value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.myDataForm.cif) = $event)),
        label: _ctx.actualRegion === 'NORTH_AMERICA' ? _ctx.$t('fein') : _ctx.$t('cif'),
        mandatory: ""
      }, null, 8, ["onChange", "model-value", "label"])
    ], 2),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_plan_input_text, {
        onChange: _ctx.updateMyDataInStore,
        "model-value": _ctx.myDataForm.companyAddress,
        "onUpdate:model-value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.myDataForm.companyAddress) = $event)),
        label: _ctx.$t('label.company-address'),
        mandatory: ""
      }, null, 8, ["onChange", "model-value", "label"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["w-full flex plan-gap-sm", _ctx.actualWidth <= 450 ? 'flex-col justify-start items-start':'justify-start items-end'])
    }, [
      (_ctx.myDataForm.type === 'CARRIER')
        ? (_openBlock(), _createBlock(_component_plan_input_text, {
            key: 0,
            onChange: _ctx.updateMyDataInStore,
            "model-value": _ctx.myDataForm.dotNumber,
            "onUpdate:model-value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.myDataForm.dotNumber) = $event)),
            label: _ctx.$t('dot-number'),
            mandatory: ""
          }, null, 8, ["onChange", "model-value", "label"]))
        : _createCommentVNode("", true),
      _createVNode(_component_plan_input_text, {
        onChange: _ctx.updateMyDataInStore,
        "model-value": _ctx.myDataForm.mcNumber,
        "onUpdate:model-value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.myDataForm.mcNumber) = $event)),
        label: _ctx.$t('mc-number'),
        mandatory: ""
      }, null, 8, ["onChange", "model-value", "label"])
    ], 2)
  ]))
}