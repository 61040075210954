import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-center" }
const _hoisted_2 = {
  key: 0,
  class: "font-bold plan-t-h2 plan-t-prim-500"
}
const _hoisted_3 = {
  key: 1,
  class: "font-bold plan-t-h2 plan-t-prim-500"
}
const _hoisted_4 = { class: "font-bold plan-t-h2 plan-t-prim-200" }
const _hoisted_5 = { class: "flex justify-center items-center plan-gap-md font-semibold flex-wrap plan-t-prim-500" }
const _hoisted_6 = { class: "plan-t-prim-100" }
const _hoisted_7 = { class: "plan-t-prim-100" }
const _hoisted_8 = { class: "w-full flex justify-center items-center plan-gap-sm max-width-carrier-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_carrier_load_acceptation_steps = _resolveComponent("plan-carrier-load-acceptation-steps")!
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_carrier_load_acceptation_my_data_form = _resolveComponent("plan-carrier-load-acceptation-my-data-form")!
  const _component_plan_carrier_load_acceptation_carrier_package_form = _resolveComponent("plan-carrier-load-acceptation-carrier-package-form")!
  const _component_plan_carrier_load_acceptation_driver_and_vehicle_form = _resolveComponent("plan-carrier-load-acceptation-driver-and-vehicle-form")!
  const _component_plan_default_carrier_view = _resolveComponent("plan-default-carrier-view")!

  return (_ctx.loadData !== null)
    ? (_openBlock(), _createBlock(_component_plan_default_carrier_view, {
        key: 0,
        "show-header": false,
        "show-subheader": false,
        "show-footer": true
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex", _ctx.actualWidth <= 640 ? 'w-full justify-start items-start':'absolute top-[16px] left-[64px] plan-b-gray-50 rounded'])
            }, [
              (!_ctx.isAssignedLoad)
                ? (_openBlock(), _createBlock(_component_plan_button, {
                    key: 0,
                    onOnClick: _ctx.goToLoadDetails,
                    type: "cancel",
                    label: _ctx.$t('exit'),
                    "show-icon": "",
                    icon: "chevron-left",
                    "icon-color": "#8A8B97"
                  }, null, 8, ["onOnClick", "label"]))
                : (_openBlock(), _createBlock(_component_plan_button, {
                    key: 1,
                    onOnClick: _ctx.goToAssigned,
                    type: "cancel",
                    label: _ctx.$t('exit'),
                    "show-icon": "",
                    icon: "chevron-left",
                    "icon-color": "#8A8B97"
                  }, null, 8, ["onOnClick", "label"]))
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["w-full flex flex-col justify-start items-center plan-gap-lg max-width-carrier-content", {'plan-mt-sm': _ctx.actualWidth > 640}])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex plan-gap-sm justify-center items-center", {'flex-col': _ctx.actualWidth <= 640 }])
              }, [
                (_ctx.isAssignedLoad)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('update-data')), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('load-acceptation')), 1)),
                _createElementVNode("span", _hoisted_4, "#" + _toDisplayString(_ctx.loadData.identifier), 1)
              ], 2),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.headerData.pickup) + " ", 1),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.headerData.pickupDate), 1)
                ]),
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.headerData.delivery) + " ", 1),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.headerData.deliveryDate), 1)
                ])
              ]),
              _createVNode(_component_plan_carrier_load_acceptation_steps, {
                onGoToStep: _ctx.goToStep,
                activeStep: _ctx.activeStep
              }, null, 8, ["onGoToStep", "activeStep"]),
              (_ctx.loadingData)
                ? (_openBlock(), _createBlock(_component_plan_icon, {
                    key: 0,
                    name: "spinner",
                    "icon-color": "#8A8B97",
                    size: "40",
                    rotate: "",
                    "is-svg": ""
                  }))
                : _createCommentVNode("", true),
              (!_ctx.loadingData && _ctx.activeStep === 'myData')
                ? (_openBlock(), _createBlock(_component_plan_carrier_load_acceptation_my_data_form, { key: 1 }))
                : _createCommentVNode("", true),
              (!_ctx.loadingData && _ctx.activeStep === 'carrierPackage')
                ? (_openBlock(), _createBlock(_component_plan_carrier_load_acceptation_carrier_package_form, { key: 2 }))
                : _createCommentVNode("", true),
              (!_ctx.loadingData && _ctx.activeStep === 'driverAndVehicle')
                ? (_openBlock(), _createBlock(_component_plan_carrier_load_acceptation_driver_and_vehicle_form, { key: 3 }))
                : _createCommentVNode("", true)
            ], 2)
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            (!_ctx.isAssignedLoad && _ctx.activeStep === 'myData')
              ? (_openBlock(), _createBlock(_component_plan_button, {
                  key: 0,
                  onOnClick: _ctx.goToLoadDetails,
                  type: "cancel",
                  label: _ctx.$t('cancel'),
                  class: "w-full"
                }, null, 8, ["onOnClick", "label"]))
              : _createCommentVNode("", true),
            (_ctx.isAssignedLoad && _ctx.activeStep === 'myData')
              ? (_openBlock(), _createBlock(_component_plan_button, {
                  key: 1,
                  onOnClick: _ctx.goToAssigned,
                  type: "cancel",
                  label: _ctx.$t('cancel'),
                  class: "w-full"
                }, null, 8, ["onOnClick", "label"]))
              : _createCommentVNode("", true),
            (_ctx.activeStep !== 'myData')
              ? (_openBlock(), _createBlock(_component_plan_button, {
                  key: 2,
                  onOnClick: _ctx.previousStep,
                  disabled: _ctx.loadingData || _ctx.loadingCarrierPackage,
                  type: "primary-outline",
                  label: _ctx.$t('previous'),
                  class: "w-full"
                }, null, 8, ["onOnClick", "disabled", "label"]))
              : _createCommentVNode("", true),
            (_ctx.activeStep !== 'driverAndVehicle')
              ? (_openBlock(), _createBlock(_component_plan_button, {
                  key: 3,
                  onOnClick: _ctx.nextStep,
                  loading: _ctx.loadingMyData || _ctx.loadingCarrierPackage,
                  disabled: _ctx.loadingData,
                  type: "primary",
                  label: _ctx.$t('continue'),
                  class: "w-full"
                }, null, 8, ["onOnClick", "loading", "disabled", "label"]))
              : _createCommentVNode("", true),
            (!_ctx.isAssignedLoad && _ctx.activeStep === 'driverAndVehicle')
              ? (_openBlock(), _createBlock(_component_plan_button, {
                  key: 4,
                  onOnClick: _ctx.acceptService,
                  loading: _ctx.loadingAcceptService,
                  type: "success",
                  label: _ctx.$t('accept-service'),
                  class: "w-full"
                }, null, 8, ["onOnClick", "loading", "label"]))
              : _createCommentVNode("", true),
            (_ctx.isAssignedLoad && _ctx.activeStep === 'driverAndVehicle')
              ? (_openBlock(), _createBlock(_component_plan_button, {
                  key: 5,
                  onOnClick: _ctx.goToAssigned,
                  loading: _ctx.loadingAcceptService,
                  type: "primary",
                  label: _ctx.$t('continue'),
                  class: "w-full"
                }, null, 8, ["onOnClick", "loading", "label"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}