
import { defineComponent, computed, reactive, toRefs } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';
import PlanCarrierShareLoad from '@/components/carrier/PlanCarrierShareLoad.vue';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import CarrierResume from '@/modules/carrier/modals/CarrierResumen.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'PlanCarrierRfpQuoteHeader',
  components: { PlanButton, PlanButtonIcon },
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive({
      loadingActivitySummary: false,
      notifications: 0,
      showCarrierResume: false,
    });

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth'];
    });

    const bellButtonIconStyle = computed(() => {
      let buttonSize = '44px';

      if (actualWidth.value <= 640) buttonSize = '38px';

      return {
        'min-width': buttonSize,
        'min-height': buttonSize,
      };
    });

    const getCarrierResume = async () => {
      data.loadingActivitySummary = true;
      await store.dispatch('UserStore/getUserEmailNotificationSetup');
      await store
        .dispatch('ShipmentStore/getActivitySummary', 'QUOTES')
        .then((response) => {
          data.notifications = response.totals.total;
        })
        .finally(() => {
          data.loadingActivitySummary = false;
        });
    };

    const showNotifications = () => {
      store.commit('CarrierStore/setShowNotifications', true);
    };

    const showShareLoad = () => {
      store.commit('CarrierStore/setShowShareLoad', true);
    };

    getCarrierResume();

    return {
      ...toRefs(data),
      actualWidth,
      bellButtonIconStyle,
      showNotifications,
      showShareLoad,
    };
  },
});
