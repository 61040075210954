
import { computed, defineComponent, reactive, ref, toRefs, watch } from 'vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputTextArea from '@/components/forms/PlanTextArea.vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';

interface Stop {
  label: string;
  value: number;
}
interface planCarrierNotifyIncidence {
  loadingPost: boolean,
  showModal: boolean,
  incidence: string,
  selectedStop: {
    label: '',
    value: -1
  },
  incidenceId: number | null
}

export default defineComponent({
  name: 'PlanCarrierNotifyIncidence',
  components: { PlanTextButton, PlanInputFile, PlanInputTextArea, PlanButton, PlanCarrierDefaultModal, PlanButtonIcon },
  setup () {
    const store = useStore()
    const translate = useI18n()

    const files = ref<File[]>([]);

    const data = reactive({
      loadingPost: false,
      showModal: false,
      incidence: '',
      selectedStop: {
        label: '',
        value: -1
      },
      incidenceId: null
    })

    const changeFiles = (newFiles: File[]) => {
      files.value = newFiles
    }

    const close = () =>{
      if (!data.loadingPost) data.showModal = false
    }

    const openModal = () => {
      data.incidence = ''
      data.selectedStop = {
        label: '',
        value: -1
      }
      data.showModal = true
    }

    const postNotifyIncidence = async () => {
      data.loadingPost = true
      await store
        .dispatch('UserStore/postIncidence', {
          description: data.incidence,
          stop: { id: data.selectedStop.value }
        })
        .then((response) => {
          data.incidenceId = response.id
          store
            .dispatch('UserStore/updateToast', {
              text: translate.t('incidence-correctly-sent'),
              type: 'success',
              visible: true
            })
        })

      if (files.value.length > 0) {
        for (const file of files.value) {
          await postNotifyIncidenceFile(file)
        }
      }

      await store.dispatch('CarrierStore/getLoadInfoForCarrier', store.getters['CarrierStore/getLoadData'].id)
      data.loadingPost = false
      data.incidenceId = null
      close()
    }

    const postNotifyIncidenceFile = async (file: File) => {
      await store
        .dispatch('UserStore/postIncidenceFile', {
          entityId: data.incidenceId,
          file: file,
        })
    }

    const stops = computed(() => {
      let result: Array<any> = []

      store.getters['CarrierStore/getLoadData'].stops.forEach((stop: any) => {
        let label = ''
        if (stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP') label = `${translate.t('pick-up')} ${stop.sequence}`
        if (stop.type === 'DELIVERY_FINAL' || stop.type === 'DELIVERY') label = `${translate.t('delivery')} ${stop.sequence}`
        label += ' - '
        label += `${stop.location?.city}, ${stop.location?.state?.iso} ${stop.location?.state?.country?.iso}`

        result.push({
          label: label,
          value: stop.id
        })
      })

      return result
    })

    return {
      ...toRefs(data),
      changeFiles,
      close,
      openModal,
      postNotifyIncidence,
      stops
    }
  }
})
