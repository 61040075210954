import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-start plan-gap-md plan-pt-sm plan-pb-sm plan-pl-lg plan-pr-lg" }
const _hoisted_2 = { class: "w-full flex flex-col" }
const _hoisted_3 = { class: "plan-t-prim-200" }
const _hoisted_4 = { class: "w-full flex justify-start items-start plan-gap-xl" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = {
  class: "plant-t-prim-500 font-bold flex justify-start items-center plan-gap-xs",
  style: {"font-size":"36px"}
}
const _hoisted_7 = { class: "truncate" }
const _hoisted_8 = { class: "plan-t-prim-100 m-0" }
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = {
  class: "plant-t-prim-500 font-bold flex justify-start items-center plan-gap-xs",
  style: {"font-size":"36px"}
}
const _hoisted_11 = { class: "truncate" }
const _hoisted_12 = { class: "plan-t-prim-100 m-0" }
const _hoisted_13 = { class: "w-full flex justify-between items-center plan-gap-md" }
const _hoisted_14 = { class: "flex justify-start items-center plan-t-prim-200 plan-gap-md" }
const _hoisted_15 = { class: "flex justify-center items-center gap-1" }
const _hoisted_16 = {
  key: 0,
  class: "flex justify-center items-center gap-1"
}
const _hoisted_17 = {
  key: 1,
  class: "flex justify-center items-center gap-1"
}
const _hoisted_18 = { class: "truncate" }
const _hoisted_19 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_load_timeline = _resolveComponent("load-timeline")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_load_detail_map = _resolveComponent("load-detail-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, "#" + _toDisplayString(_ctx.loadData.identifier), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.loadData.initialPickUp.location.city), 1),
            _createElementVNode("span", null, ", " + _toDisplayString(_ctx.loadData.initialPickUp.location.state.iso), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.loadData.initialPickUp.location.state.country.iso), 1)
          ]),
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.moment(_ctx.loadData.initialPickUp.executionDate).format('MMM DD, YYYY')), 1)
        ]),
        _createVNode(_component_plan_icon, {
          name: "arrow-right",
          "is-svg": "",
          "icon-color": "#151830",
          size: "46"
        }),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.loadData.finalDelivery.location.city), 1),
            _createElementVNode("span", null, ", " + _toDisplayString(_ctx.loadData.finalDelivery.location.state.iso), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.loadData.finalDelivery.location.state.country.iso), 1)
          ]),
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.moment(_ctx.loadData.finalDelivery.executionDate).format('MMM DD, YYYY')), 1)
        ])
      ])
    ]),
    _createVNode(_component_load_timeline, {
      stops: _ctx.loadData.stops,
      detail: ""
    }, null, 8, ["stops"]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_plan_icon, {
            name: "distance",
            "is-svg": "",
            size: "16",
            "icon-color": "#4F5264"
          }),
          _createElementVNode("span", null, _toDisplayString(_ctx.loadData.distance), 1)
        ]),
        (_ctx.loadData?.vehicle?.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createVNode(_component_plan_icon, {
                name: "icInRoute",
                "is-svg": "",
                size: "16",
                "icon-color": "#4F5264"
              }),
              _createElementVNode("p", null, _toDisplayString(_ctx.loadData.vehicle), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.loadData.goodsType.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createVNode(_component_plan_icon, {
                name: "package",
                "is-svg": "",
                size: "16",
                "icon-color": "#4F5264"
              }),
              _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.loadData.goodsType), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_plan_button, {
        onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMap = !_ctx.showMap)),
        type: "primary",
        label: _ctx.showMap ? 'Close map' : 'View map',
        "show-icon": "",
        icon: _ctx.showMap ? 'close':'world',
        "icon-type": "svg",
        style: {"width":"150px"}
      }, null, 8, ["label", "icon"])
    ]),
    _createElementVNode("div", _hoisted_19, [
      (_ctx.showMap)
        ? (_openBlock(), _createBlock(_component_load_detail_map, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ]))
}