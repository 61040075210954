<template>
  <div class="w-full flex flex-col plan-gap-md">
    <div class="flex justify-between items-center plan-b-sec-50 border plan-border-sec-200 plan-rounded-xs plan-p-md">
      <div class="flex justify-start items-center plan-gap-md plan-mr-2xl">
        <plan-icon name="bell" is-svg icon-color="#0097FF" size="30" />
        <p class="font-bold" style="font-size: 20px;">x contacts in your address book may be able to help you move this load</p>
      </div>

      <plan-button type="primary" label="New contact" />
    </div>

    <div class="flex justify-between items-start">
      <div class="flex justify-start items-center plan-gap-md">
        <div class="flex justify-start items-center plan-gap-xs">
          <input type="checkbox" class="plan-form">
          <span>Brokers</span>
        </div>
        <div class="flex justify-start items-center plan-gap-xs">
          <input type="checkbox" class="plan-form">
          <span>Carriers</span>
        </div>
        <div class="flex justify-start items-center plan-gap-xs">
          <input type="checkbox" class="plan-form">
          <span>Trayler type: Van48'</span>
        </div>
      </div>

      <plan-select-simple :value="selectedRange" @change="changeSelectedRange"
                          label="Historical range" placeholder="Select range"
                          :items="[
                            { label: 'Last month', value: 'month' },
                            { label: 'Last 3 months', value: '3months' },
                            { label: 'Last 6 months', value: '6months' },
                            { label: 'Last 9 months', value: '9months' },
                            { label: 'Last year', value: 'year' },
                          ]" />
    </div>

    <plan-table @changeOrder="changeOrder" class="plan-mt-md"
                :headers="headers" :order-by="orderBy">
      <template v-slot:table-content>
        <tr v-for="item in items" :key="item">
          <td>
            <div class="flex justify-center items-center" style="min-width: 200px">
              <plan-avatar :user-name="item.user" :description="item.company" />
              <plan-button-icon icon="chat" />
            </div>
          </td>
          <td>{{ item.rating }}</td>
          <td>-</td>
          <td>-</td>
          <td>
            <plan-button type="error" label="Direct assigment" />
          </td>
        </tr>
      </template>
    </plan-table>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import PlanTable from '@/components/tables/PlanTable.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';

export default defineComponent({
  name: 'LoadDetailQuoteDirectlyAssign',
  components: { PlanAvatar, PlanButtonIcon, PlanTable, PlanSelectSimple, PlanButton, PlanIcon },
  setup () {
    const data = reactive({
      headers: [
        { value: 'carrier', label: 'Carrrier/Brooker', align: 'left', showOrder: true },
        { value: 'rating', label: 'Rating', align: 'left', showOrder: true },
        { value: 'completedLoads', label: 'Completed loads', align: 'left', showOrder: true },
        { value: 'submittedBids', label: 'Submitted bids', align: 'left', showOrder: true },
        { label: '', align: 'left', showOrder: false }
      ],
      items: [
        { user: 'User name', company: 'Company name', rating: 5 },
        { user: 'User name', company: 'Company name', rating: 5 },
        { user: 'User name', company: 'Company name', rating: 5 },
      ],
      orderBy: 'carrier',
      selectedRange: 'month'
    })

    const changeOrder = (orderByData) => {
      data.orderBy = orderByData.value
    }

    const changeSelectedRange = (newValue) => {
      data.selectedRange = newValue
    }

    return {
      ...toRefs(data),
      changeOrder,
      changeSelectedRange
    }
  }
})
</script>

<style scoped>

</style>