
import { defineComponent, ref } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  name: 'LoadDetailNewTemplateModal',
  components: { PlanButton, PlanDefaultModal },
  emits: ['close'],
  setup (props, { emit }) {
    const templateName = ref('')
    const close = () => { emit('close') }

    return {
      templateName,
      close
    }
  }
})
