import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full plan-gap-sm" }
const _hoisted_2 = { class: "plan-text-lg font-semibold" }
const _hoisted_3 = { class: "plan-text-lg font-semibold plan-mt-lg" }
const _hoisted_4 = { class: "flex justify-center items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_input_location = _resolveComponent("plan-input-location")!
  const _component_plan_select = _resolveComponent("plan-select")!
  const _component_plan_input_number = _resolveComponent("plan-input-number")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('warehouse-form.name-and-address')), 1),
    _createVNode(_component_plan_input_text, {
      "model-value": _ctx.name,
      "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
      onChange: _ctx.update,
      label: _ctx.$t('warehouse-form.name'),
      placeholder: _ctx.$t('warehouse-form.name-placeholder'),
      mandatory: "",
      validations: ['empty'],
      requirements: ['greaterThan0'],
      "validate-from-start": _ctx.isUpdate
    }, null, 8, ["model-value", "onChange", "label", "placeholder", "validate-from-start"]),
    _createVNode(_component_plan_input_location, {
      "pre-country": _ctx.country,
      "pre-location": _ctx.zip,
      type: "warehouse",
      onChange: _ctx.updateInputLocation,
      "show-check": "",
      mandatory: ""
    }, null, 8, ["pre-country", "pre-location", "onChange"]),
    _createVNode(_component_plan_input_text, {
      "model-value": _ctx.address,
      "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.address) = $event)),
      onChange: _ctx.update,
      label: _ctx.$t('warehouse-form.address'),
      placeholder: _ctx.$t('warehouse-form.address-placeholder'),
      validations: ['empty'],
      requirements: ['greaterThan0'],
      mandatory: "",
      "validate-from-start": _ctx.isUpdate
    }, null, 8, ["model-value", "onChange", "label", "placeholder", "validate-from-start"]),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('warehouse-form.contact-information')), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_plan_select, {
        onChange: _ctx.changePrefix,
        value: _ctx.prefix,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.prefix) = $event)),
        items: _ctx.prefixes,
        label: _ctx.$t('warehouse-form.prefix'),
        placeholder: "+1",
        "hide-right": "",
        "right-line": "",
        style: {"width":"110px"}
      }, null, 8, ["onChange", "value", "items", "label"]),
      _createVNode(_component_plan_input_number, {
        "model-value": _ctx.phone,
        "onUpdate:model-value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phone) = $event)),
        onChange: _ctx.update,
        label: _ctx.$t('warehouse-form.phone'),
        placeholder: "000 000 0000",
        validations: ['greaterThan9Digits'],
        "hide-left": ""
      }, null, 8, ["model-value", "onChange", "label"])
    ]),
    _createVNode(_component_plan_input_text, {
      "model-value": _ctx.contactPerson,
      "onUpdate:model-value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.contactPerson) = $event)),
      onChange: _ctx.update,
      label: _ctx.$t('warehouse-form.contact-person'),
      placeholder: _ctx.$t('warehouse-form.contact-person-placeholder')
    }, null, 8, ["model-value", "onChange", "label", "placeholder"])
  ]))
}