
import { defineComponent, computed, reactive, toRefs } from 'vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import { useStore } from 'vuex';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanInputCalendar from '@/components/forms/PlanInputCalendar.vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanCarrierAddDriverModal from '@/components/carrier/_modals/PlanCarrierAddDriverModal.vue';
import PlanThumbnail from '@/components/forms/PlanThumbnail.vue';
import PlanCarrierAddVehicleModal from '@/components/carrier/_modals/PlanCarrierAddVehicleModal.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanDefaultDeleteModal from '@/components/defaults/PlanDefaultDeleteModal.vue';
import PlanCarrierUpdateVehicleModal from '@/components/carrier/_modals/PlanCarrierUpdateVehicleModal.vue';
import { useI18n } from 'vue-i18n';
import PlanCarrierUpdateDriverModal from '@/components/carrier/_modals/PlanCarrierUpdateDriverModal.vue';
import PlanCarrierBrokerAddVehicleModal from '@/components/carrier/_modals/PlanCarrierBrokerAddVehicleModal.vue';
import PlanCarrierBrokerUpdateVehicleModal from '@/components/carrier/_modals/PlanCarrierBrokerUpdateVehicleModal.vue';
import PlanCarrierBrokerAddDriverModal from '@/components/carrier/_modals/PlanCarrierBrokerAddDriverModal.vue';
import PlanCarrierBrokerUpdateDriverModal from '@/components/carrier/_modals/PlanCarrierBrokerUpdateDriverModal.vue';
export default defineComponent({
  name: 'PlanCarrierLoadAcceptationDriverAndVehicleForm',
  components: { PlanCarrierBrokerUpdateDriverModal, PlanCarrierBrokerAddDriverModal, PlanCarrierBrokerUpdateVehicleModal, PlanCarrierBrokerAddVehicleModal, PlanCarrierUpdateDriverModal, PlanCarrierUpdateVehicleModal, PlanDefaultDeleteModal, PlanButtonIcon, PlanCarrierAddVehicleModal, PlanThumbnail, PlanCarrierAddDriverModal },
  setup () {
    const store = useStore()
    const translate = useI18n()

    const data = reactive({
      deleteId: null,
      deleteDriverId: null,
      loadingDelete: false,
      loadingDriverDelete: false,
      selectedDriver: null,
      selectedVehicle: null
    })

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const changeDriver = async () => {
      await store.dispatch('ShipmentStore/assignDriver', {
        id: store.getters['CarrierStore/getLoadData'].shipment.id,
        driver: { id: data.selectedDriver }
      }).then(() => {
        store
          .dispatch('UserStore/updateToast', {
            text: translate.t('success-carrier-driver-selected'),
            type: 'success',
            visible: true
          })
      })
    }

    const changeVehicle = async () => {
      await store.dispatch('ShipmentStore/assignVehicle', {
        id: store.getters['CarrierStore/getLoadData'].shipment.id,
        vehicle: { id: data.selectedVehicle }
      }).then(() => {
        store
          .dispatch('UserStore/updateToast', {
            text: translate.t('success-carrier-vehicle-selected'),
            type: 'success',
            visible: true
          })
      })
    }

    const deleteDriver = async () => {
      data.loadingDriverDelete = true
      await store.dispatch('CompanyStore/deleteDriver', data.deleteDriverId)
        .then(() => {
          if (data.deleteDriverId === data.selectedDriver) data.selectedDriver = null
          data.deleteDriverId = null
          store
            .dispatch('UserStore/updateToast', {
              text: translate.t('general-success-delete'),
              type: 'success',
              visible: true
            })
          getDrivers()
        })
    }

    const deleteVehicle = async () => {
      data.loadingDelete = true
      await store.dispatch('CompanyStore/deleteVehicles', { id: data.deleteId })
        .then(() => {
          if (data.deleteId === data.selectedVehicle) data.selectedVehicle = null
          data.deleteId = null
          store
            .dispatch('UserStore/updateToast', {
              text: translate.t('general-success-delete'),
              type: 'success',
              visible: true
            })
          getVehicles()
        })
    }

    const drivers = computed(() => {
      return store.getters['CarrierStore/getDrivers']
    })

    const getDrivers = async () => {
      await store.dispatch('CompanyStore/driversList', store.getters['UserStore/getCurrentUser'].company.id)
        .then((response) => {
          store.commit('CarrierStore/setDrivers', response)
          data.loadingDriverDelete = false
        })
    }

    const getVehicles = async () => {
      await store.dispatch('CompanyStore/vehiclesList', store.getters['UserStore/getCurrentUser'].company.id)
        .then((response) => {
          store.commit('CarrierStore/setVehicles', response)
        }).finally(() => {
          data.deleteId = null
          data.loadingDelete = false
        })
    }

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData']
    })

    const myDataForm = computed(() => {
      return store.getters['CarrierStore/getMyDataForm']
    })

    const selectVehicle = (vehicleId: any) => {
      data.selectedVehicle = vehicleId
    }

    const vehicles = computed(() => {
      return store.getters['CarrierStore/getVehicles']
    })

    return {
      ...toRefs(data),
      actualWidth,
      changeDriver,
      changeVehicle,
      deleteDriver,
      deleteVehicle,
      drivers,
      loadData,
      myDataForm,
      selectVehicle,
      vehicles
    }
  }
})
