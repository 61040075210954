import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full h-full flex justify-center items-center"
}
const _hoisted_2 = { class: "flex justify-center items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_loading = _resolveComponent("plan-loading")!
  const _component_ContactForm = _resolveComponent("ContactForm")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    onClose: _ctx.close,
    title: _ctx.$t('update-contact'),
    size: "sm-auto",
    width: "1600px",
    "without-sidebar": _ctx.withoutSidebar,
    "hide-close-button": ""
  }, {
    content: _withCtx(() => [
      (!_ctx.isLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_plan_loading)
          ]))
        : (_openBlock(), _createBlock(_component_ContactForm, {
            key: 1,
            "is-update": "",
            contactInfo: _ctx.contactInfo,
            lanes: _ctx.lanes,
            currentView: _ctx.currentView,
            specialPermits: _ctx.specialPermits,
            onUpdate: _ctx.updateData,
            onUpdateLanes: _ctx.updateLanes,
            onUpdatePermits: _ctx.updatePermits,
            onUpdateInsurances: _ctx.updateInsurances,
            onEmitUpdate: _ctx.submit,
            onUpdateCurrentView: _ctx.updateCurrentView
          }, null, 8, ["contactInfo", "lanes", "currentView", "specialPermits", "onUpdate", "onUpdateLanes", "onUpdatePermits", "onUpdateInsurances", "onEmitUpdate", "onUpdateCurrentView"]))
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.close,
          disabled: _ctx.loading,
          type: "primary-transparent",
          label: _ctx.$t('cancel')
        }, null, 8, ["onOnClick", "disabled", "label"]),
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.submit,
          loading: _ctx.loading,
          disabled: !_ctx.isFormComplete || _ctx.loading,
          type: "primary",
          label: _ctx.$t('update')
        }, null, 8, ["onOnClick", "loading", "disabled", "label"])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title", "without-sidebar"]))
}