
import { defineComponent } from 'vue';
import PlanDefaultWindows from '@/components/defaults/PlanDefaultWindows.vue';
import LoadForm from '@/components/loads/form/LoadForm.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import LoadBillingData from '@/components/loads/detail/LoadBillingData.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';

export default defineComponent({
  name: 'LoadModifyModal',
  components: { PlanButtonIcon, PlanIcon, LoadForm, PlanDefaultWindows },
  emits: ['close'],
  setup (props, { emit }) {
    const close = () => { emit('close') }

    return {
      close
    }
  }
})
