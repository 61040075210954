import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 4,
  class: "italic plan-t-prim-200"
}
const _hoisted_2 = {
  key: 5,
  class: "flex justify-center items-center plan-gap-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_load_detail_header = _resolveComponent("load-detail-header")!
  const _component_load_modify_modal = _resolveComponent("load-modify-modal")!
  const _component_load_detail_new_template_modal = _resolveComponent("load-detail-new-template-modal")!
  const _component_plan_default_confirmation_modal = _resolveComponent("plan-default-confirmation-modal")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_load_detail_quote = _resolveComponent("load-detail-quote")!
  const _component_load_detail_accepting = _resolveComponent("load-detail-accepting")!
  const _component_load_detail_tracking = _resolveComponent("load-detail-tracking")!
  const _component_load_detail_delivered = _resolveComponent("load-detail-delivered")!
  const _component_load_detail_right = _resolveComponent("load-detail-right")!
  const _component_plan_default_windows = _resolveComponent("plan-default-windows")!

  return (_openBlock(), _createBlock(_component_plan_default_windows, {
    onChangeTab: _ctx.changeTab,
    onClose: _ctx.close,
    tabs: _ctx.tabsToShow,
    loading: _ctx.loadingLoad
  }, {
    header: _withCtx(() => [
      (_ctx.load !== null)
        ? (_openBlock(), _createBlock(_component_load_detail_header, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    nav: _withCtx(() => [
      (_ctx.showForm)
        ? (_openBlock(), _createBlock(_component_load_modify_modal, {
            key: 0,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showForm = false))
          }))
        : _createCommentVNode("", true),
      (_ctx.showModals.newTemplate)
        ? (_openBlock(), _createBlock(_component_load_detail_new_template_modal, {
            key: 1,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModals.newTemplate = false))
          }))
        : _createCommentVNode("", true),
      (_ctx.showModals.cancel)
        ? (_openBlock(), _createBlock(_component_plan_default_confirmation_modal, {
            key: 2,
            onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModals.cancel = false)),
            title: "Cancel load",
            message: "Are you sure you want to cancel this load?"
          }))
        : _createCommentVNode("", true),
      (_ctx.showModals.delete)
        ? (_openBlock(), _createBlock(_component_plan_default_confirmation_modal, {
            key: 3,
            onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModals.delete = false)),
            title: "Delete load",
            message: "Are you sure you want to delete this load?"
          }))
        : _createCommentVNode("", true),
      (_ctx.loadingLoad)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Loading load..."))
        : _createCommentVNode("", true),
      (!_ctx.loadingLoad && !_ctx.showForm)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_plan_button, {
              onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModals.newTemplate = true)),
              type: "primary-dark",
              label: "Create template",
              "show-icon": "",
              icon: "add-document",
              "icon-type": "svg",
              "icon-color": "white"
            }),
            _createVNode(_component_plan_button, {
              onOnClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showForm = true)),
              type: "primary-dark",
              label: "Modify",
              "show-icon": "",
              icon: "modify",
              "icon-type": "svg",
              "icon-color": "white"
            }),
            _createVNode(_component_plan_button, {
              onOnClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showModals.cancel = true)),
              type: "primary-dark",
              label: "Cancel",
              "show-icon": "",
              icon: "cancel-box",
              "icon-type": "svg",
              "icon-color": "white"
            }),
            _createVNode(_component_plan_button, {
              onOnClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showModals.delete = true)),
              type: "primary-dark",
              label: "Delete",
              "show-icon": "",
              icon: "trash",
              "icon-type": "svg",
              "icon-color": "white"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    "content-left": _withCtx(() => [
      (_ctx.selectedTab === 0 && _ctx.load.status !== 'CANCELED' && !_ctx.showForm)
        ? (_openBlock(), _createBlock(_component_load_detail_quote, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.selectedTab === 1 && _ctx.load.status !== 'CANCELED' && !_ctx.showForm)
        ? (_openBlock(), _createBlock(_component_load_detail_accepting, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.selectedTab === 2 && _ctx.load.status !== 'CANCELED' && !_ctx.showForm)
        ? (_openBlock(), _createBlock(_component_load_detail_tracking, { key: 2 }))
        : _createCommentVNode("", true),
      (_ctx.selectedTab === 3 && _ctx.load.status !== 'CANCELED' && !_ctx.showForm)
        ? (_openBlock(), _createBlock(_component_load_detail_delivered, { key: 3 }))
        : _createCommentVNode("", true)
    ]),
    "content-right": _withCtx(() => [
      (_ctx.load.status !== 'CANCELED' && !_ctx.showForm)
        ? (_openBlock(), _createBlock(_component_load_detail_right, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onChangeTab", "onClose", "tabs", "loading"]))
}