
import { defineComponent, onMounted, ref, watch } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import BaseModal from '@/components/modals/BaseModal.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import PhoneNumberSelector from '@/components/forms/PhoneNumberSelector.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

interface Contact {
  type: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  prefix: string;
  phone: number;
  mcNumber: string;
  identificationNumber: string;
  dotNumber: string;
  validation: {
    passed: boolean;
    message: string;
  };
}
export default defineComponent({
  name: 'WelcomeTrialModal',
  components: {
    BaseModal,
    ErrorMessage,
    Field,
    Form,
    PhoneNumberSelector,
    PlanButton,
  },
  props: {
    modalState: Boolean,
    contactToEdit: {
      type: Object as () => Contact,
      required: true,
    },
  },
  mixins: [BaseComponent],
  emits: ['close', 'updateContact'],
  setup(props, { emit }) {
    /** Global */
    const modalIsOpen = ref(props.modalState);
    const localContact = ref(props.contactToEdit);
    const form = ref(null);
    const loading = ref(false);

    const saveContactChanges = async () => {
      const validation = await (form as any).value.validate();
      if (!validation.valid) {
        return;
      }

      loading.value = true;
      emit('updateContact', localContact.value);

      setTimeout(() => {
        loading.value = false;
        emit('close');
      }, 2000);
    };

    onMounted(async () => {
      setTimeout(() => {
        (form as any).value.validate();
      }, 1000);
    });

    const onClickClose = () => {
      emit('close');
    };

    const changePhone = (type, phone) => {
      localContact.value.phone = phone.number;
      localContact.value.prefix = phone.prefix;
    };

    return {
      form,
      localContact,
      loading,
      changePhone,
      modalIsOpen,
      onClickClose,
      saveContactChanges,
    };
  },
});
