
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import ContactForm from '@/modules/planning/contacts/_forms/ContactForm.vue';
import PlanLoading from '@/components/loadings/PlanLoading.vue';

// Utils
import { formatDateStringReverse } from '@/utils/formatDates';
// Base
import BaseComponent from '@/base/BaseComponent';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

interface UpdateContactModal {
  vehiclesArray: Array<any>;
  loading: boolean;
  isLoaded: boolean;
  updateEmited: boolean;
  currentView: string;
  insurances: object;
  contactInfo: {
    name: string;
    surname: string;
    company: string;
    email: string;
    dotNumber: string;
    cif: string;
    address: string;
    mcNumber: string;
    phone: string;
    prefix: string;
    userType: string;
  };
  company: {
    identificationNumber: string;
  };
  lanes: Array<any>;
  specialPermits: {
    hasTransportAndDeliveryOfAlcoholicBeveragesPermit: boolean;
    hasHazardousMaterialPermit: boolean;
    hasTWICCard: boolean;
    hasTSA: boolean;
    hasOverweightLoadPermit: boolean;
    hasTeamDriverPermit: boolean;
    hasMoveFullTruckPermit: boolean;
    hasMovePartialPermit: boolean;
    hasOversizeLoadPermit: boolean;
    hasBondPermit: boolean;
    hasTirPermit: boolean;
  };
}

export default defineComponent({
  name: 'UpdateContactModal',
  components: { PlanDefaultModal, ContactForm, PlanButton, PlanLoading },
  emits: ['close', 'closeAndUpdate'],
  mixins: [BaseComponent],
  props: {
    selectedUser: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const store = useStore();
    const translate = useI18n();

    const data = reactive<UpdateContactModal>({
      vehiclesArray: [],
      isLoaded: false,
      currentView: 'user',
      updateEmited: false,
      insurances: {},
      loading: false,
      lanes: [],
      company: {
        identificationNumber: '',
      },
      contactInfo: {
        name: '',
        surname: '',
        company: '',
        email: '',
        mcNumber: '',
        dotNumber: '',
        cif: '',
        address: '',
        phone: '',
        prefix: '+1',
        userType: '',
      },
      specialPermits: {
        hasTransportAndDeliveryOfAlcoholicBeveragesPermit: false,
        hasHazardousMaterialPermit: false,
        hasTWICCard: false,
        hasTSA: false,
        hasOverweightLoadPermit: false,
        hasTeamDriverPermit: false,
        hasBondPermit: false,
        hasTirPermit: false,
        hasMoveFullTruckPermit: false,
        hasMovePartialPermit: false,
        hasOversizeLoadPermit: false,
      },
    });

    store
      .dispatch('CompanyStore/getCompanyById', props.selectedUser.company.id)
      .then((response) => {
        data.contactInfo.company = response?.name;
        data.contactInfo.address = response?.location?.address;
        data.isLoaded = true;
      });

    const currentCompany = computed(() => {
      return store.getters['CompanyStore/getCompanyById'];
    });

    const vehicles = computed(() => {
      return store.getters['CompanyStore/getVehiclesList'];
    });

    watch(
      () => props.selectedUser,
      (newUser) => {
        if (newUser) {
          data.contactInfo.name = newUser.name;
          data.contactInfo.surname = newUser.surname;
          data.contactInfo.email = newUser.email;
          data.contactInfo.mcNumber = newUser.company.mcNumber;
          data.contactInfo.dotNumber = newUser.company.dotNumber;
          data.contactInfo.cif = newUser.company.identificationNumber;
          data.contactInfo.prefix = newUser?.phonePrefix || '+1';
          data.contactInfo.phone = newUser.phone;
          store.dispatch('CompanyStore/driversList', newUser?.company?.id);
          store.dispatch('CompanyStore/vehiclesList', newUser?.company?.id);
          data.vehiclesArray = vehicles.value;
        }
      },
      { deep: true, immediate: true },
    );

    watch(
      () => currentCompany.value,
      (company) => {
        if (Object.keys(company).length !== 0) {
          data.contactInfo.userType = company.type;
          data.specialPermits.hasTransportAndDeliveryOfAlcoholicBeveragesPermit =
            company.hasTransportAndDeliveryOfAlcoholicBeveragesPermit;
          data.specialPermits.hasHazardousMaterialPermit = company?.hasHazardousMaterialPermit;
          data.specialPermits.hasTWICCard = company?.hasTWICCard;
          data.specialPermits.hasTSA = company?.hasTSA;

          data.specialPermits.hasOversizeLoadPermit = company?.hasOversizeLoadPermit;
          data.specialPermits.hasMovePartialPermit = company?.hasMovePartialPermit;
          data.specialPermits.hasMoveFullTruckPermit = company?.hasMoveFullTruckPermit;

          data.specialPermits.hasOverweightLoadPermit = company?.hasOverweightLoadPermit;
          data.specialPermits.hasTeamDriverPermit = company?.hasTeamDriverPermit;
          data.specialPermits.hasBondPermit = company?.hasBondPermit;
          data.specialPermits.hasTirPermit = company?.hasTirPermit;
          data.contactInfo.address = company?.location?.address;
        }
      },
      { deep: true, immediate: true },
    );

    const updatePermits = (updatedPermits) => {

      data.updateEmited = true;
      data.specialPermits = updatedPermits;
    };

    const updateData = (updatedContactInfo) => {
      data.updateEmited = true;
      data.contactInfo = updatedContactInfo;
    };

    const updateLanes = (lanes) => {
      data.updateEmited = true;
      data.lanes = lanes;
    };

    const updateInsurances = (insurances) => {
      data.updateEmited = true;
      data.insurances = insurances;
    };

    const updateCurrentView = (newView) => {
      data.updateEmited = false;
      data.currentView = newView;
    };

    const isFormComplete = computed(() => {
      let result = true;
      if (data.updateEmited === false) result = false;
      return result;
    });

    const withoutSidebar = computed(() => {
      return route.name !== 'warehousesList';
    });

    const close = () => {
      if (!data.loading) emit('close');
    };

    const updateUser = async () => {
      if (!isFormComplete.value) return;
      data.loading = true;

      if (
        props.selectedUser.company?.type !== data.contactInfo.userType &&
        data.contactInfo.userType !== ''
      ) {
        store.dispatch('CompanyStore/putCompanyUpdateType', {
          id: props.selectedUser?.company?.id,
          type: {
            type: data.contactInfo.userType,
          },
        });
      }

      store
        .dispatch('CompanyStore/putCompany', {
          id: props.selectedUser?.company?.id,
          company: {
            name: data.contactInfo.company,
            mcNumber: data.contactInfo.mcNumber,
            dotNumber: data.contactInfo.dotNumber,
            identificationNumber: data.contactInfo.cif,
            hasBondPermit: data.specialPermits.hasBondPermit,
            hasHazardousMaterialPermit: data.specialPermits.hasHazardousMaterialPermit,
            hasOverweightLoadPermit: data.specialPermits.hasOverweightLoadPermit,

            hasOversizeLoadPermit: data.specialPermits.hasOversizeLoadPermit,
            hasMovePartialPermit: data.specialPermits.hasMovePartialPermit,
            hasMoveFullTruckPermit: data.specialPermits.hasMoveFullTruckPermit,

            hasTSA: data.specialPermits.hasTSA,
            hasTWICCard: data.specialPermits.hasTWICCard,
            hasTeamDriverPermit: data.specialPermits.hasTeamDriverPermit,
            hasTirPermit: data.specialPermits.hasTirPermit,
            hasTransportAndDeliveryOfAlcoholicBeveragesPermit:
              data.specialPermits.hasTransportAndDeliveryOfAlcoholicBeveragesPermit,
            location: {
              address: data.contactInfo.address,
            },
          },
        })
        .then(() => {
          store.dispatch('CompanyStore/getCompanyById', props.selectedUser?.company?.id);
          BaseComponent.methods?.showToastSuccess(translate.t('information-updated'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
      await store
        .dispatch('UserStore/putUser', {
          id: props.selectedUser.id,
          user: {
            email: data.contactInfo.email,
            name: data.contactInfo.name,
            phone: `${data.contactInfo.phone}`,
            phonePrefix: data.contactInfo.prefix,
            surname: data.contactInfo.surname,
          },
        })
        .then(() => {
          data.loading = false;
          BaseComponent.methods?.showToastSuccess(translate.t('information-updated'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    };

    const createLanes = () => {
      data.loading = true;
      data.lanes.forEach((lane) => {
        lane.radiusDistance = +lane.radiusDistance;
        store
          .dispatch('LoadsStore/postLane', {
            ...lane,
            company: { id: currentCompany.value.id },
          })
          .then(() => {
            data.loading = false;
            store.dispatch('CompanyStore/getCompanyById', currentCompany.value.id);
            store.dispatch('LoadsStore/getLanes', { id: currentCompany.value.id });
            BaseComponent.methods?.showToastSuccess(translate.t('updated Correctly'));
          });
      });
      data.lanes = [];
    };

    const submit = () => {
      if (!isFormComplete.value) return;
      if (data.currentView === 'user') {
        updateUser();
      }

      if (data.currentView === 'lanes') {
        createLanes();
      }
      if (data.currentView === 'insurance') {
        createInsurances(data.insurances);
      }
    };

    const createInsurances = async (insurances) => {
      const { getCargoInsurance, liabilityInsurance, getW9Media, DOTMCMedia } = insurances;

      data.loading = true;

      if (getCargoInsurance.isCreated && currentCompany?.value.type === 'CARRIER') {
        await putInsurance('CARGO', insurances);
      } else if (currentCompany?.value.type === 'CARRIER') {
        await postInsurance('CARGO', insurances);
      }

      if (liabilityInsurance.isCreated) {
        await putInsurance('LIABILITY', insurances);
      } else {
        await postInsurance('LIABILITY', insurances);
      }

      await store.dispatch('CompanyStore/getCompanyById', props.selectedUser.company.id);

      if (currentCompany?.value.type === 'CARRIER') {
        if (getCargoInsurance.file !== getCargoInsurance.InsuranceFile) {
          if (getCargoInsurance !== undefined) {
            if (getCargoInsurance?.isCreated?.mediaObject) {
              await postUpdateMediaObjectInsurance('CARGO', insurances);
            } else {
              await postMediaObjectInsurance('CARGO', insurances);
            }
          } else {
            await postMediaObjectInsurance('CARGO', insurances);
          }
        }
      }

      if (liabilityInsurance.file !== liabilityInsurance.InsuranceFile) {
        if (liabilityInsurance.isCreated !== undefined) {
          if (liabilityInsurance?.isCreated?.mediaObject) {
            await postUpdateMediaObjectInsurance('LIABILITY', insurances);
          } else {
            await postMediaObjectInsurance('LIABILITY', insurances);
          }
        } else {
          await postMediaObjectInsurance('LIABILITY', insurances);
        }
      }

      if (getW9Media.file !== getW9Media.InsuranceFile) {
        if (getW9Media !== undefined) {
          if (getW9Media.InsuranceFile instanceof File) {
            await postUpdateMediaObjectCompany('W9', insurances);
          } else {
            await postMediaObjectCompany('W9', insurances);
          }
        } else {
          await postMediaObjectCompany('W9', insurances);
        }
      }

      if (DOTMCMedia.file !== DOTMCMedia.InsuranceFile) {
        if (DOTMCMedia !== undefined) {
          if (DOTMCMedia.InsuranceFile instanceof File) {
            await postUpdateMediaObjectCompany('DOT_MC_NUMBER', insurances);
          } else {
            await postMediaObjectCompany('DOT_MC_NUMBER', insurances);
          }
        } else {
          await postMediaObjectCompany('DOT_MC_NUMBER', insurances);
        }
      }

      data.loading = false;

      store.dispatch('CompanyStore/getCompanyById', currentCompany?.value.id).then(() => {
        data.updateEmited = false;
        store.commit('CompanyStore/updatingInsurances', true);
      });

      BaseComponent.methods?.showToastSuccess(translate.t('updated-correctly'));
    };

    const putInsurance = async (type: string, insurances: any) => {
      const { getCargoInsurance, liabilityInsurance } = insurances;
      const insurancePut = type === 'CARGO' ? getCargoInsurance : liabilityInsurance;
      const findInsurance = currentCompany?.value.insurances.find((obj) => obj.type === type);

      const regex = /\//g;
      let matches = insurancePut.date.toString().match(regex);
      let insuranceExpiration =
        matches !== null ? insurancePut.date.toString().replace(/\//g, '-') : insurancePut.date;

      await store
        .dispatch('ShipmentStore/putInsurances', {
          id: findInsurance.id,
          body: {
            number: insurancePut.number,
            limitAmount: Number(insurancePut.limit),
            expirationDate: formatDateStringReverse(new Date(insuranceExpiration)),
          },
        })
        .catch((err) => {
          err.response.data.violations;
        });
    };

    const postUpdateMediaObjectInsurance = async (type, insurances) => {
      const { getCargoInsurance, liabilityInsurance } = insurances;

      const insurance = type === 'CARGO' ? getCargoInsurance : liabilityInsurance;

      await store.dispatch('ShipmentStore/postUpdateMediaObjectInsurance', {
        id:
          type === 'CARGO'
            ? getCargoInsurance.isCreated.mediaObject.id
            : liabilityInsurance.isCreated.mediaObject.id,
        file: insurance.file,
      });
    };

    const postMediaObjectInsurance = async (type, insurances) => {
      const { getCargoInsurance, liabilityInsurance } = insurances;
      const insurance = type === 'CARGO' ? getCargoInsurance : liabilityInsurance;

      const findInsurance = currentCompany?.value.insurances.find((obj) => obj.type === type);

      await store
        .dispatch('ShipmentStore/postMediaObjectInsurance', {
          entityId: findInsurance.id,
          file: insurance.file,
        })
        .catch((err) => {
          err.response.data.violations;
        });
    };

    const postMediaObjectCompany = async (type, insurances) => {
      const { getW9Media, DOTMCMedia } = insurances;

      let insurance = type === 'W9' ? getW9Media : DOTMCMedia;

      await store
        .dispatch('CompanyStore/postMediaObjectCompany', {
          entityId: currentCompany.value.id,
          file: insurance.file,
          type: type,
        })
        .catch((err) => {
          err.response.data.violations;
        });
    };

    const postUpdateMediaObjectCompany = async (type, insurances) => {
      const { getW9Media, DOTMCMedia } = insurances;

      let insurance = type === 'W9' ? getW9Media : DOTMCMedia;
      let mediaId = type === 'W9' ? getW9Media.isCreated.id : DOTMCMedia.isCreated.id;

      await store
        .dispatch('CompanyStore/postUpdateMediaObjectCompany', {
          id: mediaId,
          file: insurance.file,
        })
        .catch((err) => {
          err.response.data.violations;
        });
    };

    const postInsurance = async (type: string, insurances: any) => {
      const { getCargoInsurance, liabilityInsurance } = insurances;
      const insurance = type === 'CARGO' ? getCargoInsurance : liabilityInsurance;
      await store.dispatch('ShipmentStore/postInsurances', {
        type: type,
        number: insurance.number,
        limitAmount: Number(insurance.limit),
        expirationDate: formatDateStringReverse(insurance.date),
        company: { id: currentCompany.value.id },
      });
    };

    return {
      ...toRefs(data),
      isFormComplete,
      withoutSidebar,
      currentCompany,
      close,
      submit,
      updateData,
      updateLanes,
      updatePermits,
      updateInsurances,
      updateCurrentView,
      postUpdateMediaObjectInsurance,
      postUpdateMediaObjectCompany,
      postMediaObjectInsurance,
    };
  },
});
