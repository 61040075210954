
import { defineComponent, computed } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'CarrierSidebar',
  components: { PlanAvatar, PlanIcon },
  setup (props, { emit }) {
    const store = useStore()

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const actualUser = computed(() => {
      return store.getters['UserStore/getCurrentUser']
    })

    return {
      actualWidth,
      actualUser
    }
  }
})
