
import { computed, defineComponent, reactive, ref, toRefs } from 'vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import store from '@/services/store';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanInputTextArea from '@/components/forms/PlanTextArea.vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import { useI18n } from 'vue-i18n';

interface planCarrierAddAdditionalFee {
  loading: boolean,
  showModal: boolean,
  feeValue: null | number,
  feeExplanation: string,
  observations: string
}

export default defineComponent({
  name: 'PlanCarrierAddAdditionalFee',
  components: { PlanInputFile, PlanInputTextArea, PlanSelect, PlanInputNumber, PlanButton, PlanCarrierDefaultModal, PlanButtonIcon},
  setup (props, { emit }) {
    const files = ref<File[]>([]);
    const store = useStore()
    const translate = useI18n()

    const data = reactive<planCarrierAddAdditionalFee>({
      loading: false,
      showModal: false,
      feeValue: null,
      feeExplanation: '',
      observations: ''
    })

    const changeFeeExplanation = (newFeeExplanation: any) => {
      data.feeExplanation = newFeeExplanation
    }

    const changeFiles = (newFiles: File[]) => {
      files.value = newFiles
    }

    const closeModal = () => {
      if (!data.loading) data.showModal = false
    }

    const feeExplanationItems = computed(() => {
      let result: Array<any> = [];
      store.getters['LoadsStore/getFeeExplanations'].forEach(function (feeExplanation: any) {
        result.push({
          label: feeExplanation.name,
          value: feeExplanation.id.toString(),
        });
      });

      return result;
    })

    function formatPrice(number: number) {
      const locale = store.getters['CountryStore/getCountryDefaultLanguageLocale'];
      const currency = store.getters['CountryStore/getCountryCurrency'];
      const options = {
        style: 'currency',
        currency: currency || 'USD',
        maximumFractionDigits: 0,
        useGrouping: true
      };
      return new Intl.NumberFormat(locale, options).format(number);
    }

    const openModal = () => {
      data.showModal = true
      data.feeValue = null
      data.feeExplanation = ''
      data.observations = ''
      files.value = []
    }

    const postAdditionalFee = async () => {
      data.loading = true
      let additionalFeeId = -1
      await store.dispatch('LoadsStore/postCreateAdditionalFee', {
        shipment: store.getters['CarrierStore/getLoadData'].shipment.id,
        feeExplanation: { id: data.feeExplanation },
        description: data.observations,
        value: data.feeValue,
        status: 'STATUS_PENDING',
        type: 'TYPE_SHIPPER',
      })
        .then((response) => {
          additionalFeeId = response.id
        })

      if (files.value.length > 0) await postAdditionalFeeFile(additionalFeeId)
      await store.dispatch('CarrierStore/getLoadInfoForCarrier', store.getters['CarrierStore/getLoadData'].id)
      await store.dispatch('UserStore/updateToast', {
        text: translate.t('success-post-create-additional-fee'),
        type: 'success',
        visible: true,
      });
      data.loading = false
      closeModal()
    }

    const postAdditionalFeeFile = async (additionalFeeId: any) => {
      for (const file of files.value) {
        const formData = new FormData();
        formData.append('entityId', additionalFeeId.toString());
        formData.append('file', file);
        await store.dispatch('LoadsStore/postCreateAdditionalFeeMedia', formData);
      }
    }

    return {
      ...toRefs(data),
      changeFeeExplanation,
      changeFiles,
      closeModal,
      feeExplanationItems,
      formatPrice,
      openModal,
      postAdditionalFee
    }
  }
})
