
import { defineComponent } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  name: 'LoadDetailTrackingStop',
  components: { PlanButton, PlanIcon },
  props: {

  }
})
