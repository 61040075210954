
import { defineComponent, reactive, toRefs } from 'vue';
import PlanTabs from '@/components/tabs/PlanTabs.vue';
import PlanTable from '@/components/tables/PlanTable.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import LoadDetailQuoteBids from '@/components/loads/detail/quote/LoadDetailQuoteBids.vue';
import LoadDetailQuoteDirectlyAssign from '@/components/loads/detail/quote/LoadDetailQuoteDirectlyAssign.vue';
import LoadDetailQuotePartners from '@/components/loads/detail/quote/LoadDetailQuotePartners.vue';

export default defineComponent({
  name: 'LoadDetailQuote',
  components: { LoadDetailQuotePartners, LoadDetailQuoteDirectlyAssign, LoadDetailQuoteBids, PlanTabs },
  setup () {
    const data = reactive({
      tabs: [
        { label: '0/1 Bids' },
        { label: 'Directly assign' },
        { label: 'Partners' }
      ],
      selectedTab: 0
    })

    const changeTab = (tabIndex) => { data.selectedTab = tabIndex }

    return {
      ...toRefs(data),
      changeTab
    }
  }
})
