
declare var google: any;

import { defineComponent, ref } from 'vue';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
// Base
import BaseComponent from '@/base/BaseComponent';

interface LatLng {
  lat: number;
  lng: number;
}

interface Waypoint {
  location: LatLng;
  stopover: boolean;
}

interface DirectionsRequest {
  origin: LatLng;
  destination: LatLng;
  travelMode: string;
  waypoints?: Waypoint[];
}

interface Position {
  shipment: {
    id: number;
  };
  location: {
    lat: number;
    long: number;
    zip: number;
    city: string;
    state: {
      id: number;
    };
  };
}

export default defineComponent({
  mixins: [BaseComponent],
  /* eslint-disable */
  data() {
    return {
      iconOrigin: require('@/resources/assets/icons/marker-pickup-initial.svg'),
      iconDestiny: require('@/resources/assets/icons/marker-delivery-final.svg'),
      map: null,
      markers: [] as any,
      markerCluster: null,
      showContentPickUp: false,
      showContentDeliverie: false,
    };
  },
  props: {
    load: {
      type: Object,
    },
  },
  methods: {
    addCustomMarker(map, lat, lng, iconURL) {
      let marker = new google.maps.Marker({
        position: { lat: lat, lng: lng },
        map: map,
        icon: iconURL,
      });
    },

    initializeMarkers() {
      let pickup = require('@/resources/assets/icons/marker-pickup.svg');
      let deliver = require('@/resources/assets/icons/marker-delivery.svg');
      this.currentLoad.stops
        .filter((obj) => obj.type !== 'PICKUP_INITIAL' && obj.type !== 'DELIVERY_FINAL')
        .forEach((obj, index) => {
          const marker = new google.maps.Marker({
            icon: new google.maps.MarkerImage(
              obj.type === 'PICKUP' ? pickup : deliver,
              null,
              null,
              null,
              new google.maps.Size(26, 26),
            ),
            title: obj.type == 'PICKUP' ? `${this.$t('pickup')} ` : `${this.$t('delivery')} `,
            label: {
              text: String(index + 1),
              fontSize: '20px',
              fontFamily: 'Nunito',
              color: obj.type == 'PICKUP' ? '#2F3787' : '#f9b318',
            },
            position: {
              lat: obj.location.lat + 0.0001 * index,
              lng: obj.location.long + 0.0001 * index,
            },
            map: this.map,
          });
          marker.addListener('click', () => {
            const infowindow = new google.maps.InfoWindow({
              content: `<strong>${this.$t('stop-with-param', { id: index + 1 })}</strong></br><p><strong>${obj.location.city} ${
                obj.location.state.iso
              }</strong></p>`,
            });
            infowindow.open(this.map, marker);
          });
          this.markers.push(marker);
        });

      const markerCluster = new MarkerClusterer({ map: this.map, markers: this.markers });
    },

    hideContent(type) {
      if (type === 'pickup') {
        this.showContentPickUp = false;
      } else {
        this.showContentDeliverie = false;
      }
    },
  },
  computed: {
    collapsedSidebar() {
      return this.$store.getters['UserStore/getCollapsedSidebar']
    },
    origin(): any {
      return this.currentLoad.stops.find((obj) => obj.type === 'PICKUP_INITIAL');
    },
    destiny(): any {
      return this.currentLoad.stops.find((obj) => obj.type === 'DELIVERY_FINAL');
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    currentLoadLenght(): any {
      return this.currentLoad.stops.length;
    },
    filteredPickupsCompleted(): any {
      const filteredObjects = this.currentLoad.stops.filter(
        (obj) => (obj.type === 'PICKUP_INITIAL' || obj.type === 'PICKUP') && obj.doneAt,
      ).length;
      return filteredObjects;
    },
    filteredDeliveriesCompleted(): any {
      const filteredObjects = this.currentLoad.stops.filter(
        (obj) => (obj.type === 'DELIVERY_FINAL' || obj.type === 'DELIVERY') && obj.doneAt,
      ).length;
      return filteredObjects;
    },
    pickUpQuantity() {
      let sum = 1;
      if (!this.currentLoad.stops || this.currentLoad.stops === undefined) return;
      this.currentLoad.stops.forEach((stop: any) => {
        if (stop.type === 'PICKUP') {
          sum++;
        }
      });
      return sum;
    },
    deliveriesQuantity() {
      let sum = 1;
      if (!this.currentLoad.stops || this.currentLoad.stops === undefined) return;
      this.currentLoad.stops.forEach((stop: any) => {
        if (stop.type === 'DELIVERY') {
          sum++;
        }
      });
      return sum;
    },
    pickUpStops(): any {
      let initialStops: any = [];
      const stops = this.currentLoad.stops;
      const stopsOrdered = stops.sort((a, b) => a.sequence - b.sequence);
      stopsOrdered.forEach((stop: any) => {
        if (stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP') {
          initialStops.push(stop);
        }
      });
      return initialStops;
    },
    deliveryStops(): any {
      let finalStops: any = [];
      const stops = this.currentLoad.stops;
      const stopsOrdered = stops.sort((a, b) => a.sequence - b.sequence);
      stopsOrdered.forEach((stop: any) => {
        if (stop.type === 'DELIVERY_FINAL' || stop.type === 'DELIVERY') {
          finalStops.push(stop);
        }
      });
      return finalStops;
    },
  },
  mounted() {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: this.origin.location.lat, lng: this.origin.location.long },
      zoom: 5,
      streetViewControl: false,
      mapTypeControl: false,
      styles: [
        { featureType: 'all', elementType: 'labels.text.fill', stylers: [{ color: '#ffffff' }] },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#000000' }, { lightness: 13 }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#144b53' }, { lightness: 14 }, { weight: 1.4 }],
        },
        { featureType: 'landscape', elementType: 'all', stylers: [{ color: '#08304b' }] },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#0c4152' }, { lightness: 5 }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#0b434f' }, { lightness: 25 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#0b3d51' }, { lightness: 16 }],
        },
        { featureType: 'road.local', elementType: 'geometry', stylers: [{ color: '#000000' }] },
        { featureType: 'transit', elementType: 'all', stylers: [{ color: '#146474' }] },
        { featureType: 'water', elementType: 'all', stylers: [{ color: '#021019' }] },
      ],
    });

    this.initializeMarkers();

    let iconOrigin = require('@/resources/assets/icons/marker-pickup-initial.svg');
    let iconDestiny = require('@/resources/assets/icons/marker-delivery-final.svg');
    let iconTruck = require('@/resources/assets/icons/marker-truck.png');

    // WINDOWS DATA
    // Esta es la información del marker que se va a mostrar, el contenido acepta HTML
    var infowindow = new google.maps.InfoWindow({
      content: `<strong>${this.$t('in-progress')}</strong>`,
    });

    // ORIGIN & DESTINY MARKERS

    var OriginMarker = new google.maps.Marker({
      position: { lat: this.origin.location.lat + 0.0004, lng: this.origin.location.long + 0.0004 },
      map: this.map,
      icon: new google.maps.MarkerImage(iconOrigin, null, null, null, new google.maps.Size(35, 35)),
      title: 'Origin',
    });

    var DestinyMarker = new google.maps.Marker({
      position: { lat: this.destiny.location.lat, lng: this.destiny.location.long },
      map: this.map,
      icon: new google.maps.MarkerImage(
        iconDestiny,
        null,
        null,
        null,
        new google.maps.Size(35, 35),
      ),
      title: 'Destiny',
    });

    DestinyMarker.addListener('click', () => {
      const infowindow = new google.maps.InfoWindow({
        content: `<p class="font-bold">${this.$t('label.delivery-final')}</p><p><strong>${this.destiny.location.city} ${this.destiny.location.state.iso}</strong></p>`,
      });
      infowindow.open(this.map, DestinyMarker);
    });

    OriginMarker.addListener('click', () => {
      const infowindow = new google.maps.InfoWindow({
        content: `<p class="font-bold">${this.$t('label.pickup-initial')}</p><p><strong>${this.origin.location.city} ${this.origin.location.state.iso}</strong></p>`,
      });
      infowindow.open(this.map, OriginMarker);
    });

    let directionsService = new google.maps.DirectionsService();
    let directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: '#00ffff',
        strokeWeight: 7,
        zIndex: -1,
      },
    });
    directionsDisplay.setMap(this.map);

    let request: DirectionsRequest = {
      origin: new google.maps.LatLng(this.origin.location.lat, this.origin.location.long),
      destination: new google.maps.LatLng(this.destiny.location.lat, this.destiny.location.long),
      travelMode: 'DRIVING',
    };

    if (this.currentLoadLenght !== 2) {
      const waypoints = this.currentLoad.stops.filter(
        (point) => point.type !== 'DELIVERY_FINAL' && point.type !== 'PICKUP_INITIAL',
      );
      request.waypoints = waypoints.map((point) => {
        return {
          location: new google.maps.LatLng(point.location.lat, point.location.long),
          stopover: true,
        };
      });
    }

    directionsService.route(request, function (result, status) {
      if (status === 'OK') {
        directionsDisplay.setDirections(result);
      }
    });
  },
});
