
import { defineComponent, reactive, toRefs, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import UserInformationForm from '@/modules/planning/contacts/_forms/UserInformationForm.vue';
import DriverVehicleForm from '@/modules/planning/contacts/_forms/DriverVehicleForm.vue';
import ContactDetailLanes from '@/modules/planning/contacts/ContactDetailLanes.vue';
import ContactDetailInsurance from '@/modules/planning/contacts/ContactDetailInsurance.vue';

// Base
import BaseComponent from '@/base/BaseComponent';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';

export default defineComponent({
  name: 'ContactForm',
  components: {
    DriverVehicleForm,
    ContactDetailLanes,
    ContactDetailInsurance,
    UserInformationForm,
  },
  emits: ['update', 'updateCurrentView', 'updatePermits', 'createLanes', 'updateLanes', 'updateInsurances'],
  mixins: [BaseComponent, CountryMixins],
  props: {
    contactInfo: { type: Object, required: true },
    lanes: { type: Array, required: true },
    currentView: { type: String, required: true },
    specialPermits: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();

    const forwardUpdate = (contactInfo) => {
      emit('update', contactInfo);
    };

    const forwardUpdatePermits = (specialPermits) => {
      emit('updatePermits', specialPermits);
    };

    const updateLanes = (lanes) => {
      emit('updateLanes', lanes);
    };

    const updateInsurances = (insurances) => {
      emit('updateInsurances', insurances);
    };

    const createLanes = (lanes) => {
      emit('createLanes', lanes);
    };

    const changeCurrentView = (view) => {
      emit('updateCurrentView', view);
    };

    const user = computed(() => {
      return store.getters['UserStore/getUser'];
    });

    return {
      user,
      changeCurrentView,
      createLanes,
      forwardUpdate,
      forwardUpdatePermits,
      updateLanes,
      updateInsurances,
    };
  },
});
