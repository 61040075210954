
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanCarrierPreAssignedBox from '@/components/carrier/_rfpQuote/PlanCarrierPreAssignedBox.vue';

interface planCarrierRfpQuotingPerLane {
  price: null | number,
  loadsPerWeek: null | number
}

export default defineComponent({
  name: 'PlanCarrierRfpQuotingPerLane',
  components: { PlanCarrierPreAssignedBox, PlanInputNumber, PlanIcon },
  props: {
    onlyDetails: { type: Boolean, required: false, default: false },
    positionPreAssigned: { type: Number, required: false, default: -1 }
  },
  setup () {
    const store = useStore()

    const data = reactive<planCarrierRfpQuotingPerLane>({
      price: null,
      loadsPerWeek: null
    })

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const isLaneFilled = computed(() => {
      return data.price !== null && data.price > 0 && data.loadsPerWeek !== null && data.loadsPerWeek > 0
    })

    return {
      ...toRefs(data),
      actualWidth,
      isLaneFilled
    }
  }
})
