import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full mr-1" }
const _hoisted_2 = { class: "plan-form" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex justify-between items-center" }
const _hoisted_5 = {
  key: 0,
  class: "plan-div-form plan-div-form-hide-right flex justify-center items-center",
  style: {"width":"30px"}
}
const _hoisted_6 = { class: "w-full flex justify-end items-center" }
const _hoisted_7 = {
  key: 0,
  class: "plan-div-form-hide-right w-fit plan-p-sm plan-pl-md plan-pr-md plan-t-prim-200 flex justify-center items-center"
}
const _hoisted_8 = ["placeholder", "disabled"]
const _hoisted_9 = {
  key: 3,
  class: "plan-div-form-hide-left w-fit plan-p-sm plan-pl-md plan-pr-md plan-t-prim-200 flex justify-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.mandatory)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.withCurrency)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.currency), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.leftText.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.leftText), 1))
          : _createCommentVNode("", true),
        (!_ctx.isInputOnFocus)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focusInput && _ctx.focusInput(...args))),
              class: "w-full plan-div-form plan-false-form flex justify-start items-center"
            }, _toDisplayString(_ctx.getFormattedValue), 1))
          : _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 2,
              ref: "inputRef",
              onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeTheValue && _ctx.changeTheValue(...args))),
              onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeTheValue && _ctx.changeTheValue(...args))),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.internalValue) = $event)),
              type: "number",
              class: _normalizeClass(["w-full plan-form", {
                 'plan-form-error': _ctx.thereAreChanges && _ctx.validationResult.length > 0,
                 'plan-form-hide-right': _ctx.rightText.length > 0 || _ctx.hideRight,
                 'plan-form-hide-left': _ctx.leftText.length > 0 || _ctx.hideLeft || _ctx.withCurrency
               }]),
              placeholder: _ctx.placeholder,
              disabled: _ctx.disabled
            }, null, 42, _hoisted_8)), [
              [_vModelText, _ctx.internalValue]
            ]),
        (_ctx.rightText.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.rightText), 1))
          : _createCommentVNode("", true),
        (_ctx.validationResult === '' && _ctx.thereAreChanges && _ctx.internalValue !== null && !_ctx.hideValidations)
          ? (_openBlock(), _createBlock(_component_plan_icon, {
              key: 4,
              style: {"position":"absolute","margin-right":"8px"},
              size: "15",
              name: "fill-circle-check",
              "is-svg": "",
              "icon-color": "#00DB50"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.thereAreChanges && !_ctx.hideValidations)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["text-xs text-plan-error-200", _ctx.validationResult.length > 0 ? 'text-plan-error-200':'text-transparent'])
        }, _toDisplayString(_ctx.validationResult), 3))
      : _createCommentVNode("", true)
  ]))
}