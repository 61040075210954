
import { computed, defineComponent, reactive, ref, toRefs } from 'vue';
import PlanTextButton from '@/components/buttons/PlanTextButton.vue';
import PlanCarrierDefaultModal from '@/components/defaults/PlanCarrierDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputFile from '@/components/forms/PlanInputFile.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import { useStore } from 'vuex';
import PlanThumbnail from '@/components/forms/PlanThumbnail.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';

interface planCarrierBrokerUpdateDriverModal {
  showModal: boolean,
  loadingPost: boolean,
  fistName: string,
  lastName: string,
  driverLicense: string,
  email: string,
  prefix: string,
  phoneNumber: string,
  emitId: null | number
}
export default defineComponent({
  mixins: [CountryMixins],
  name: 'PlanCarrierBrokerUpdateDriverModal',
  components: { PlanThumbnail, PlanSelect, PlanInputFile, PlanInputText, PlanButton },
  emit: ['selectDriver'],
  props: {
    driver: { type: Object, required: true }
  },
  setup (props, { emit }) {
    const store = useStore()

    const driverFrontFile = ref<File[]>([]);
    const driverBackFile = ref<File[]>([]);

    const data = reactive({
      showModal: false,
      loadingPost: false,
      fistName: '',
      lastName: '',
      driverLicense: '',
      email: '',
      prefix: '',
      phoneNumber: '',
      emitId: null
    })

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const changeDriverLicenseFront = (files: File[]) => {
      driverFrontFile.value = files
    }

    const changeDriverLicenseBack = (files: File[]) => {
      driverBackFile.value = files
    }

    const changePrefix = (newPrefix: any) => {
      data.prefix = newPrefix
    }

    const close = () => {
      data.loadingPost = false
      data.showModal = false
      data.fistName = ''
      data.lastName = ''
      data.driverLicense = ''
      data.email = ''
      data.prefix = ''
      data.phoneNumber = ''
    }

    const getDrivers = async () => {
      await store.dispatch('CompanyStore/driversList', store.getters['UserStore/getCurrentUser'].company.id)
        .then((response) => {
          store.commit('CarrierStore/setDrivers', response)
        }).finally(() => {
          emit('selectDriver', data.emitId)
        })
    }

    const postDriver = async () => {
      if (!validateDriver.value) return

      data.loadingPost = true
      await store.dispatch('CompanyStore/putDriver', {
        id: props.driver.id,
        params: {
          name: data.fistName,
          surname: data.lastName,
          drivingLicense: data.driverLicense,
          company: { id: store.getters['UserStore/getCurrentUser'].company.id },
          email: data.email,
          phonePrefix: data.prefix,
          phone: data.phoneNumber,
          showContactInformation: true
        }
      }).then((response) => {
        data.emitId = response.id

      })

      if (driverFrontFile.value.length > 0 && data.emitId) await postDriverMediaObject(data.emitId, 'DRIVER_LICENSE_FRONT')
      if (driverBackFile.value.length > 0 && data.emitId) await postDriverMediaObject(data.emitId, 'DRIVER_LICENSE_BACK')
      await getDrivers()
    }

    const postDriverMediaObject = async (driverId: number, type: string) => {
      await store
        .dispatch('CompanyStore/postMediaObjectDriver', {
          entityId: driverId,
          type: type,
          file: type === 'DRIVER_LICENSE_FRONT' ? driverFrontFile.value[0] : driverBackFile.value[0],
        })
    }

    const prefixes = computed(() => {
      return store.getters['CarrierStore/getRegionPrefixes']
    })

    const validateDriver = computed(() => {
      return data.fistName.length > 0 && data.lastName.length > 0 && data.driverLicense.length > 0
    })

    data.fistName = props.driver.name + ''
    data.lastName = props.driver.surname + ''
    data.driverLicense = props.driver.drivingLicense + ''
    data.email = props.driver.email + ''
    data.prefix = props.driver.phonePrefix + ''
    data.phoneNumber = props.driver.phone + ''

    return {
      ...toRefs(data),
      actualWidth,
      changeDriverLicenseFront,
      changeDriverLicenseBack,
      changePrefix,
      close,
      postDriver,
      prefixes,
      validateDriver
    }
  }
})
