
import { defineComponent, computed, reactive, toRefs } from 'vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
export default defineComponent({
  name: 'PlanThumbnail',
  components: { PlanIcon, PlanButtonIcon },
  props: {
    id: { type: Number, required: true, default: -1 },
    entityClass: { type: String, required: true, default: '' },
    showRemove: { type: Boolean, required: false, default: false },
    isMinSize: { type: Boolean, required: false, default: false },
  },
  emits: ['remove'],
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive({
      loadingThumbnailBig: false,
      loadingThumbnailSmall: false,
      url: ''
    })

    const planThumbnailStyle = computed(() => {
      return {
        background: 'white',
        width: props.isMinSize ? '75px':'150px',
        height: props.isMinSize ? '50px':'100px',
        'background-image': `url(${data.url})`,
        'background-size': 'cover',
        'background-position': 'center',
        'box-shadow': '0px 15px 10px -15px #8A8B97'
      }
    })

    const planThumbnailLoadingStyle = computed(() => {
      return {
        background: 'rgba(0,0,0,0.5)',
        width: props.isMinSize ? '75px':'150px',
        height: props.isMinSize ? '50px':'100px'
      }
    })

    const getDownloadThumbnailSmall = () => {
      data.loadingThumbnailSmall = true
      store.dispatch('ShipmentStore/thumbnailSmall', {
        id: props.id,
        class: props.entityClass
      }).then((response) => {
        data.url = URL.createObjectURL(response);
        data.loadingThumbnailSmall = false
      })
    }

    const goToThumbnailBig = () => {
      data.loadingThumbnailBig = true
      store.dispatch('ShipmentStore/thumbnailBig', {
        id: props.id,
        class: props.entityClass
      }).then((response) => {
        const imageUrl = URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = imageUrl;
        link.target = '_blank'
        link.click();
        URL.revokeObjectURL(imageUrl);
        data.loadingThumbnailBig = false
      })
    }

    const remove = () => { emit('remove') }

    getDownloadThumbnailSmall()

    return {
      ...toRefs(data),
      goToThumbnailBig,
      planThumbnailStyle,
      planThumbnailLoadingStyle,
      remove
    }
  }
})
