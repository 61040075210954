
import { defineComponent, reactive, toRefs } from 'vue';
import PlanTable from '@/components/tables/PlanTable.vue';
import PlanSelectSimple from '@/components/forms/PlanSelectSimple.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanRating from '@/components/badges/PlanRating.vue';

export default defineComponent({
  name: 'LoadDetailQuoteBids',
  components: { PlanRating, PlanButton, PlanButtonIcon, PlanAvatar, PlanSelectSimple, PlanTable },
  setup () {
    const data = reactive({
      tabs: [
        { label: '0/1 Bids' },
        { label: 'Directly assign' },
        { label: 'Partners' }
      ],
      headers: [
        { value: 'carrier', label: 'Carrrier/Brooker', align: 'left', showOrder: true },
        { value: 'rating', label: 'Rating', align: 'left', showOrder: true },
        { value: 'completedLoads', label: 'Completed loads', align: 'left', showOrder: true },
        { value: 'historicalAveragePrice', label: 'Historical average price', align: 'left', showOrder: true },
        { value: 'rate', label: 'Rate', align: 'left', showOrder: true },
        { label: '', align: 'left', showOrder: false }
      ],
      items: [
        { user: 'User name', company: 'Company name', rating: 4.6 },
        { user: 'User name', company: 'Company name', rating: 3.2 },
        { user: 'User name', company: 'Company name', rating: 1 },
      ],
      orderBy: 'carrier',
      selectedRange: 'month'
    })

    const changeOrder = (orderByData) => {
      data.orderBy = orderByData.value
    }

    const changeSelectedRange = (newValue) => {
      data.selectedRange = newValue
    }

    return {
      ...toRefs(data),
      changeOrder,
      changeSelectedRange
    }
  }
})
