
import { defineComponent, computed, toRefs, reactive } from 'vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import LoadDetailRightLoad from '@/components/loads/detail/rightColumn/LoadDetailRightLoad.vue';
import PlanTabs from '@/components/tabs/PlanTabs.vue';
import LoadDetailRightBilling from '@/components/loads/detail/rightColumn/LoadDetailRightBilling.vue';
import LoadDetailRightDocuments from '@/components/loads/detail/rightColumn/LoadDetailRightDocumentation.vue';

export default defineComponent({
  name: 'LoadDetailRight',
  components: { LoadDetailRightDocuments, LoadDetailRightBilling, PlanTabs, LoadDetailRightLoad, PlanButtonIcon, PlanAvatar },
  setup () {
    const store = useStore()
    const data = reactive({
      tabs: [
        { label: 'Load' },
        { label: 'Billing' },
        { label: 'Documentation' }
      ],
      selectedTab: 0
    })

    const loadData = computed(() => {
      return store.getters['LoadsStore/getLoadInfo']
    })

    const changeTab = (tabIndex) => { data.selectedTab = tabIndex }

    return {
      ...toRefs(data),
      changeTab
    }
  }
})
