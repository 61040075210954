import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_avatar = _resolveComponent("plan-avatar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.actualWidth <= 640 ? 'carrier-sidebar-top':'carrier-sidebar')
  }, [
    _createVNode(_component_plan_icon, {
      name: "planimatick-collapsed",
      size: "32",
      "is-svg": ""
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_plan_avatar, {
        "user-name": _ctx.actualUser.name,
        onlyAvatar: ""
      }, null, 8, ["user-name"])
    ])
  ], 2))
}