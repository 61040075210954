
import { defineComponent } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanInputHour from '@/components/forms/PlanInputHour.vue';
import PlanInputCalendar from '@/components/forms/PlanInputCalendar.vue';
import PlanGoogleAutocomplete from '@/components/forms/PlanGoogleAutocomplete.vue';
import PlanInputLocation from '@/components/forms/PlanInputLocation.vue';

export default defineComponent({
  name: 'LoadFormStop',
  components: { PlanInputLocation, PlanInputCalendar, PlanInputHour, PlanIcon },
  props: {
    stopType: { type: String, required: true, default: 'stop' },
    stopNumber: { type: Number, required: true, default: 0 }
  }
})
