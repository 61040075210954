export default {
  setActualWidth (state: any, value: number) {
    state.actualWidth = value
  },
  setLoadData (state: any, load: any) {
    state.loadData = load
  },
  setMyDataForm (state: any, myData: any) {
    state.myDataForm = myData
  },
  setCarrierPackageForm (state: any, carrierPackage: any) {
    state.carrierPackageForm = carrierPackage
  },
  setDrivers (state: any, drivers: any) {
    state.drivers = drivers
  },
  setVehicles (state: any, vehicles: any) {
    state.vehicles = vehicles
  },
  setUserBid (state: any, bid: any) {
    state.userBid = bid
  },
   setCarrierChat(state: any, bid: any) {
    state.carrierChat = bid
  },
  setIsDriver(state: any, bid: any) {
    state.isDriver = bid
  },
  setRegionPrefixes (state: any, prefixes: any) {
    state.regionPrefixes = prefixes
  },
  setCargoInsuranceMediaObject (state: any, file: File) {
    state.filesToUpload.cargoInsurance = file
  },
  setLiabilityInsuranceMediaObject (state: any, file: File) {
    state.filesToUpload.liabilityInsurance = file
  },
  setTrackingHistory (state: any, items: any) {
    state.trackingHistory = items
  },
  setShowNotifications (state: any, status: boolean) {
    state.showNotifications = status
  },
  setShowShareLoad (state: any, status: boolean) {
    state.showShareLoad = status
  }
}