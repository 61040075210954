import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "truncate" }
const _hoisted_3 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_4 = { class: "flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_popper = _resolveComponent("popper")!
  const _component_plan_input_number = _resolveComponent("plan-input-number")!
  const _component_plan_select = _resolveComponent("plan-select")!
  const _component_plan_input_text_area = _resolveComponent("plan-input-text-area")!
  const _component_plan_input_file = _resolveComponent("plan-input-file")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_carrier_default_modal = _resolveComponent("plan-carrier-default-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_popper, {
      placement: "left",
      hover: "",
      class: "plan-carrier-fixed-button-popper",
      offsetDistance: "2"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('add-additional-fee')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_plan_button_icon, {
          onOnClick: _ctx.openModal,
          icon: "simple-dollar",
          type: "warning",
          color: "white",
          style: {"min-width":"38px"}
        }, null, 8, ["onOnClick"])
      ]),
      _: 1
    }),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_plan_carrier_default_modal, {
          key: 0,
          onClose: _ctx.closeModal,
          title: _ctx.$t('report-additional-fee'),
          size: "sm-auto",
          "hide-close-button": ""
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_plan_input_number, {
                "model-value": _ctx.feeValue,
                "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.feeValue) = $event)),
                label: _ctx.$t('fee'),
                placeholder: _ctx.formatPrice(0),
                currency: "",
                mandatory: "",
                "hide-validations": ""
              }, null, 8, ["model-value", "label", "placeholder"]),
              _createVNode(_component_plan_select, {
                onChange: _ctx.changeFeeExplanation,
                value: _ctx.feeExplanation,
                items: _ctx.feeExplanationItems,
                label: _ctx.$t('feeExplanation'),
                placeholder: _ctx.$t('select-fee-explanation'),
                mandatory: ""
              }, null, 8, ["onChange", "value", "items", "label", "placeholder"]),
              _createVNode(_component_plan_input_text_area, {
                "model-value": _ctx.observations,
                "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.observations) = $event)),
                placeholder: _ctx.$t('comments'),
                rows: 4
              }, null, 8, ["model-value", "placeholder"]),
              _createVNode(_component_plan_input_file, {
                onChange: _ctx.changeFiles,
                types: ['application/pdf', 'image/*'],
                maxSize: 10,
                multiple: ""
              }, null, 8, ["onChange"])
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.closeModal,
                disabled: _ctx.loading,
                type: "cancel",
                label: _ctx.$t('cancel')
              }, null, 8, ["onOnClick", "disabled", "label"]),
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.postAdditionalFee,
                loading: _ctx.loading,
                disabled: _ctx.feeValue <= 0 || _ctx.feeExplanation.length === 0,
                type: "primary",
                label: _ctx.$t('send'),
                style: {"min-width":"100px"}
              }, null, 8, ["onOnClick", "loading", "disabled", "label"])
            ])
          ]),
          _: 1
        }, 8, ["onClose", "title"]))
      : _createCommentVNode("", true)
  ]))
}