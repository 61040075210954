
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanDefaultWindows from '@/components/defaults/PlanDefaultWindows.vue';
import { useStore } from 'vuex';
import PlanLoading from '@/components/loadings/PlanLoading.vue';
import LoadDetailHeader from '@/components/loads/detail/LoadDetailHeader.vue';
import LoadDetailRight from '@/components/loads/detail/rightColumn/LoadDetailRight.vue';
import LoadDetailQuote from '@/components/loads/detail/quote/LoadDetailQuote.vue';
import LoadDetailMap from '@/components/loads/detail/LoadDetailMap.vue';
import LoadDetailAccepting from '@/components/loads/detail/accepting/LoadDetailAccepting.vue';
import LoadDetailTracking from '@/components/loads/detail/tracking/LoadDetailTracking.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanDefaultConfirmationModal from '@/components/defaults/PlanDefaultConfirmationModal.vue';
import LoadDetailNewTemplateModal from '@/components/loads/detail/LoadDetailNewTemplateModal.vue';
import LoadDetailDelivered from '@/components/loads/detail/delivered/LoadDetailDelivered.vue';
import { useI18n } from 'vue-i18n';
import LoadForm from '@/components/loads/form/LoadForm.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import LoadModifyModal from '@/components/loads/LoadModifyModal.vue';

interface loadDetail {
  loadingLoad: boolean,
  selectedTab: number,
  showForm: boolean,
  showModals: {
    cancel: boolean,
    delete: boolean,
    newTemplate: boolean
  }
}

export default defineComponent({
  name: 'LoadDetail',
  components: { LoadModifyModal, LoadDetailDelivered, LoadDetailNewTemplateModal, PlanDefaultConfirmationModal, PlanButton, LoadDetailTracking, LoadDetailAccepting, LoadDetailQuote, LoadDetailRight, LoadDetailHeader, PlanDefaultWindows },
  props: {
    loadId: { type: Number, required: true, default: 0 }
  },
  emits: ['close'],
  setup (props, { emit }) {
    const store = useStore()
    const translate = useI18n()

    const data = reactive<loadDetail>({
      loadingLoad: false,
      selectedTab: 0,
      showForm: false,
      showModals: {
        cancel: false,
        delete: false,
        newTemplate: false
      }
    })

    const load = computed(() => {
      return store.getters['LoadsStore/currentLoad']
    })

    const tabsToShow = computed(() => {
      let loadStatus = store.getters['LoadsStore/currentLoad'].status
      if (loadStatus === 'CANCELED') return []

      let quoteIsDisabled = false
      let quoteIcon = (loadStatus === 'CONFIRMED' || loadStatus === 'ACCEPTING' || loadStatus === 'IN_PROGRESS' || loadStatus === 'FINISHED') ? 'circle-check':''
      let acceptingIsDisabled = loadStatus === 'QUOTING'
      let acceptingIcon = (loadStatus === 'IN_PROGRESS' || loadStatus === 'FINISHED') ? 'circle-check':''
      let trackingIsDisabled = loadStatus === 'QUOTING' || loadStatus === 'ACCEPTING'
      let trackingIcon = (loadStatus === 'FINISHED') ? 'circle-check':''
      let deliveryIsDisabled = loadStatus === 'QUOTING' || loadStatus === 'ACCEPTING' || loadStatus === 'IN_PROGRESS'
      let deliveryIcon = (loadStatus === 'FINISHED') ? 'circle-check':''

      return [
        { label: translate.t('accept-quote'), icon: quoteIcon, disabled: quoteIsDisabled },
        { label: translate.t('accepting'), icon: acceptingIcon, disabled: acceptingIsDisabled },
        { label: translate.t('in-course'), icon: trackingIcon, disabled: trackingIsDisabled },
        { label: translate.t('document-delivered'), icon: deliveryIcon, disabled: deliveryIsDisabled }
      ]
    })

    const userRegion = computed(() => {
      return store.getters['UserStore/getCurrentUser'].region
    })

    const changeTab = (newTab) => { data.selectedTab = newTab }

    const close = () => {
      store.commit('LoadsStore/setLoadInfo', null)
      emit('close')
    }

    const getLoad = async () => {
      data.loadingLoad = true
      await store.dispatch('LoadsStore/getLoadInfo', props.loadId)
        .then((response) => {
          console.log('getLoadInfo', store.getters['LoadsStore/getLoadInfo'])
        }).catch((error) => {
          console.log('error', error)
      })

      data.loadingLoad = false
    }

    if (props.loadId !== 0) getLoad()

    return {
      ...toRefs(data),
      load,
      tabsToShow,
      changeTab,
      close
    }
  }
})
