
import { defineComponent } from 'vue';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import PlanInputMultiSelect from '@/components/forms/PlanInputMultiSelect.vue';
import AddCarrierModal from '@/modules/planning/userProfile/AddCarrierModal.vue';
import ModalInput from '@/components/modals/InputModal.vue';
import RankingRFPModal from '@/modules/rfp/_components/modals/RankingRFPModal.vue';
import SetMaxResponseTimeModal from '@/modules/rfp/_components/modals/SetMaxResponseTimeModal.vue';
import TopOffersLeftTable from '@/modules/planning/loadDetail/rfp/_components/TopOffersLeftTable.vue';
import TopOffersRightTable from '@/modules/planning/loadDetail/rfp/_components/TopOffersRightTable.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanHistoricalPaidRate from '@/components/loads/detail/quote/PlanHistoricalPaidRate.vue';
import { debounce } from 'lodash';
import PlanHeaderSearch from '@/components/filters/PlanHeaderSearch.vue';
interface User {
  id: number;
  name: string;
  company: any;
  owner: {
    name: string;
  };
  status: string;
  stats: {
    amountValorations: number;
    shipmentAmount: number;
    averageShipmentPrice: number;
  };
  source: string;
  LaneAssignedShipmentsFinishedCount: number;
  LaneAverageShipmentPrice: number;
}

export default defineComponent({
  name: 'ShipperLoadContainerQuotesRFP',
  components: {
    PlanHistoricalPaidRate,
    AddCarrierModal,
    ModalInput,
    PlanButton,
    PlusCircleIcon,
    PlanInputMultiSelect,
    RankingRFPModal,
    SetMaxResponseTimeModal,
    TopOffersLeftTable,
    TopOffersRightTable,
    PlanHeaderSearch,
  },
  mixins: [BaseComponent],
  props: {
    loadCreatedId: {
      type: Number,
    },
    loadId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      acceptedBidByRate: '',
      acceptedBidByCompanyName: '',
      carrierAdded: {} as any,
      modalVisible: false,
      section: this.$store.getters['GlobalStore/getSectionFromPlanification'] ? 'direct' : 'bids',
      kekw: null,
      historicalRange: 'LAST_MONTH',
      isLeft: this.$store.getters['GlobalStore/getSectionFromPlanification'] ? false : true,
      isLoading: true,
      isSortByName: false,
      isSortByRating: false,
      isSortByQuantity: false,
      isSortByAverage: false,
      isSortByBidName: false,
      isSortByBidRating: false,
      isSortByBidQuantity: false,
      isSortByBidAverage: false,
      isSortByBidRate: false,
      trailerTypeIdToSearch: 0,
      isTrailerFiltered: true,
      showRankingModal: false,
      showMaxResponseTimeModal: false,
      showFilters: false,
      selectedContacts: [] as any,
      useContactPage: 1,
      userType: 'CARRIER|BROKER',
      draggableList: [],
      bgColor: 'white',
      isSending: [] as any,
      allowed: true,
      showSpinner: false,
      netWorkContactPage: 1,
      searchQuery: '',
      showReassignModal: false,
      reassignModalText: this.$t('reassign-confirm'),
      assignModalText: this.$t('direct-assign'),
      reassignCompany: null,
      searchByCompanyName: '',
      searchByRating: '',
      searchByLoadsQuantity: '',
      searchByAverageBid: '',
      acceptedBidByRating: '',
      acceptedBidByQuantity: '',
      acceptedBidByAverage: '',
      selectedRequeriments: [],
      sortBy: '',
      isUpdateList: true,
      showModalDirectAssign: false,
      carrierToAssign: {} as any,
      bestBidId: 0,
      isDeassign: false,
      showDeAssign: false,
      deassignModalText: this.$t('de-assign-confirm'),
      isBookNow: false,
      debouncedFetchContactList: null as any,
      searchByUserName: '',
      permits: [
        {
          value: 'hasTransportAndDeliveryOfAlcoholicBeveragesPermit',
          translation: 'permits-contact.hasTransportAndDeliveryOfAlcoholicBeveragesPermit',
        },
        {
          value: 'hasHazardousMaterialPermit',
          translation: 'permits-contact.hasHazardousMaterialPermit',
        },
        { value: 'hasTWICCard', translation: 'permits-contact.hasTWICCard' },
        { value: 'hasTSA', translation: 'permits-contact.hasTSA' },
        {
          value: 'hasOverweightLoadPermit',
          translation: 'permits-contact.hasOverweightLoadPermit',
        },
        { value: 'hasTeamDriverPermit', translation: 'permits-contact.hasTeamDriverPermit' },
        { value: 'hasBondPermit', translation: 'permits-contact.hasBondPermit' },
        { value: 'hasTirPermit', translation: 'permits-contact.hasTirPermit' },
        {
          value: 'hasMoveFullTruckPermit',
          translation: 'permits-contact.hasMoveFullTruckPermit',
        },
        { value: 'hasMovePartialPermit', translation: 'permits-contact.hasMovePartialPermit' },
        {
          value: 'hasOversizeLoadPermit',
          translation: 'permits-contact.hasMovePartialPermit',
        },
      ],
      bookNow: undefined,
      searchTimeout: null as any,
      isMoving: false,
      drag: false,
      relaunching: false,
    };
  },
  created(): void {
    this.debouncedFetchContactList = debounce(this.fetchContactList, 500);
    this.$store.dispatch('LoadsStore/quotesAverageStats', {
      trailerType: this.currentLoad?.trailerType?.id,
      originZip: this.originZip,
      destinyZip: this.destinyZip,
      period: this.historicalRange,
    });
    this.isLoading = true;
    this.$store
      .dispatch('LoadsStore/getRankingRFP', {
        id: this.currentLoad.id,
        data: {
          page: 1,
          maxResults: 100,
        },
      })
      .finally(() => {
        this.isLoading = false;
      });

    this.$store.dispatch('RFPStore/getProjectById', {
      projectId: this.currentLoad?.laneRFP?.project?.id,
      page: 1,
    });
  },
  computed: {
    calculatePercentageSaved() {
      const quoteAverage = this.quotesAverageStats?.quoteAverage || 0;
      const rateAverage = this.quotesAverageStats?.rateAverage || 0;

      if (quoteAverage === 0) {
        return 0;
      }

      const percentageSaved = ((quoteAverage - rateAverage) / quoteAverage) * 100;
      return percentageSaved.toFixed(2);
    },

    quotesAverageStats(): any {
      return this.$store.getters['LoadsStore/quotesAverageStats'];
    },
    currentProject(): any {
      return this.$store.getters['RFPStore/getCurrentProject'];
    },
    carrierRequestedBid(): User[] {
      return this.$store.getters['UserStore/requestBidStatsByLoad'];
    },
    companyAccepted(): any {
      return this.$store.getters['ShipmentStore/companyAccepted'];
    },
    currentContacts(): any[] {
      if (this.carrierAdded?.id !== undefined) {
        let current = this.$store.getters['UserStore/carrierContactListStatsByLoad'];
        current.unshift(this.carrierAdded);
        return current;
      } else {
        return this.$store.getters['UserStore/carrierContactListStatsByLoad'];
      }
    },
    currentLane(): any {
      return this.$store.getters['RFPStore/getCurrentLane'];
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    destinyZip(): any {
      return this.currentLoad.stops.filter((stop) => stop.type === 'DELIVERY_FINAL')[0].location
        .zip;
    },
    isOpenChat(): any {
      return this.$store.getters['GlobalStore/isOpenChat'];
    },
    isRFP(): boolean {
      return this.currentLoad?.source === 'RFP';
    },
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
    maxBidsRequest(): number {
      return +this.$store.getters['GlobalStore/getGlobalConfig']
        .LIMIT_AMOUNT_OF_COMPANIES_TO_SEND_BID_REQUEST;
    },
    originZip(): any {
      return this.currentLoad.stops.filter((stop) => stop.type === 'PICKUP_INITIAL')[0].location
        .zip;
    },
    quotes(): any {
      return this.$store.getters['LoadsStore/getLTLQuotesById'];
    },
    rankingList(): any {
      let quotesArray = this.$store.getters['LoadsStore/getRankingRFP'];
      let sortedQuotes = quotesArray.sort((a, b) => {
        return a.quote.position - b.quote.position;
      });

      return sortedQuotes;
    },
    savedBidsLTL(): any {
      return this.$store.getters['ShipmentStore/getSavedShipmentBids'];
    },
    shipmentId(): any {
      return this.currentLoad.shipment.id;
    },
    sourceCarrier() {
      let source: any = this.currentContacts.filter(
        (item: any) => item.source === 'CARGOBOT' || item.source === 'CARGOBOT_EU',
      );
      return source;
    },
    totalBids(): any {
      return this.$store.getters['ShipmentStore/totalBids'];
    },
    totalSavedBidsLTL(): any {
      return this.$store.getters['LoadsStore/totalSavedBidsLTL'];
    },
    userForChat(): any {
      return this.$store.state.ChatsStore.userForChat;
    },
  },
  methods: {
    changeSelectedPermits(permitsSelected) {
      this.selectedRequeriments = permitsSelected;
      this.debouncedFetchContactList();
    },
    handleInputChange(event) {
      this.searchByUserName = event;
      this.debouncedFetchContactList();
    },
    fetchContactList() {
      console.log(this.trailerTypeIdToSearch);

      this.$store
        .dispatch('UserStore/carrierContactListStatsByLoad', {
          page: this.useContactPage,
          name: this.searchByUserName,
          type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
          trailerType: !this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
          trailerTypeStats: this.currentLane.loadTemplate.trailerType.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
          permits: this.selectedRequeriments,
        })
        .then(() => {
          if (this.sourceCarrier && this.sourceCarrier.length > 0) {
            if (!this.selectedContacts.some((item) => item === this.sourceCarrier[0].id)) {
              this.selectedContacts.push(this.sourceCarrier[0].id);
            }
          }
        });
    },
    fetch() {
      this.isLoading = true;
      this.$store
        .dispatch('LoadsStore/getRankingRFP', {
          id: this.currentLoad.id,
          data: {
            page: 1,
            maxResults: 100,
          },
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async acceptBid(company) {
      if (this.isSuperAdminRoute) {
        return;
      }
      this.reassignCompany = company;
      if (this.currentLoad.status === 'QUOTING') {
        this.$store
          .dispatch('ShipmentStore/acceptBid', {
            id: company.bestBidId,
            // id: this.shipmentId,
            // companyId: company,
          })
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('bid-accepted'));
            this.$store.dispatch('LoadsStore/loadById', this.loadId);
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          })
          .finally(() => {
            console.log('');
          });
      } else {
        this.showReassignModal = true;
      }
    },
    async addMemberToChatLoad(chatId) {
      await this.$store.dispatch('ChatsStore/addMemberToChatLoad', chatId);
    },
    addSelectedContact(id: number, i) {
      this.isSending[i] = true;
      this.selectedContacts.push(id);
      this.validateForm(i);
    },
    callbackFetchLanesDetail() {
      this.$store.dispatch('RFPStore/getProjectById', {
        projectId: this.currentLoad?.laneRFP?.project?.id,
        page: 1,
      });
    },
    callbackToggleMaxResponseModal() {
      if (this.isShipperViewer) return;
      this.showMaxResponseTimeModal = !this.showMaxResponseTimeModal;
    },
    checkCompanyId(currentBids, contact) {
      return currentBids.some((item) => item.company.id === contact);
    },
    directAssign(price: any) {
      if (price === null) {
        this.showModalDirectAssign = false;
        return;
      }
      if (this.currentLoad.status !== 'QUOTING') {
        BaseComponent.methods?.showToastError(this.$t('message-only-quoting-state'));
        this.showModalDirectAssign = false;
        return;
      }
      if (price !== null) {
        this.showModalDirectAssign = false;

        // Lanzar la assignación directa
        const baseObject = {
          shipments: [{ id: this.currentLoad.shipment.id }],
          company: { id: this.carrierToAssign.id },
          price: price,
        };
        this.$store
          .dispatch('UserStore/assignLoad', baseObject)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('carrier-assigned'));
            this.setCarrierContactListStatsByLoad();
            this.setRequestBidStatsByLoad();

            // this.$store.dispatch('LoadsStore/showModalCarrierAssigned', true);
            // this.nextRoute(route);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.$store.dispatch('LoadsStore/currentLoadEditing', null);
            this.allowed = true;
            // this.sendingAction = false;
          });
      }
    },
    hideModal(carrier: any) {
      if (carrier !== 0) {
        this.carrierAdded = carrier.company;
        this.selectedContacts.push(carrier.company.id);
        if (!this.selectedContacts.some((item) => item === this.sourceCarrier()[0].id)) {
          this.selectedContacts.push(this.sourceCarrier()[0].id);
        }
        this.modalVisible = !this.modalVisible;
      } else {
        this.modalVisible = !this.modalVisible;
      }
    },
    historicalRangeFilter() {
      this.$store.dispatch('LoadsStore/quotesAverageStats', {
        trailerType: this.currentLoad?.trailerType?.id,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
      });
    },
    isLastInGroup(index) {
      if (index === this.rankingList.length - 1) {
        return false;
      }

      const currentElement = this.rankingList[index];
      const nextElement = this.rankingList[index + 1];
      return currentElement.quote?.rankingOrder !== nextElement.quote?.rankingOrder;
    },
    isRead(lane): any {
      const member = lane?.lanesChat?.chatMembers.find(
        (chat) => chat.users.id === this.currentUser?.id,
      );
      return lane.chat_rfp_readed ?? member?.readed ?? true;
    },
    modalDirectAssign(data: any) {
      this.showModalDirectAssign = true;
      this.carrierToAssign = data;
    },
    moveLeft(section) {
      this.isLeft = true;
      this.section = section;
    },
    moveRight(section) {
      this.isLeft = false;
      this.section = section;
    },
    async openChat(event, user) {
      if (!this.isOpenChat) {
        this.$store.commit('GlobalStore/setOpenChat', false);
        const member = user?.lanesChat?.chatMembers.find(
          (chat) => chat.users.id === this.currentUser?.id,
        );
        if (user?.lanesChat) {
          member.readed = true;
        }
        setTimeout(() => {
          this.$store.commit('ChatsStore/setRankingForChat', user);
          this.$store.commit('ChatsStore/setUserForChat', user);
          this.$store.commit('ChatsStore/setLaneForChat', this.currentLane);
          this.$store.commit(
            'ChatsStore/setChatId',
            user.chat_rfp_id ?? user?.lanesChat?.id ?? null,
          );
          this.$store.commit('GlobalStore/setOpenChat', true);
        }, 500);
      }
    },
    quotedQuotes() {
      return this.quotes.quotedQuotes;
    },
    relaunchRankingRFP() {
      if (this.isSuperAdminRoute) {
        return;
      }
      this.relaunching = true;
      this.$store
        .dispatch('LoadsStore/relaunchRankingRFP', this.currentLoad.id)
        .then((res) => {
          BaseComponent.methods?.showToastSuccess(this.$t('ranking-updated'));
          this.fetch();
          this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id);
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.details);
        })
        .finally(() => {
          this.relaunching = false;
        });
    },
    reSendQuote(bidId: any, index) {
      if (this.isSuperAdminRoute) {
        return;
      }
      this.isSending[index] = true;
      this.$store
        .dispatch('ShipmentStore/reSendRequestBid', bidId)
        .then((res) => {
          BaseComponent.methods?.showToastSuccess(this.$t('toast-re-send-quote'));
          this.isSending[index] = false;
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.details);
          this.isSending[index] = false;
        });
    },
    setCarrierContactListStatsByLoad() {
      this.$store
        .dispatch('UserStore/carrierContactListStatsByLoad', {
          destinyZip: this.destinyZip,
          lanesRFP: this.currentLane?.id,
          name: null,
          originZip: this.originZip,
          page: this.useContactPage,
          period: this.historicalRange,
          sortBy: null,
          trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          type: 'CARRIER|BROKER',
        })
        .then(() => {
          // 1. Check Cargobot in the contact list (for the Brokers)
          // 2. Check there isn't Quoting in Cargobot
          // 3. Add Cargobot to contact list to send Quoting
          if (this.sourceCarrier().length > 0) {
            if (!this.checkCompanyId(this.carrierRequestedBid, this.sourceCarrier()[0].id)) {
              this.selectedContacts.push(this.sourceCarrier()[0].id);
            }
          }
        });
    },
    setRequestBidStatsByLoad() {
      if (this.shipmentId !== null) {
        this.$store.dispatch('UserStore/requestBidStatsByLoad', {
          page: 1,
          shipment: this.shipmentId,
          sortBy: `rate[ASC]`,
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });

        this.$store.dispatch('ShipmentStore/totalBids', {
          id: this.shipmentId,
        });
        // .then(() => (this.showDirectAssign = true));
      }
    },
    showModalDeAssign() {
      if (this.isSuperAdminRoute) {
        return;
      }
      this.showDeAssign = true;
    },
    toggleOpenRanking() {
      this.showRankingModal = !this.showRankingModal;
    },
    updateAcceptedBidByAverage() {
      this.isSortByBidName = false;
      this.isSortByBidRating = false;
      this.isSortByBidQuantity = false;
      this.isSortByBidAverage = true;
      this.isSortByBidRate = false;

      if (this.acceptedBidByAverage === '') {
        this.acceptedBidByAverage = 'DESC';
      } else {
        if (this.acceptedBidByAverage === 'DESC') {
          this.acceptedBidByAverage = 'ASC';
        } else {
          this.acceptedBidByAverage = '';
        }
      }
      // this.isSortBy();
      if (this.shipmentId !== null) {
        this.$store.dispatch('UserStore/requestBidStatsByLoad', {
          page: 1,
          shipment: this.shipmentId,
          sortBy: `${this.acceptedBidByAverage}`
            ? `averageBid[${this.acceptedBidByAverage}]`
            : 'rate[ASC]',
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      }
    },
    updateAcceptedBidByCompanyName() {
      this.isSortByBidName = true;
      this.isSortByBidRating = false;
      this.isSortByBidQuantity = false;
      this.isSortByBidAverage = false;
      this.isSortByBidRate = false;

      if (this.acceptedBidByCompanyName === '') {
        this.acceptedBidByCompanyName = 'DESC';
      } else {
        if (this.acceptedBidByCompanyName === 'DESC') {
          this.acceptedBidByCompanyName = 'ASC';
        } else {
          this.acceptedBidByCompanyName = '';
        }
      }
      // this.isSortBy();
      if (this.shipmentId !== null) {
        this.$store.dispatch('UserStore/requestBidStatsByLoad', {
          page: 1,
          shipment: this.shipmentId,
          sortBy:
            `${this.acceptedBidByCompanyName}` !== ''
              ? `companyName[${this.acceptedBidByCompanyName}]`
              : 'rate[ASC]',
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      }
    },
    updateAcceptedBidByQuantity() {
      this.isSortByBidName = false;
      this.isSortByBidRating = false;
      this.isSortByBidQuantity = true;
      this.isSortByBidAverage = false;
      this.isSortByBidRate = false;

      if (this.acceptedBidByQuantity === '') {
        this.acceptedBidByQuantity = 'DESC';
      } else {
        if (this.acceptedBidByQuantity === 'DESC') {
          this.acceptedBidByQuantity = 'ASC';
        } else {
          this.acceptedBidByQuantity = '';
        }
      }
      // this.isSortBy();
      if (this.shipmentId !== null) {
        this.$store.dispatch('UserStore/requestBidStatsByLoad', {
          page: 1,
          shipment: this.shipmentId,
          sortBy: `${this.acceptedBidByQuantity}`
            ? `shipmentAmount[${this.acceptedBidByQuantity}]`
            : 'rate[ASC]',
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      }
    },
    updateAcceptedBidByRate() {
      this.isSortByBidName = false;
      this.isSortByBidRating = false;
      this.isSortByBidQuantity = false;
      this.isSortByBidAverage = false;
      this.isSortByBidRate = true;

      if (this.acceptedBidByRate === '') {
        this.acceptedBidByRate = 'DESC';
      } else {
        if (this.acceptedBidByRate === 'DESC') {
          this.acceptedBidByRate = 'ASC';
        } else {
          this.acceptedBidByRate = '';
        }
      }
      // this.isSortBy();
      if (this.shipmentId !== null) {
        this.$store.dispatch('UserStore/requestBidStatsByLoad', {
          page: 1,
          shipment: this.shipmentId,
          sortBy: `${this.acceptedBidByRate}` ? `rate[${this.acceptedBidByRate}]` : 'rate[ASC]',
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      }
    },
    updateAcceptedBidByRating() {
      this.isSortByBidName = false;
      this.isSortByBidRating = true;
      this.isSortByBidQuantity = false;
      this.isSortByBidAverage = false;
      this.isSortByBidRate = false;

      if (this.acceptedBidByRating === '') {
        this.acceptedBidByRating = 'DESC';
      } else {
        if (this.acceptedBidByRating === 'DESC') {
          this.acceptedBidByRating = 'ASC';
        } else {
          this.acceptedBidByRating = '';
        }
      }
      // this.isSortBy();
      if (this.shipmentId !== null) {
        this.$store.dispatch('UserStore/requestBidStatsByLoad', {
          page: 1,
          shipment: this.shipmentId,
          sortBy:
            `${this.acceptedBidByRating}` !== ''
              ? `rating[${this.acceptedBidByRating}]`
              : 'rate[ASC]',
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      }
    },
    updateSearchByAverageBid() {
      this.carrierAdded = {} as User;

      this.isSortByName = false;
      this.isSortByRating = false;
      this.isSortByQuantity = false;
      this.isSortByAverage = true;

      if (this.searchByAverageBid === '') {
        this.searchByAverageBid = 'DESC';
      } else {
        if (this.searchByAverageBid === 'DESC') {
          this.searchByAverageBid = 'ASC';
        } else {
          this.searchByAverageBid = '';
        }
      }
      // this.isSortBy();
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        lanesRFP: this.currentLane?.id,
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        sortBy: `averageBid[${this.searchByAverageBid}]`,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.currentLoad?.trailerType?.id,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
      });
    },
    updateSearchByCompanyName() {
      this.carrierAdded = {} as User;

      this.isSortByName = true;
      this.isSortByRating = false;
      this.isSortByQuantity = false;
      this.isSortByAverage = false;

      if (this.searchByCompanyName === '') {
        this.searchByCompanyName = 'DESC';
      } else {
        if (this.searchByCompanyName === 'DESC') {
          this.searchByCompanyName = 'ASC';
        } else {
          this.searchByCompanyName = '';
        }
      }
      // this.isSortBy();
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        lanesRFP: this.currentLane?.id,
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        sortBy: `companyName[${this.searchByCompanyName}]`,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.currentLoad?.trailerType?.id,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
      });
    },
    updateSearchByLoadsQuantity() {
      this.carrierAdded = {} as User;

      this.isSortByName = false;
      this.isSortByRating = false;
      this.isSortByQuantity = true;
      this.isSortByAverage = false;

      if (this.searchByLoadsQuantity === '') {
        this.searchByLoadsQuantity = 'DESC';
      } else {
        if (this.searchByLoadsQuantity === 'DESC') {
          this.searchByLoadsQuantity = 'ASC';
        } else {
          this.searchByLoadsQuantity = '';
        }
      }
      // this.isSortBy();
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        lanesRFP: this.currentLane?.id,
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        sortBy: `loadsQuantity[${this.searchByLoadsQuantity}]`,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.currentLoad?.trailerType?.id,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
      });
    },
    updateSearchByRating() {
      this.carrierAdded = {} as User;

      this.isSortByName = false;
      this.isSortByRating = true;
      this.isSortByQuantity = false;
      this.isSortByAverage = false;

      if (this.searchByRating === '') {
        this.searchByRating = 'DESC';
      } else {
        if (this.searchByRating === 'DESC') {
          this.searchByRating = 'ASC';
        } else {
          this.searchByRating = '';
        }
      }
      // this.isSortBy();
      this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
        lanesRFP: this.currentLane?.id,
        page: this.useContactPage,
        name: null,
        type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
        sortBy: `rating[${this.searchByRating}]`,
        trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
        trailerTypeStats: this.currentLoad?.trailerType?.id,
        originZip: this.originZip,
        destinyZip: this.destinyZip,
        period: this.historicalRange,
      });
    },
    updateSearchTypeUser(userType: string) {
      this.carrierAdded = {} as User;

      if (userType === 'TRAILER') {
        this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
          lanesRFP: this.currentLane?.id,
          page: this.useContactPage,
          name: null,
          type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
          trailerType: !this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      } else {
        let searchArray = this.userType.split('|');
        if (searchArray.includes(userType)) {
          searchArray = searchArray.filter((el) => el !== userType);
          this.userType = searchArray.join('|');
        } else {
          if (this.userType === '') {
            this.userType = userType;
          } else {
            this.userType += '|' + userType;
          }
        }
        this.$store.dispatch('UserStore/carrierContactListStatsByLoad', {
          lanesRFP: this.currentLane?.id,
          page: this.useContactPage,
          name: null,
          type: this.userType === '' ? 'CARRIER|BROKER' : this.userType,
          trailerType: this.isTrailerFiltered ? this.trailerTypeIdToSearch : null,
          trailerTypeStats: this.currentLoad?.trailerType?.id,
          originZip: this.originZip,
          destinyZip: this.destinyZip,
          period: this.historicalRange,
        });
      }
    },
    async validateForm(i) {
      if (this.selectedContacts.length > this.maxBidsRequest) {
        BaseComponent.methods?.showToastError(this.$t('number-max-send-quotes'));
        return;
      }

      if (!this.allowed) return;

      let baseObject = {
        companies: this.selectedContacts.map((item) => ({ id: item })),
      };

      this.isSending[i] = true;
      this.allowed = false;

      this.$store
        .dispatch('LoadsStore/shipmentRequestBatch', {
          companies: baseObject.companies,
          shipments: [{ id: this.shipmentId }],
        })
        .then(() => {
          this.selectedContacts = [];
          BaseComponent.methods?.showToastSuccess(this.$t('quote-send-correctly'));
          this.$store.dispatch('UserStore/requestBidStatsByLoad', {
            page: 1,
            shipment: this.shipmentId,
            trailerTypeStats: this.currentLoad?.trailerType?.id,
            originZip: this.originZip,
            destinyZip: this.destinyZip,
            period: this.historicalRange,
          });
          this.$store.dispatch('ShipmentStore/totalBids', {
            id: this.shipmentId,
          });
          // .then(() => (this.showDirectAssign = true));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.details);
        })
        .finally(() => {
          this.isSending[i] = false;
          this.allowed = true;
        });
    },
  },
  watch: {
    '$store.getters["GlobalStore/getSectionFromPlanification"]': function (newVal) {
      this.section = newVal ? 'direct' : 'bids';
      this.isLeft = newVal ? false : true;
    },
  },
});
