import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center plan-gap-xs" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "flex flex-col gap-2" }
const _hoisted_4 = { class: "load-card-state-content" }
const _hoisted_5 = { class: "load-card-state-title" }
const _hoisted_6 = {
  key: 0,
  style: {"padding":"4px 8px"},
  class: "text-xs text-white font-bold plan-b-red-500 rounded-full text-center"
}
const _hoisted_7 = {
  class: "plan-t-prim-200",
  style: {"font-size":"13px"}
}
const _hoisted_8 = {
  class: "plan-t-sec-500",
  style: {"font-size":"15px"}
}
const _hoisted_9 = {
  key: 1,
  class: "font-bold"
}
const _hoisted_10 = {
  key: 2,
  class: "font-bold"
}
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { key: 3 }
const _hoisted_13 = {
  key: 0,
  class: "load-card-state-footer justify-center"
}
const _hoisted_14 = { class: "flex justify-between items-center plan-gap-xs" }
const _hoisted_15 = {
  key: 1,
  class: "load-card-state-footer justify-center"
}
const _hoisted_16 = { class: "flex justify-center items-center plan-gap-xs rounded-full" }
const _hoisted_17 = { class: "plan-text-sm" }
const _hoisted_18 = {
  class: "flex flex-col plan-gap-xs overflow-y-auto",
  style: {"max-height":"300px"}
}
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_load_alerts_info = _resolveComponent("load-alerts-info")!
  const _component_load_detail = _resolveComponent("load-detail")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_Popper = _resolveComponent("Popper")!
  const _component_plan_avatar = _resolveComponent("plan-avatar")!
  const _component_popper = _resolveComponent("popper")!
  const _component_load_first_two_ranking_info = _resolveComponent("load-first-two-ranking-info")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onClick('state'))),
    class: _normalizeClass(["load-card-state", _ctx.loadCardStateBackground])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["load-card-state-options", _ctx.load.totalClaims > 0 ? 'justify-between' : 'justify-end'])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.load.totalClaims > 0)
          ? (_openBlock(), _createBlock(_component_load_alerts_info, {
              key: 0,
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
              "load-id": _ctx.load.id
            }, null, 8, ["load-id"]))
          : _createCommentVNode("", true),
        (_ctx.showUiComponents)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.showNewDetail)
                ? (_openBlock(), _createBlock(_component_load_detail, {
                    key: 0,
                    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showNewDetail = false)),
                    "load-id": _ctx.load.id
                  }, null, 8, ["load-id"]))
                : _createCommentVNode("", true),
              _createVNode(_component_plan_button, {
                onClick: _withModifiers(_ctx.onClickShowNewDetail, ["stop"]),
                type: "success",
                label: "New detail",
                class: "plan-text-xs",
                size: "sm"
              }, null, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_Popper, {
        arrow: "",
        hover: "",
        placement: "left"
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.onOptionsClick('createTemplate')), ["stop"]))
            }, [
              _createVNode(_component_plan_button, {
                type: "white",
                label: _ctx.$t('create-template'),
                "justify-align": "justify-start",
                size: "sm"
              }, null, 8, ["label"])
            ]),
            (_ctx.state !== 'CANCELED')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.onOptionsClick('modify')), ["stop"]))
                }, [
                  _createVNode(_component_plan_button, {
                    type: "white",
                    label: _ctx.$t('modify'),
                    "justify-align": "justify-start",
                    size: "sm"
                  }, null, 8, ["label"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.onOptionsClick('clone')), ["stop"]))
            }, [
              _createVNode(_component_plan_button, {
                type: "white",
                label: _ctx.$t('clone'),
                "justify-align": "justify-start",
                size: "sm"
              }, null, 8, ["label"])
            ]),
            (_ctx.state !== 'CANCELED')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.onOptionsClick('cancel')), ["stop"]))
                }, [
                  _createVNode(_component_plan_button, {
                    type: "white",
                    label: _ctx.$t('cancel'),
                    "justify-align": "justify-start",
                    size: "sm"
                  }, null, 8, ["label"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.state !== 'CANCELED')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.onOptionsClick('delete')), ["stop"]))
                }, [
                  _createVNode(_component_plan_button, {
                    type: "white",
                    label: _ctx.$t('delete'),
                    "justify-align": "justify-start",
                    size: "sm"
                  }, null, 8, ["label"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_plan_button_icon, {
            onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"])),
            icon: "options",
            type: "transparent-gray-800"
          })
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.stateTitle), 1),
      (_ctx.load.fillRCAlert)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('fill-rc-alert')), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.state === 'QUOTING')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["w-full flex items-center plan-t-prim-500", _ctx.load.shipment.bestBids.length > 0 ? 'justify-between' : 'justify-center'])
          }, [
            (_ctx.isQuotingUnassigned)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openDetail && _ctx.openDetail(...args)), ["stop"])),
                  class: "flex flex-col text-center"
                }, [
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('message-no-offers-accepted-by-tenders-1')), 1),
                  _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('message-no-offers-accepted-by-tenders-2')), 1)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.isQuotingUnassigned)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.load.shipment.bestBids.length) + " " + _toDisplayString(_ctx.$t('bids')), 1))
              : _createCommentVNode("", true),
            (!_ctx.isQuotingUnassigned)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.bestBid), 1))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.isCarrierFacade.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_plan_avatar, {
              "user-name": _ctx.isCarrierFacade.company,
              logo: _ctx.isCarrierFacade.logo,
              "max-characters": 11
            }, null, 8, ["user-name", "logo"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.chatsToShow.chats.length > 0 && !_ctx.isCarrierFacade.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            (_ctx.chatsToShow.chats.length === 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_plan_avatar, {
                      "user-name": _ctx.chatsToShow.chats[0].company,
                      logo: _ctx.chatsToShow.chats[0].logo,
                      "max-characters": 11
                    }, null, 8, ["user-name", "logo"]),
                    _createElementVNode("div", {
                      onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.openChat(_ctx.chatsToShow.chats[0])), ["stop"]))
                    }, [
                      _createVNode(_component_plan_button_icon, {
                        icon: "chat",
                        type: "transparent-gray-800",
                        "with-notification": !_ctx.chatsToShow.chats[0].readed
                      }, null, 8, ["with-notification"])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.chatsToShow.chats.length > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createVNode(_component_popper, {
                    hover: "",
                    placement: "left"
                  }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", _hoisted_18, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatsToShow.chats, (chat) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: chat,
                            class: "flex justify-between items-center plan-gap-xs"
                          }, [
                            _createVNode(_component_plan_avatar, {
                              "user-name": chat.company,
                              "max-characters": 11
                            }, null, 8, ["user-name"]),
                            _createElementVNode("div", {
                              onClick: _withModifiers(($event: any) => (_ctx.openChat(chat)), ["stop"])
                            }, [
                              _createVNode(_component_plan_button_icon, {
                                icon: "chat",
                                type: "transparent-gray-800",
                                "with-notification": !chat.readed
                              }, null, 8, ["with-notification"])
                            ], 8, _hoisted_19)
                          ]))
                        }), 128))
                      ])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('has-several-chats')), 1),
                        _createVNode(_component_plan_button_icon, {
                          "with-notification": _ctx.chatsToShow.unread,
                          icon: "chat",
                          type: "transparent-gray-800"
                        }, null, 8, ["with-notification"])
                      ])
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.load.status === 'CONFIRMED' && _ctx.load.hasRanking)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createVNode(_component_load_first_two_ranking_info, {
              "load-id": _ctx.load.laneRFP.id,
              chats: _ctx.chats
            }, null, 8, ["load-id", "chats"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}