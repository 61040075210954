import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_tabs = _resolveComponent("plan-tabs")!
  const _component_load_detail_quote_bids = _resolveComponent("load-detail-quote-bids")!
  const _component_load_detail_quote_directly_assign = _resolveComponent("load-detail-quote-directly-assign")!
  const _component_load_detail_quote_partners = _resolveComponent("load-detail-quote-partners")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_tabs, {
      onChangeTab: _ctx.changeTab,
      tabs: _ctx.tabs,
      "show-badges": false,
      size: "plan-text-lg"
    }, null, 8, ["onChangeTab", "tabs"]),
    (_ctx.selectedTab === 0)
      ? (_openBlock(), _createBlock(_component_load_detail_quote_bids, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.selectedTab === 1)
      ? (_openBlock(), _createBlock(_component_load_detail_quote_directly_assign, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.selectedTab === 2)
      ? (_openBlock(), _createBlock(_component_load_detail_quote_partners, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}