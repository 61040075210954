
import { defineComponent, computed, ref } from 'vue';
import moment from 'moment';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import LoadTimeline from '@/components/loads/LoadTimeline.vue';
import { useStore } from 'vuex';
import LoadDetailMap from '@/components/loads/detail/LoadDetailMap.vue';

export default defineComponent({
  name: 'LoadDetailHeader',
  components: { LoadDetailMap, LoadTimeline, PlanButton, PlanIcon },
  setup (props, { emit }) {
    const store = useStore()
    const showMap = ref(false)

    const loadData = computed(() => {
      const loadInfo = store.getters['LoadsStore/getLoadInfo']
      let distanceUnits = store.getters['UserStore/getRegion'] === 'EUROPE' ? ' km' : ' mi'
      let goodsTypeUnits = store.getters['UserStore/getRegion'] === 'EUROPE' ? ' kg' : ' lb'
      let goodsTypeResult = ''

      if (loadInfo.load?.commodityMasters && loadInfo.load?.commodityMasters.length > 0) {
        loadInfo.load?.commodityMasters?.forEach(function(commodity: any) {
          if (goodsTypeResult === '') {
            goodsTypeResult = commodity.commodityName + ' ' + commodity.weight + goodsTypeUnits
          } else {
            goodsTypeResult = goodsTypeResult + ', ' + commodity.commodityName + ' ' + commodity.weight + goodsTypeUnits
          }
        })
      }

      return {
        identifier: loadInfo.load.identifier,
        stops: loadInfo.load.stops,
        initialPickUp: loadInfo.load.stops[0],
        finalDelivery: loadInfo.load.stops[loadInfo.load.stops.length - 1],
        distance: loadInfo.load?.shipment?.totalDistance + distanceUnits,
        vehicle: loadInfo.load?.trailerType?.description,
        goodsType: goodsTypeResult
      }
    })

    return {
      loadData,
      showMap,
      moment
    }
  }
})
