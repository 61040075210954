
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'CarrierAuthentication',
  components: { PlanIcon },
  props: {
    id: { type: String, required: false, default: null },
    hash: { type: String, required: false, default: null },
    code: { type: String, required: false, default: null },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const translate = useI18n();

    moment.locale(translate.locale.value);

    const getData = () => {
      store.dispatch('CarrierStore/getLoadInfoForCarrier', props.id).then((response) => {
        const actualState = store.getters['CarrierStore/getLoadData'].status;
        if (actualState === 'NORMAL_QUOTING' || actualState === 'CONFIRMED') {
          router.push({ name: 'CarrierLoadDetails', params: { id: props.id } });
        } else if (actualState === 'ACCEPTED') {
          router.push({ name: 'CarrierLoadAccepted', params: { id: props.id } });
        } else if (
          actualState === 'ASSIGNED' ||
          actualState === 'IN_PROGRESS' ||
          actualState === 'FINISHED'
        ) {
          router.push({ name: 'CarrierLoadAssigned', params: { id: props.id } });
        } else {
          router.push({ name: 'CarrierLoadDetails', params: { id: props.id } });
        }
      });
    };

    const loginUser = () => {
      store
        .dispatch('UserStore/hashValidation', {
          hashId: props.hash,
          secureCode: props.code,
        })
        .then(() => {
          if (route.name === 'DriverAuthentication') {
            store.commit('CarrierStore/setIsDriver', true);
          }
          store.dispatch('PusherStore/initializePusher');
          getData();
        });
    };

    loginUser();
  },
});
