
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import Spin from '@/components/spin/AnimateBlackSpin.vue';
// Mixins
import CountryMixins from '@/services/country/_mixins/index';

export default {
  name: 'PlanCountrySelector',
  components: { Spin },
  mixins: [BaseComponent, CountryMixins],
  emits: ['callbackClick'],
  props: {
    country: {
      type: Object,
      required: true,
    },
    dataCountries: {
      type: Array,
      default: () => [{}],
      required: true,
    },
    placement: {
      type: String,
      default: 'bottom',
      required: false,
    },

    
    countrySelectorClasses: {
      type: String,
      default:
        'inline-flex border border-gray-300 rounded-none bg-white items-center text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:ring-blue-500 focus:border-blue-500 block px-4 py-2',
    },
  },
  setup(props, ctx) {
    /** Global */
    const store = useStore();
    const countrySelected = ref(null);
    const countryValue = ref(props.country);

    /** Computed */
    const currentCountry = computed(() => {
      return store.getters['CountryStore/getCurrentCountry'];
    });

    /** Methods */
    const getDataCountriesFiltered = () => {
      let localCountryValue = countryValue?.value;
      if (localCountryValue?.length > 0) {
        localCountryValue = props.dataCountries.find((item) => localCountryValue === item.iso);
      }

      let country = countrySelected?.value || localCountryValue;

      if (!country?.region || !country?.iso) {
        country = currentCountry.value;
      }

      return props.dataCountries.filter(
        (item) => item?.region?.name.includes(country?.region?.name) && country.iso !== item.iso,
      );
    };

    const handleClick = (close, country) => {
      countrySelected.value = country;
      ctx.emit('callbackClick', country);
      close();
    };

    const currentRegion = computed(() => {
      return store.getters['UserStore/getRegion'];
    });

    /** Watchers */
    watch(
      () => props.country,
      (newValue) => {
        if (newValue) {
          countryValue.value = newValue;
          getDataCountriesFiltered();
        }
      },
    );

    return {
      countrySelected,
      countryValue,
      currentRegion,
      getDataCountriesFiltered,
      handleClick,
    };
  },
};
