import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"padding":"4px"} }
const _hoisted_2 = { class: "flex justify-start items-center plan-gap-sm" }
const _hoisted_3 = { class: "flex justify-center items-center border plan-border-prim-200 rounded-full plan-p-sm" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "plan-t-prim-100 plan-t-xxs" }
const _hoisted_7 = { style: {"padding":"4px"} }
const _hoisted_8 = { class: "flex justify-end items-center" }
const _hoisted_9 = { class: "flex flex-col plan-gap-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_popper = _resolveComponent("popper")!
  const _component_plan_default_table = _resolveComponent("plan-default-table")!

  return (_openBlock(), _createBlock(_component_plan_default_table, {
    items: _ctx.items,
    headers: []
  }, {
    default: _withCtx((props) => [
      _createElementVNode("td", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_plan_icon, {
              name: "checked-document",
              "icon-color": "#4F5264",
              "is-svg": ""
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(props.item.displayName), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.showDate(props.item.createdDate)), 1)
          ])
        ])
      ]),
      _createElementVNode("td", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_popper, {
            placement: "left",
            offsetSkid: "0",
            offsetDistance: "0"
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_plan_button, {
                  onOnClick: ($event: any) => (_ctx.viewDocumentUploaded(props.item.id, props.item.entityClass)),
                  type: "cancel",
                  label: _ctx.$t('show'),
                  size: "sm",
                  "justify-align": "flex-start"
                }, null, 8, ["onOnClick", "label"]),
                _createVNode(_component_plan_button, {
                  onOnClick: ($event: any) => (_ctx.download(props.item)),
                  type: "cancel",
                  label: _ctx.$t('download'),
                  size: "sm",
                  "justify-align": "flex-start"
                }, null, 8, ["onOnClick", "label"]),
                _createVNode(_component_plan_button, {
                  type: "cancel",
                  label: _ctx.$t('share'),
                  size: "sm",
                  "justify-align": "flex-start"
                }, null, 8, ["label"])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_plan_button_icon, {
                icon: "options",
                type: "transparent-gray-800"
              })
            ]),
            _: 2
          }, 1024)
        ])
      ])
    ]),
    _: 1
  }, 8, ["items"]))
}