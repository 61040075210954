import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "plan-table" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        (_ctx.headers.length > 0)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, idx) => {
                return (_openBlock(), _createElementBlock("th", {
                  key: idx,
                  class: _normalizeClass([
                header.align === 'left' ? 'text-left' : '',
                header.align === 'center' ? 'text-center' : '',
                header.align === 'right' ? 'text-right' : '',
              ])
                }, [
                  _createElementVNode("div", {
                    onClick: ($event: any) => (_ctx.selectHeaderOrder(header)),
                    class: _normalizeClass(["w-full flex justify-center items-center plan-gap-xs", {'plan-t-sec-500': _ctx.orderBy === header.value, 'cursor-pointer': header.showOrder }])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(header.label), 1),
                    (header.showOrder && header.value === _ctx.orderBy)
                      ? (_openBlock(), _createBlock(_component_plan_icon, {
                          key: 0,
                          name: _ctx.orderType === 'DESC' ? 'chevron-down':'chevron-up',
                          "is-svg": ""
                        }, null, 8, ["name"]))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_4)
                ], 2))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("tbody", null, [
        _renderSlot(_ctx.$slots, "table-content")
      ])
    ])
  ]))
}