import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col plan-gap-xl" }
const _hoisted_2 = { class: "flex justify-between items-end plan-gap-xl" }
const _hoisted_3 = { class: "w-full flex justify-end items-center plan-gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_input_text = _resolveComponent("plan-input-text")!
  const _component_plan_select = _resolveComponent("plan-select")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_default_modal = _resolveComponent("plan-default-modal")!

  return (_openBlock(), _createBlock(_component_plan_default_modal, {
    title: "Billing data",
    size: "sm-auto"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_plan_input_text, {
          "model-value": "",
          label: "Company",
          placeholder: "Enter your company name"
        }),
        _createVNode(_component_plan_input_text, {
          "model-value": "",
          label: "Email",
          placeholder: "Enter your email"
        }),
        _createVNode(_component_plan_input_text, {
          "model-value": "",
          label: "ID",
          placeholder: "Enter your ID"
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_plan_select, {
            value: "",
            items: [],
            placeholder: "Select your country",
            label: "Country"
          }),
          _createVNode(_component_plan_input_text, {
            "model-value": "",
            label: "ZIP",
            placeholder: "Enter your ZIP"
          })
        ]),
        _createVNode(_component_plan_select, {
          value: "",
          items: [],
          placeholder: "Select your State",
          label: "State"
        }),
        _createVNode(_component_plan_input_text, {
          "model-value": "",
          label: "City",
          placeholder: "Enter your city"
        }),
        _createVNode(_component_plan_input_text, {
          "model-value": "",
          label: "Address",
          placeholder: "Enter your address"
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_plan_button, {
            type: "white",
            label: "Cancel"
          }),
          _createVNode(_component_plan_button, {
            type: "primary",
            label: "Update"
          })
        ])
      ])
    ]),
    _: 1
  }))
}