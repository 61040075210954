
import { defineComponent, computed, onMounted, ref } from 'vue';
import CarrierSidebar from '@/components/carrier/CarrierSidebar.vue';
import PlanCarrierSizeCalculator from '@/modules/carrierViews/PlanCarrierSizeCalculator.vue';
import { useStore } from 'vuex';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanCarrierNotifyIncidence from '@/components/carrier/_assigned/PlanCarrierNotifiyIncidence.vue';
import PlanCarrierAddAdditionalFee from '@/components/carrier/_assigned/PlanCarrierAddAdditionalFee.vue';
import PlanCarrierUpdatePosition from '@/components/carrier/_assigned/PlanCarrierUpdatePosition.vue';
import SidebarChat from '@/components/sidebar/ChatSidebar.vue';
import PlanCarrierDefaultChat from '@/components/defaults/PlanCarrierDefaultChat.vue';
import { is } from '@vee-validate/rules';
import CarrierResume from '@/modules/carrier/modals/CarrierResumen.vue';
import PlanCarrierShareLoad from '@/components/carrier/PlanCarrierShareLoad.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'PlanDefaultCarrierView',
  methods: { is },
  components: { PlanButtonIcon, PlanCarrierShareLoad, CarrierResume, SidebarChat, PlanCarrierUpdatePosition, PlanCarrierAddAdditionalFee, PlanCarrierNotifyIncidence, PlanCarrierSizeCalculator, CarrierSidebar },
  props: {
    sidebar: { type: Boolean, required: false, default: true },
    showHeader: { type: Boolean, required: false, default: true },
    showSubheader: { type: Boolean, required: false, default: true },
    showFooter: { type: Boolean, required: false, default: false },
    showAddLocation: { type: Boolean, required: false, default: false },
    showAddAdditionalFees: { type: Boolean, required: false, default: false },
    showNotifyIncidence: { type: Boolean, required: false, default: false },
    showUpdateData: { type: Boolean, required: false, default: false },
    defaultContentWidth: { type: String, required: false, default: '' },
  },
  setup(props, { emit, slots }) {
    const router = useRouter()
    const store = useStore()

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const isDriver = computed(() => {
      return store.getters['CarrierStore/getIsDriver'];
    });


    const contentStyle = computed(() => {
      let contentWidth = '100%'

      if (actualWidth.value > 640 && actualWidth.value <= 1536) {
        contentWidth = '600px'
      } else if (actualWidth.value > 1536) {
        contentWidth = '1200px'
      }

      if(props.defaultContentWidth) {
        contentWidth = props.defaultContentWidth;
      }

      return {
        width: contentWidth
      }
    })

    const goToAcceptance = () => {
      router.push({ name: 'CarrierLoadAcceptation', params: { id: store.getters['CarrierStore/getLoadData'].id }})
    }

    const hideNotifications = () => {
      store.commit('CarrierStore/setShowNotifications', false)
    }

    const hideShareLoad = () => {
      store.commit('CarrierStore/setShowShareLoad', false)
    }

    const isOpenChat = computed(() => {
      return store.state.GlobalStore.isOpenChat;
    })

    const showNotifications = computed(() => {
      return store.getters['CarrierStore/getShowNotifications']
    })

    const showShareLoad = computed(() => {
      return store.getters['CarrierStore/getShowShareLoad']
    })

    return {
      actualWidth,
      contentStyle,
      goToAcceptance,
      hideNotifications,
      hideShareLoad,
      isOpenChat,
      isDriver,
      showNotifications,
      showShareLoad
    }
  }
})
