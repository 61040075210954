
import { defineComponent, reactive, computed, toRefs } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanUpdateMultipleLanesStartDate from '@/components/rfp/PlanUpdateMultipleLanesComponents/PlanUpdateMultipleLanesStartDate.vue';
import PlanUpdateMultipleLanesEndDate from '@/components/rfp/PlanUpdateMultipleLanesComponents/PlanUpdateMultipleLanesEndDate.vue';
import PlanUpdateMultipleLanesLoadsFrequency from '@/components/rfp/PlanUpdateMultipleLanesComponents/PlanUpdateMultipleLanesLoadsFrequency.vue';
import PlanUpdateMultipleLanesWeight from '@/components/rfp/PlanUpdateMultipleLanesComponents/PlanUpdateMultipleLanesWeight.vue';
import PlanUpdateMultipleLanesCommodity from '@/components/rfp/PlanUpdateMultipleLanesComponents/PlanUpdateMultipleLanesCommodity.vue';
import { useStore } from 'vuex';
import PlanUpdateMultipleLanesVehicle from '@/components/rfp/PlanUpdateMultipleLanesComponents/PlanUpdateMultipleLanesVehicle.vue';
import PlanUpdateMultipleLanesSpecialRequirements from '@/components/rfp/PlanUpdateMultipleLanesComponents/PlanUpdateMultipleLanesSpecialRequirements.vue';
import PlanUpdateMultipleStopLocation from '@/components/rfp/PlanUpdateMultipleLanesComponents/PlanUpdateMultipleLanesStopLocation.vue';
import PlanUpdateMultipleLanesStops from '@/components/rfp/PlanUpdateMultipleLanesComponents/PlanUpdateMultipleLanesStops.vue';

export default defineComponent({
  name: 'PlanUpdateMultipleLanes',
  components: {
    PlanUpdateMultipleLanesStops,
    PlanUpdateMultipleStopLocation,
    PlanUpdateMultipleLanesSpecialRequirements,
    PlanUpdateMultipleLanesVehicle,
    PlanUpdateMultipleLanesCommodity,
    PlanUpdateMultipleLanesWeight,
    PlanUpdateMultipleLanesLoadsFrequency,
    PlanUpdateMultipleLanesEndDate,
    PlanUpdateMultipleLanesStartDate,
    PlanButton,
    PlanDefaultModal,
  },
  emits: ['close'],
  props: {
    projectId: { type: Number, required: true, default: 0 },
    currentProject: { type: Object, required: true },
    lanesToUpdate: { type: Array, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive({
      additionalStops: [],
      commodity: {
        isActive: false,
        commodity: '',
      },
      deliveryLocation: {
        isActive: false,
        locationObject: {
          googlePlaceId: '',
          lat: 0,
          long: 0,
          zip: '-',
          city: '-',
          state: {
            iso: '-',
            country: {
              iso: '-',
            },
            isoGoogle: '-',
          },
        },
        place: '',
      } as any,
      endDate: {
        isActive: false,
        value: '',
      },
      lanes: JSON.parse(JSON.stringify(props.lanesToUpdate)),
      loadingPost: false,
      loadsFrequency: {
        isActive: false,
        frequency: 0,
        frequencyType: 'weekly',
      },
      pickupLocation: {
        isActive: false,
        locationObject: {
          googlePlaceId: '',
          lat: 0,
          long: 0,
          zip: '-',
          city: '-',
          state: {
            iso: '-',
            country: {
              iso: '-',
            },
            isoGoogle: '-',
          },
        },
        place: '',
      } as any,
      showAdditionalStops: false,
      specialRequirements: {
        isActive: false,
        teamDriver: false,
        bondedPermission: false,
        tsa: false,
        twicCard: false,
        hazardousLoad: false,
        specialPermits: false,
        specialPermitsDescription: '',
      },
      startDate: {
        isActive: false,
        value: '',
      },
      vehicle: {
        isActive: false,
        vehicle: '',
        vehicleName: '',
        isRefrigerated: {
          value: false,
          min: null,
          max: null,
        },
      },
      weight: {
        isActive: false,
        weight: 0,
      },
    });

    const changeAdditionalStops = (additionalStops: any) => {
      data.additionalStops = additionalStops;
    };

    const changeCommodity = (commodity: any) => {
      data.commodity = commodity;
    };

    const changeDeliveryLocation = (deliveryLocation: any) => {
      data.deliveryLocation = deliveryLocation;
    };

    const changeEndDate = (endDate: any) => {
      data.endDate = endDate;
    };

    const changeLoadsFrequency = (loadsFrequency: any) => {
      data.loadsFrequency = loadsFrequency;
    };

    const changePickupLocation = (pickupLocation: any) => {
      data.pickupLocation = pickupLocation;
    };

    const changeSpecialRequirements = (specialRequirements: any) => {
      data.specialRequirements = specialRequirements;
    };

    const changeStartDate = (startDate: any) => {
      console.log('changeStartDate: ', startDate);
      data.startDate = startDate;
    };

    const changeVehicle = (vehicle: any) => {
      data.vehicle = vehicle;
    };

    const changeWeight = (weight: any) => {
      data.weight = weight;
    };

    const close = () => {
      emit('close');
    };

    const updatedLines = () => {
      let result: Array<any> = props.lanesToUpdate.slice();
      result.forEach((load: any) => {
        if (data.additionalStops.length > 0) {
          if (load.loadTemplate.stops.length === 2) {
            data.additionalStops.forEach((additionalStop: any) => {
              load.loadTemplate.stops.push(additionalStop);
            });
          } else {
            let result: Array<any> = [];
            result.push(load.loadTemplate.stops[0]);
            result.push(load.loadTemplate.stops[1]);
            data.additionalStops.forEach((additionalStop: any) => {
              result.push(additionalStop);
            });
            load.loadTemplate.stops = result;
          }
        }

        if (data.pickupLocation.isActive) {
          load.loadTemplate.stops.forEach((stop: any) => {
            if (stop.type === 'PICKUP_INITIAL') {
              stop.place = data.pickupLocation.place;
              stop.location.googlePlaceId =
                data.pickupLocation.locationObject.location.googlePlaceId;
              stop.location.city = data.pickupLocation.locationObject.location.city;
              stop.location.lat = data.pickupLocation.locationObject.location.lat;
              stop.location.long = data.pickupLocation.locationObject.location.long;
              stop.location.zip = data.pickupLocation.locationObject.location.zip;
              stop.location.state.iso = data.pickupLocation.locationObject.location.state.iso;
              stop.location.state.isoGoogle =
                data.pickupLocation.locationObject.location.state.isoGoogle;

              if (!stop.location.state.country) {
                stop.location.state.country = { iso: '-' };
              }
              stop.location.state.country.iso =
                data.pickupLocation.locationObject.location.state.country.iso;

              if (data.pickupLocation.locationObject.airportStop) {
                stop.airportStop = true;
                stop.portStop = false;
              }
              if (data.pickupLocation.locationObject.portStop) {
                stop.portStop = true;
                stop.airportStop = false;
              }

              stop.executionWindowStartTime =
                data.pickupLocation.locationObject.executionWindowStartTime;
              stop.executionWindowEndTime =
                data.pickupLocation.locationObject.executionWindowEndTime;

              stop.companyName = data.pickupLocation.locationObject.companyName;
              stop.location.address = data.pickupLocation.locationObject.address;
              stop.lumperFee = data.pickupLocation.locationObject.lumperFee;
              stop.lumperFeePrice = data.pickupLocation.locationObject.lumperFeePrice;
              stop.observations = data.pickupLocation.locationObject.observations;
              stop.poNumber = data.pickupLocation.locationObject.poNumber;
              stop.phoneNumber = data.pickupLocation.locationObject.phoneNumber;
              stop.phonePrefix = data.pickupLocation.locationObject.phonePrefix;
              stop.contact = data.pickupLocation.locationObject.contactName;
              stop.executionWindowStartTime =
                data.pickupLocation.locationObject.executionWindowStartTime;
              stop.executionWindowEndTime =
                data.pickupLocation.locationObject.executionWindowEndTime;

              if (data.pickupLocation && data.pickupLocation.locationObject.warehouseTemplate) {
                if (!stop.warehouseTemplate) {
                  stop.warehouseTemplate = {
                    id: data.pickupLocation.locationObject.warehouseTemplate.id,
                  };
                }
              }
            }
          });
        }

        if (data.deliveryLocation.isActive) {
          load.loadTemplate.stops.forEach((stop: any) => {
            if (stop.type === 'DELIVERY_FINAL') {
              stop.place = data.deliveryLocation.place;
              stop.location.googlePlaceId =
                data.deliveryLocation.locationObject.location.googlePlaceId;
              stop.location.city = data.deliveryLocation.locationObject.location.city;
              stop.location.lat = data.deliveryLocation.locationObject.location.lat;
              stop.location.long = data.deliveryLocation.locationObject.location.long;
              stop.location.zip = data.deliveryLocation.locationObject.location.zip;
              stop.location.state.iso = data.deliveryLocation.locationObject.location.state.iso;
              stop.location.state.isoGoogle =
                data.deliveryLocation.locationObject.location.state.isoGoogle;

              if (!stop.location.state.country) {
                stop.location.state.country = { iso: '-' };
              }
              stop.location.state.country.iso =
                data.deliveryLocation.locationObject.location.state.country.iso;

              if (data.deliveryLocation.locationObject.airportStop) {
                stop.airportStop = true;
                stop.portStop = false;
              }
              if (data.deliveryLocation.locationObject.portStop) {
                stop.portStop = true;
                stop.airportStop = false;
              }

              stop.executionWindowStartTime =
                data.deliveryLocation.locationObject.executionWindowStartTime;
              stop.executionWindowEndTime =
                data.deliveryLocation.locationObject.executionWindowEndTime;

              stop.companyName = data.deliveryLocation.locationObject.companyName;
              stop.location.address = data.deliveryLocation.locationObject.address;
              stop.lumperFee = data.deliveryLocation.locationObject.lumperFee;
              stop.lumperFeePrice = data.deliveryLocation.locationObject.lumperFeePrice;
              stop.observations = data.deliveryLocation.locationObject.observations;
              stop.poNumber = data.deliveryLocation.locationObject.poNumber;
              stop.phoneNumber = data.deliveryLocation.locationObject.phoneNumber;
              stop.phonePrefix = data.deliveryLocation.locationObject.phonePrefix;
              stop.contact = data.deliveryLocation.locationObject.contactName;
              stop.executionWindowStartTime =
                data.deliveryLocation.locationObject.executionWindowStartTime;
              stop.executionWindowEndTime =
                data.deliveryLocation.locationObject.executionWindowEndTime;

              if (data.deliveryLocation && data.deliveryLocation.locationObject.warehouseTemplate) {
                if (!stop.warehouseTemplate) {
                  stop.warehouseTemplate = {
                    id: data.deliveryLocation.locationObject.warehouseTemplate.id,
                  };
                }
              }
            }
          });
        }

        if (data.specialRequirements.isActive) {
          load.loadTemplate.specialRequirement.teamDriver = data.specialRequirements.teamDriver;
          load.loadTemplate.specialRequirement.bondedPermission =
            data.specialRequirements.bondedPermission;
          load.loadTemplate.specialRequirement.tsa = data.specialRequirements.tsa;
          load.loadTemplate.specialRequirement.TWICCard = data.specialRequirements.twicCard;
          load.loadTemplate.specialRequirement.hazardousLoad =
            data.specialRequirements.hazardousLoad;
          load.loadTemplate.specialRequirement.specialPermissions =
            data.specialRequirements.specialPermits;
          if (data.specialRequirements.specialPermits)
            load.loadTemplate.specialRequirement.specialPermissionsDescription =
              data.specialRequirements.specialPermitsDescription;
        }

        if (data.vehicle.isActive) {
          load.loadTemplate.trailerType.id = data.vehicle.vehicle;
          load.loadTemplate.trailerType.description = data.vehicle.vehicleName;
          if (data.vehicle.isRefrigerated.value) {
            load.loadTemplate.minTemp = data.vehicle.isRefrigerated.min;
            load.loadTemplate.maxTemp = data.vehicle.isRefrigerated.max;
          }
        }

        if (data.weight.isActive) {
          load.loadTemplate.commodityMaster.weight = data.weight.weight;
          if (load?.loadTemplate?.commodityMasters?.length > 0) {
            load.loadTemplate?.commodityMasters?.forEach((cm: any) => {
              cm.weight = data.weight.weight;
            });
          }
        }

        if (data.commodity.isActive) {
          load.loadTemplate.commodityMaster.commodityName = data.commodity.commodity;
          if (load.loadTemplate?.commodityMasters?.length > 0) {
            load.loadTemplate.commodityMasters.forEach((cm: any) => {
              cm.commodityName = data.commodity.commodity;
            });
          }
        }

        if (data.loadsFrequency.isActive) {
          load.loadsFrequency = data.loadsFrequency.frequency;
          load.loadsFrequencyPeriod = data.loadsFrequency.frequencyType;
        }

        if (data.startDate.isActive) {
          console.log('data.startDate: ', data.startDate);
          load.loadTemplate.stops.forEach((stop: any) => {
            if (stop.type === 'PICKUP_INITIAL') stop.executionDate = data.startDate.value;
          });
        }

        if (data.endDate.isActive) {
          load.loadTemplate.stops.forEach((stop: any) => {
            if (stop.type === 'DELIVERY_FINAL') stop.executionDate = data.endDate.value;
          });
        }
      });
      console.log('updatedLines: ', result);

      emit('close');
      return result;
    };

    return {
      ...toRefs(data),
      changeAdditionalStops,
      changeCommodity,
      changeDeliveryLocation,
      changeEndDate,
      changeLoadsFrequency,
      changePickupLocation,
      changeSpecialRequirements,
      changeStartDate,
      changeVehicle,
      changeWeight,
      close,
      updatedLines,
    };
  },
});
