
import { defineComponent } from 'vue';
import PlanDefaultCarrierView from '@/components/defaults/PlanDefaultCarrierView.vue';
import PlanCarrierRfpQuoteHeader from '@/components/carrier/_rfpQuote/PlanCarrierRfpQuoteHeader.vue';
import PlanCarrierRfpQuoteSubHeader from '@/components/carrier/_rfpQuote/PlanCarrierRfpQuoteSubheader.vue';
import PlanCarrierRfpQuotingPerLane from '@/components/carrier/_rfpQuote/PlanCarrierRfpQuotingPerLane.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useRouter } from 'vue-router';
import PlanCarrierLoadHeaderNotification from '@/components/carrier/PlanCarrierLoadHeaderNotification.vue';

export default defineComponent({
  name: 'CarrierRfpQuote',
  components: { PlanCarrierLoadHeaderNotification, PlanButtonIcon, PlanButton, PlanCarrierRfpQuotingPerLane, PlanCarrierRfpQuoteSubHeader, PlanCarrierRfpQuoteHeader, PlanDefaultCarrierView },
  props: {
    id: { type: Number, required: true, default: -1 }
  },
  setup () {
    const router = useRouter()

    const goToCarrierRfpQuote = () => {
      router.push({ name: 'CarrierRfpQuote', params: { id: 1 }})
    }

    return {
      goToCarrierRfpQuote
    }
  }
});
