
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'LoadDetailRightLoad',
  components: { PlanIcon },
  setup () {
    const store = useStore()

    const isSuplanting = computed(() => {
      return store.getters['UserStore/getImpersonatedUserId'];
    });

    const currentUserImpersonating = computed(() => {
      return store.getters['UserStore/getCurrentUserImpersonating'];
    });

    const currentUser = computed(() => {
      if (currentUserImpersonating.value && isSuplanting.value) {
        return currentUserImpersonating.value;
      } else {
        return store.getters['UserStore/getCurrentUser'];
      }
    });

    const goodsType = computed(() => {
      let result = ''
      let weightType = currentUser.value.company?.country?.region?.name === 'EUROPE' ? ' kg' : ' lb'
      store.getters['LoadsStore/currentLoad'].commodityMasters?.forEach(function(commodityMaster) {
        result = result.length === 0 ? result + commodityMaster.commodityName : result + ', ' + commodityMaster.commodityName
        result = result + commodityMaster.weight + weightType
      })
      return result
    })

    return {
      goodsType
    }
  }
})
