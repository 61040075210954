
import { defineComponent, ref, watch, reactive, onMounted, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import IncidencePerWeek from '@/components/tooltip/IncidencePerWeek.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
// Types
import { RFPStatus } from '@/modules/rfp/RFPTypes';
import { RankingQuoteType } from '@/modules/rfp/RFPTypes';
// Utils
import i18n from '@/resources/locales';
import { formatValoration } from '@/utils/formatString';
import { numberSuffixAndColor } from '@/utils/formatString';
import * as Routing from '@/router/Routing';

export default defineComponent({
  components: { IncidencePerWeek, PlanButton },
  mixins: [BaseComponent],
  props: {
    currentWeek: {
      type: Number,
      required: true,
    },
    laneList: Array as any,
    projectId: Number,
    status: String,
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    RFPStatus(): any {
      return RFPStatus;
    },
    rankingQuoteType(): any {
      return RankingQuoteType;
    },
  },
  setup(props, { emit }) {
    // Global
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const $t = i18n.global.t;

    // Variables

    const currentProjectId = ref(+route.params.projectId);
    const fetchDataOnHover = ref(false);
    const lanes = ref(props.laneList || []);
    const loading = ref(false as any);

    // Methods

    const cellClasses = (indexColumn, lane) => {
      const topRanking = getTopRanking(lane, indexColumn);
      const hasQuotes = lane?.computed?.quotes?.quotedAmount > 0;

      return {
        'text-blue-600':
          topRanking?.status === RFPStatus.ACCEPTING || topRanking?.status === RFPStatus.CONFIRMED,
        'text-zinc-800':
          topRanking?.status !== RFPStatus.ACCEPTING && topRanking?.status !== RFPStatus.CONFIRMED,
        'cursor-pointer': hasQuotes,
      };
    };

    const checkHasConfirmed = (ranking) => {
      const checkHasConfirmed = ranking.some((item) => item.status === 'CONFIRMED');
      return checkHasConfirmed;
    };

    const confirmRanking = (lane, bidId) => {
      loading.value = 'remind';
      store
        .dispatch('RFPStore/postRequestQuoteConfirmation', {
          quoteId: bidId,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess($t('ranking-confirm'));
          store.dispatch('RFPStore/getProjectById', {
            projectId: Number(route.params.projectId),
            page: 1,
          }),
            store.dispatch('RFPStore/getLanesByProjectId', {
              projectId: Number(route.params.projectId),
              page: 1,
            }),
            store.dispatch('RFPStore/getRankingList', {
              id: lane?.id,
            });
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError($t('error'));
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const formatQuotationValue = (status, bid) => {
      let previousSymbol = '';

      if (status === RFPStatus.ACCEPTING) {
        previousSymbol = '√';
      }

      if (status === RFPStatus.CONFIRMED) {
        previousSymbol = '√√';
      }

      return bid ? `${previousSymbol}` : '-';
    };

    const getDefaultUserImage = (name) => {
      return BaseComponent.methods?.getUserDefaultImage(name);
    };

    const getTopRanking = (lane, index) => {
      return lane?.computed?.quotes?.topRanking[index];
    };

    const handlePopperHover = () => {
      fetchDataOnHover.value = true;
    };
    const handlePopperLeave = () => {
      fetchDataOnHover.value = false;
    };

    const handleRankingClick = (lane) => {
      if (lane?.computed?.quotes?.quotedAmount > 0) {
        showRankingModal(lane);
      }
    };

    const loadsFrequencyPeriod = (frequencyPeriod, maxCapacity) => {
      let translation = '';
      switch (frequencyPeriod) {
        case 'WEEK':
          translation = $t('week-pluri', { value: maxCapacity });
          break;
        case 'MONTH':
          translation = $t('month-pluri', { value: maxCapacity });
          break;
        case 'TOTAL':
          translation = $t('total');
          break;
      }

      return translation;
    };

    const onClickDetail = (laneId) => {
      router.push({
        name: 'RFP-laneDetail',
        params: { projectId: route.params.projectId, laneId: laneId },
      });
    };

    const onClickStartPlan = (laneId) => {
      router.push({
        name: 'NewLoadRFP',
        params: { projectId: route.params.projectId, laneId: laneId },
      });
    };

    const openLaneDetail = (item) => {
      router.push({
        name: 'RFP-laneService',
        params: { projectId: item.projectId, laneId: item.laneId, id: item.loadId },
      });
    };

    const isDev = computed(() => {
      return process.env.VUE_APP_URL.includes('dev');
    });

    const revoke = (lane, quoteId) => {
      loading.value = 'revoke';
      store
        .dispatch('RFPStore/postRevokeQuote', quoteId)
        .then(() => {
          store.dispatch('RFPStore/getProjectById', {
            projectId: Number(route.params.projectId),
            page: 1,
          }),
            store.dispatch('RFPStore/getLanesByProjectId', {
              projectId: Number(route.params.projectId),
              page: 1,
            }),
            store.dispatch('RFPStore/getRankingList', {
              id: lane?.id,
            });
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError('error');
        })
        .finally(() => (loading.value = false));
    };

    const showRankingModal = (lane) => {
      emit('showRanking', lane);
    };

    // Lifecycle

    onMounted(() => {
      //
    });

    // Watchers

    watch(
      () => props.laneList,
      (newValue) => {
        lanes.value = newValue || [];
      },
    );
    return {
      isDev,
      cellClasses,
      checkHasConfirmed,
      currentProjectId,
      confirmRanking,
      fetchDataOnHover,
      formatQuotationValue,
      formatValoration,
      getDefaultUserImage,
      getTopRanking,
      handleRankingClick,
      handlePopperHover,
      handlePopperLeave,
      lanes,
      loading,
      loadsFrequencyPeriod,
      numberSuffixAndColor,
      onClickDetail,
      onClickStartPlan,
      openLaneDetail,
      revoke,
      Routing,
      showRankingModal,
    };
  },
});
