
import { defineComponent } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'LoadDetailDocumentsTable',
  components: { PlanIcon, PlanButtonIcon, PlanDefaultTable, PlanButton },
  props: {
    items: { type: Array, required: true }
  },
  setup (props, { emit }) {
    const store = useStore()

    const download = async (doc: any) => {
      const response = await store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: doc.id,
        class: doc.entityClass,
      });
      const fileName = `${doc.displayName}#${doc.id}`;
      let file = document.createElement('a');
      file.href = URL.createObjectURL(response);
      file.download = `documents-${fileName}`;
      file.click();
    }

    const showDate = (date: string) => {
      return moment(date).format('DD MMM YYYY HH:MM')
    }

    const viewDocumentUploaded = async (id: any, entityClass: any) => {
      const result = await store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: id,
        class: entityClass,
      });

      const url = URL.createObjectURL(result);
      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-${''}`;
        const blob = new Blob([result], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    }

    return {
      download,
      showDate,
      viewDocumentUploaded
    }
  }
})
