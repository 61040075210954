
import { defineComponent } from 'vue';
// Base
import BaseComponent from '@/base/BaseComponent';

export default defineComponent({
  name: 'assignedLaneComponent',
  mixins: [BaseComponent],
  beforeCreate() {
    this.$store.dispatch('PusherStore/initializePusher');
  },
  async created() {
    this.fetchAllInfo();
  },
  computed: {
    projectId(): any {
      return this.$route.params.id;
    },
  },
  methods: {
    async fetchAllInfo() {
      const id = this.$route.params.id;
      const hash: any = this.$route.params.hash;
      const code: any = this.$route.params.code;
      if (hash && code) {
        await this.$store
          .dispatch('UserStore/hashValidation', {
            hashId: hash,
            secureCode: code,
          })
          .then(() => {
            this.$store.dispatch('PusherStore/initializePusher');
            this.$store.dispatch('RFPStore/postCarrierConfirm', this.$route.params.quoteId);
          })
          .catch((err) => {
            BaseComponent.methods?.showToastError(err?.response?.data?.title)
            this.$store.commit('UserStore/setShowHash', true);
          });
      }
    },
    routerRequestForQuote() {
      const projectId = this.projectId;
      this.$router.push({ name: 'RequestForQuoteCarrier', params: { projectId } });
    },
  },
});
