
declare var google: any;

interface LatLng {
  lat: number;
  lng: number;
}

interface Waypoint {
  location: LatLng;
  stopover: boolean;
}

interface DirectionsRequest {
  origin: LatLng;
  destination: LatLng;
  travelMode: string;
  waypoints?: Waypoint[];
}

import { defineComponent } from 'vue';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

export default defineComponent({
  /* eslint-disable */
  data() {
    return {
      map: null,
      markers: [] as any,
      markerCluster: null,
      iconURL: require('@/resources/assets/icons/marker-truck.png'),
    };
  },
  computed: {
    origin(): any {
      return this.currentLoad.stops.find((obj) => obj.type === 'PICKUP_INITIAL');
    },
    destiny(): any {
      return this.currentLoad.stops.find((obj) => obj.type === 'DELIVERY_FINAL');
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    currentLoadLenght(): any {
      return this.currentLoad.stops.length;
    },
  },
  // watch: {
  //   currentLoad() {
  //     if (this.currentLoad?.shipment?.currentTracking.id !== undefined) {
  //       this.addCustomMarker(
  //         this.map,
  //         this.currentLoad?.shipment?.currentTracking?.location.lat,
  //         this.currentLoad?.shipment?.currentTracking?.location.long,
  //         this.iconURL,
  //       );
  //     }
  //   },
  // },
  created() {
    if (this.currentLoad?.shipment?.currentTracking.id !== undefined) {
      const location = this.currentLoad?.shipment?.currentTracking?.location;
      this.addCustomMarker(this.map, location.lat, location.long, this.iconURL);
    }
  },
  mounted() {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: this.origin.location.lat, lng: this.origin.location.long },
      zoom: 5,
      // disableDefaultUI: true,
      // zoomControl: true,
      // scaleControl: true,
      streetViewControl: false,
      mapTypeControl: false,
      styles: [
        { featureType: 'all', elementType: 'labels.text.fill', stylers: [{ color: '#ffffff' }] },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#000000' }, { lightness: 13 }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#144b53' }, { lightness: 14 }, { weight: 1.4 }],
        },
        { featureType: 'landscape', elementType: 'all', stylers: [{ color: '#08304b' }] },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#0c4152' }, { lightness: 5 }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#0b434f' }, { lightness: 25 }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.fill',
          stylers: [{ color: '#000000' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#0b3d51' }, { lightness: 16 }],
        },
        { featureType: 'road.local', elementType: 'geometry', stylers: [{ color: '#000000' }] },
        { featureType: 'transit', elementType: 'all', stylers: [{ color: '#146474' }] },
        { featureType: 'water', elementType: 'all', stylers: [{ color: '#021019' }] },
      ],
    });

    this.initializeMarkers();

    let iconOrigin = require('@/resources/assets/icons/marker-pickup-initial.svg');
    let iconDestiny = require('@/resources/assets/icons/marker-delivery-final.svg');
    // let iconTruck = require('@/assets/icons/marker-driver-icon-circle-w.png');
    const iconURL = require('@/resources/assets/icons/marker-truck.png');

    // WINDOWS DATA
    // Esta es la información del marker que se va a mostrar, el contenido acepta HTML
    var infowindow = new google.maps.InfoWindow({
      content: `<strong>${this.$t('in-progress')}</strong>`,
    });

    if (this.currentLoad?.shipment?.currentTracking.id !== undefined) {
      // ORIGIN & TRUCK MARKERS
      // **********************

      var OriginMarker = new google.maps.Marker({
        position: {
          lat: this.origin.location.lat + 0.0004,
          lng: this.origin.location.long + 0.0004,
        },
        map: this.map,
        icon: new google.maps.MarkerImage(
          iconOrigin,
          null,
          null,
          null,
          new google.maps.Size(35, 35),
        ),
        title: 'Origin',
      });

      // var DestinyMarkerTruck = new google.maps.Marker({
      //   position: { lat: this.currentLoad?.shipment?.currentTracking?.location.lat, lng: this.currentLoad?.shipment?.currentTracking?.location.long },
      //   map: this.map,
      //   icon: new google.maps.MarkerImage(
      //     iconTruck,
      //     null,
      //     null,
      //     null,
      //     new google.maps.Size(30, 30),
      //   ),
      //   title: 'Destiny',
      // });

      // DestinyMarkerTruck.addListener('click', () => {
      //   infowindow.open(this.map, DestinyMarkerTruck);
      // });

      OriginMarker.addListener('click', () => {
        const infowindow = new google.maps.InfoWindow({
          content: `<p class="font-bold">${this.$t('label.pickup-initial')}</p>
                    <p>${this.origin.companyName}</p>
                    <p><strong>${this.origin.location.address}</strong></p>`,
        });
        infowindow.open(this.map, OriginMarker);
      });

      let directionsService = new google.maps.DirectionsService();
      let directionsDisplay = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: '#00ffff',
          strokeWeight: 7,
          zIndex: -1,
        },
      });
      directionsDisplay.setMap(this.map);

      let request: DirectionsRequest = {
        origin: new google.maps.LatLng(this.origin.location.lat, this.origin.location.long),
        destination: new google.maps.LatLng(
          this.currentLoad?.shipment?.currentTracking?.location.lat,
          this.currentLoad?.shipment?.currentTracking?.location.long,
        ),
        travelMode: 'DRIVING',
      };

      if (this.currentLoadLenght !== 2) {
        const waypoints = this.currentLoad.stops.filter(
          (point) => point.type !== 'DELIVERY_FINAL' && point.type !== 'PICKUP_INITIAL',
        );
        request.waypoints = waypoints.map((point) => {
          return {
            location: new google.maps.LatLng(point.location.lat, point.location.long),
            stopover: true,
          };
        });
      }

      directionsService.route(request, function (result, status) {
        if (status === 'OK') {
          directionsDisplay.setDirections(result);
        }
      });

      // ***********************
      // TRUCK & DESTINY MARKERS
      // ***********************

      // var OriginMarkerTruck = new google.maps.Marker({
      //   position: { lat: this.currentLoad?.shipment?.currentTracking?.location.lat + 0.0004, lng: this.currentLoad?.shipment?.currentTracking?.location.long + 0.0004 },
      //   map: this.map,
      //   icon: new google.maps.MarkerImage(iconTruck, null, null, null, new google.maps.Size(30, 30)),
      //   title: 'Origin',
      // });

      var DestinyMarker = new google.maps.Marker({
        position: { lat: this.destiny.location.lat, lng: this.destiny.location.long },
        map: this.map,
        icon: new google.maps.MarkerImage(
          iconDestiny,
          null,
          null,
          null,
          new google.maps.Size(35, 35),
        ),
        title: 'Destiny',
      });

      DestinyMarker.addListener('click', () => {
        const infowindow = new google.maps.InfoWindow({
          content: `<p class="font-bold">${this.$t('label.delivery-final')}</p>
                    <strong>${this.destiny.companyName}</strong>
                    <p><strong>${this.destiny.location.address}</strong></p>`,
        });
        infowindow.open(this.map, DestinyMarker);
      });
      // '#0B7A04DD'
      let directionsServiceBis = new google.maps.DirectionsService();
      let directionsDisplayBis = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: '#00ffff',
          strokeWeight: 7,
          zIndex: -1,
        },
      });
      directionsDisplayBis.setMap(this.map);

      let requestBis: DirectionsRequest = {
        origin: new google.maps.LatLng(
          this.currentLoad?.shipment?.currentTracking?.location.lat,
          this.currentLoad?.shipment?.currentTracking?.location.long,
        ),
        destination: new google.maps.LatLng(this.destiny.location.lat, this.destiny.location.long),
        travelMode: 'DRIVING',
      };

      if (this.currentLoadLenght !== 2) {
        const waypoints = this.currentLoad.stops.filter(
          (point) => point.type !== 'DELIVERY_FINAL' && point.type !== 'PICKUP_INITIAL',
        );
        requestBis.waypoints = waypoints.map((point) => {
          return {
            location: new google.maps.LatLng(point.location.lat, point.location.long),
            stopover: true,
          };
        });
      }

      directionsServiceBis.route(requestBis, function (result, status) {
        if (status === 'OK') {
          directionsDisplayBis.setDirections(result);
        }
      });
    } else {
      // Origin - Destiny - no truck - no traking
      var OriginMarker = new google.maps.Marker({
        position: {
          lat: this.origin.location.lat + 0.0004,
          lng: this.origin.location.long + 0.0004,
        },
        map: this.map,
        icon: new google.maps.MarkerImage(
          iconOrigin,
          null,
          null,
          null,
          new google.maps.Size(35, 35),
        ),
        title: 'Origin',
      });

      OriginMarker.addListener('click', () => {
        const infowindow = new google.maps.InfoWindow({
          content: `<p class="font-bold">${this.$t('label.pickup-initial')}</p>
                    <strong>${this.origin.companyName}</strong>
                    <p><strong>${this.origin.location.address}</strong></p>`,
        });
        infowindow.open(this.map, OriginMarker);
      });

      var DestinyMarker = new google.maps.Marker({
        position: { lat: this.destiny.location.lat, lng: this.destiny.location.long },
        map: this.map,
        icon: new google.maps.MarkerImage(
          iconDestiny,
          null,
          null,
          null,
          new google.maps.Size(35, 35),
        ),
        title: 'Destiny',
      });

      DestinyMarker.addListener('click', () => {
        const infowindow = new google.maps.InfoWindow({
          content: `<p class="font-bold">${this.$t('label.delivery-final')}</p>
                    <strong>${this.destiny.companyName}</strong></br>
                    <p><strong>${this.destiny.location.address}</strong></p>`,
        });
        infowindow.open(this.map, DestinyMarker);
      });

      let directionsService = new google.maps.DirectionsService();
      let directionsDisplay = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: '#00ffff',
          strokeWeight: 7,
          zIndex: -1,
        },
        // Color verde para la ruta #0B7A04DD
      });
      directionsDisplay.setMap(this.map);

      let request: DirectionsRequest = {
        origin: new google.maps.LatLng(this.origin.location.lat, this.origin.location.long),
        destination: new google.maps.LatLng(this.destiny.location.lat, this.destiny.location.long),
        travelMode: 'DRIVING',
      };

      if (this.currentLoadLenght !== 2) {
        const waypoints = this.currentLoad.stops.filter(
          (point) => point.type !== 'DELIVERY_FINAL' && point.type !== 'PICKUP_INITIAL',
        );
        request.waypoints = waypoints.map((point) => {
          return {
            location: new google.maps.LatLng(point.location.lat, point.location.long),
            stopover: true,
          };
        });
      }

      directionsService.route(request, function (result, status) {
        if (status === 'OK') {
          directionsDisplay.setDirections(result);
        }
      });
    }
  },
  methods: {
    addCustomMarker(map, lat, lng, iconURL) {
      let marker = new google.maps.Marker({
        position: { lat: Number(lat), lng: Number(lng) },
        icon: new google.maps.MarkerImage(iconURL, null, null, null, new google.maps.Size(40, 40)),
      });
      marker.addListener('click', () => {
        const infowindow = new google.maps.InfoWindow({
          content: `<p class="font-bold">${
            this.currentLoad.shipment?.assignedCompany?.company?.name
          }</p><p>${this.currentLoad.shipment?.assignedCompany?.company?.owner?.name} ${
            this.currentLoad.shipment?.assignedCompany?.company?.owner?.surname
          } ${''}</p><p>${this.currentLoad?.shipment?.currentTracking.location.city}, ${
            this.currentLoad?.shipment?.currentTracking.location.state.isoGoogle
          }</p>`,
        });
        infowindow.open(this.map, marker);
      });
      this.markers.push(marker);
      const markerCluster = new MarkerClusterer({ map: this.map, markers: this.markers });
    },
    initializeMarkers() {
      let pickup = require('@/resources/assets/icons/marker-pickup.svg');
      let deliver = require('@/resources/assets/icons/marker-delivery.svg');
      this.currentLoad.stops
        .filter((obj) => obj.type !== 'PICKUP_INITIAL' && obj.type !== 'DELIVERY_FINAL')
        .forEach((obj, index) => {
          const marker = new google.maps.Marker({
            icon: new google.maps.MarkerImage(
              obj.type === 'PICKUP' ? pickup : deliver,
              null,
              null,
              null,
              new google.maps.Size(30, 30),
            ),
            title: obj.type == 'PICKUP' ? `${'PICKUP'} ` : `${'DELIVERY'} `,
            label: {
              text: String(index + 1),
              fontSize: '20px',
              fontFamily: 'Nunito',
              // color: obj.type == 'PICKUP' ? '#2F3787' : '#f9b318',
              color: obj.type == 'PICKUP' ? '#ff000' : '#fff',
            },
            position: {
              lat: obj.location.lat + 0.0001 * index,
              lng: obj.location.long + 0.0001 * index,
            },
            map: this.map,
          });
          marker.addListener('click', () => {
            const infowindow = new google.maps.InfoWindow({
              content: `<p class="font-bold">
                ${obj.type === 'PICKUP' ? this.$t('pickup') : this.$t('delivery')}
                #${index + 1}</p>
                <p>${obj.companyName}</p>
                <p><strong>${obj.location.address}</strong></p>`,
            });
            infowindow.open(this.map, marker);
          });
          this.markers.push(marker);
        });

      const markerCluster = new MarkerClusterer({ map: this.map, markers: this.markers });
    },
  },
});
