import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto tabs-nav mb-10" }
const _hoisted_2 = { class: "flex justify-start 2xl:justify-center border-b-2 pb-3 space-x-6" }
const _hoisted_3 = { class: "whitespace-nowrap" }
const _hoisted_4 = { class: "whitespace-nowrap" }
const _hoisted_5 = { class: "whitespace-nowrap" }
const _hoisted_6 = { class: "whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserInformationForm = _resolveComponent("UserInformationForm")!
  const _component_DriverVehicleForm = _resolveComponent("DriverVehicleForm")!
  const _component_ContactDetailLanes = _resolveComponent("ContactDetailLanes")!
  const _component_ContactDetailInsurance = _resolveComponent("ContactDetailInsurance")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", {
          class: _normalizeClass(["relative", _ctx.currentView === 'user' ? 'relative active' : ''])
        }, [
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeCurrentView('user'))),
            class: "flex cursor-pointer"
          }, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('user')), 1)
          ])
        ], 2),
        _createElementVNode("li", {
          class: _normalizeClass(["relative", _ctx.currentView === 'driver&vehicle' ? 'relative active' : ''])
        }, [
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeCurrentView('driver&vehicle'))),
            class: "flex cursor-pointer"
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('driver-vehicle')), 1)
          ])
        ], 2),
        _createElementVNode("li", {
          class: _normalizeClass(["relative", _ctx.currentView === 'lanes' ? 'relative active' : ''])
        }, [
          _createElementVNode("a", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeCurrentView('lanes'))),
            class: "flex cursor-pointer"
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('service-preference')), 1)
          ])
        ], 2),
        _createElementVNode("li", {
          class: _normalizeClass(["relative", _ctx.currentView === 'insurance' ? 'relative active' : ''])
        }, [
          _createElementVNode("a", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeCurrentView('insurance'))),
            class: "flex cursor-pointer"
          }, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.user.company.type === 'CARRIER' ? _ctx.$t('carrier-certifications') : _ctx.$t('broker-operator')), 1)
          ])
        ], 2)
      ])
    ]),
    (_ctx.currentView === 'user')
      ? (_openBlock(), _createBlock(_component_UserInformationForm, {
          key: 0,
          contactInfo: _ctx.contactInfo,
          specialPermits: _ctx.specialPermits,
          onUpdate: _ctx.forwardUpdate,
          onUpdatePermits: _ctx.forwardUpdatePermits
        }, null, 8, ["contactInfo", "specialPermits", "onUpdate", "onUpdatePermits"]))
      : _createCommentVNode("", true),
    (_ctx.currentView === 'driver&vehicle')
      ? (_openBlock(), _createBlock(_component_DriverVehicleForm, {
          key: 1,
          onCreateLanes: _ctx.createLanes
        }, null, 8, ["onCreateLanes"]))
      : _createCommentVNode("", true),
    (_ctx.currentView === 'lanes')
      ? (_openBlock(), _createBlock(_component_ContactDetailLanes, {
          key: 2,
          lanes: _ctx.lanes,
          onUpdateLanes: _ctx.updateLanes
        }, null, 8, ["lanes", "onUpdateLanes"]))
      : _createCommentVNode("", true),
    (_ctx.currentView === 'insurance')
      ? (_openBlock(), _createBlock(_component_ContactDetailInsurance, {
          key: 3,
          userType: _ctx.user?.company?.type,
          onUpdateInsurances: _ctx.updateInsurances
        }, null, 8, ["userType", "onUpdateInsurances"]))
      : _createCommentVNode("", true)
  ], 64))
}