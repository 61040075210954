
import { defineComponent, reactive, toRefs, computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanUpdateMultipleLanesAdditionalStopInfo from '@/components/rfp/PlanUpdateMultipleLanesComponents/PlanUpdateMultipleLanesAdditionalStopInfo.vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';

interface planUpdateMultipleLanesStops {
  showForm: boolean;
  additionalStops: Array<any>;
}

export default defineComponent({
  name: 'PlanUpdateMultipleLanesStops',
  components: { PlanDefaultModal, PlanUpdateMultipleLanesAdditionalStopInfo, PlanButton },
  emits: ['close', 'change'],
  props: {
    defaultAdditionalStops: { type: Array as any, required: true },
    showAdditionalStops: { type: Boolean, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();

    const data = reactive<planUpdateMultipleLanesStops>({
      showForm: false,
      additionalStops: [],
    });

    const addStop = () => {
      data.additionalStops.push({
        type: 'PICKUP',
        airportStop: false,
        appoiment: 'NONE',
        executionWindowEndTime: null,
        executionWindowStartTime: null,
        initialObservations: '',
        lumperFee: false,
        lumperFeePrice: null,
        observations: '',
        portStop: false,
        location: {
          lat: 0,
          long: 0,
          zip: '',
          city: '',
          state: {
            id: 1,
            iso: '',
            country: {
              iso: '',
            },
            isoGoogle: '',
          },
        },
        place: '',
      });
    };

    const close = () => {
      emit('close');
    };

    const change = () => {
      emit('change', data.additionalStops);
    };

    const deleteStop = (index: number) => {
      data.additionalStops.splice(index, 1);
    };

    const updateStop = (stopData: any) => {
      data.additionalStops[stopData.position].googlePlaceId = stopData.location.googlePlaceId;
      data.additionalStops[stopData.position].type = stopData.location.type;
      data.additionalStops[stopData.position].location = stopData.location.location;
      data.additionalStops[stopData.position].place = stopData.location.place;
      console.log('updateStop: ', data.additionalStops[stopData.position]);

      change();
    };

    onMounted(() => {
      setTimeout(() => {
        console.log('onMounted: ', props.defaultAdditionalStops);
        data.additionalStops = props.defaultAdditionalStops;
      }, 500);
    });

    return {
      ...toRefs(data),
      addStop,
      close,
      deleteStop,
      updateStop,
    };
  },
});
