<template>
  <div class="load-detail-tracking-stop-point">
    <div class="point" :class="{'active-point': isActive}">
      <plan-icon v-if="isActive" name="check" is-svg icon-color="#151830" />
    </div>
    <div class="line" :class="{'active-line': isInProgress}"></div>
  </div>
</template>

<script>
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default {
  name: 'LoadDetailTranckingStopPoint',
  components: { PlanIcon },
  props: {
    isActive: { type: Boolean, required: true, default: false },
    isInProgress: { type: Boolean, required: true, default: false },
  }
};
</script>

<style scoped>

</style>