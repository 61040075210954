import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-center plan-gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_carrier_load_header = _resolveComponent("plan-carrier-load-header")!
  const _component_plan_carrier_load_sub_header = _resolveComponent("plan-carrier-load-sub-header")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_carrier_load_header_notification = _resolveComponent("plan-carrier-load-header-notification")!
  const _component_plan_carrier_load_details = _resolveComponent("plan-carrier-load-details")!
  const _component_plan_carrier_load_stop_data = _resolveComponent("plan-carrier-load-stop-data")!
  const _component_plan_carrier_load_bid = _resolveComponent("plan-carrier-load-bid")!
  const _component_plan_default_carrier_view = _resolveComponent("plan-default-carrier-view")!

  return (_ctx.loadData !== null)
    ? (_openBlock(), _createBlock(_component_plan_default_carrier_view, {
        key: 0,
        "show-footer": _ctx.loadData.status === 'QUOTING'
      }, {
        "header-content": _withCtx(() => [
          _createVNode(_component_plan_carrier_load_header)
        ]),
        "subheader-content": _withCtx(() => [
          _createVNode(_component_plan_carrier_load_sub_header),
          (_ctx.shipperHasSelectedYouNotification)
            ? (_openBlock(), _createBlock(_component_plan_carrier_load_header_notification, {
                key: 0,
                message: _ctx.$t('shipper-selected-you-perform-service'),
                class: "plan-mt-md"
              }, {
                buttons: _withCtx(() => [
                  _createVNode(_component_plan_button, {
                    onOnClick: _ctx.goToLoadAcceptation,
                    type: "primary-light",
                    label: _ctx.$t('confirm-your-availability'),
                    "full-rounded": ""
                  }, null, 8, ["onOnClick", "label"])
                ]),
                _: 1
              }, 8, ["message"]))
            : _createCommentVNode("", true)
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_plan_carrier_load_details),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stopsData, (stopData) => {
              return (_openBlock(), _createBlock(_component_plan_carrier_load_stop_data, {
                key: stopData.stop,
                "to-city": stopData.toCity,
                stop: stopData.stop
              }, null, 8, ["to-city", "stop"]))
            }), 128))
          ])
        ]),
        footer: _withCtx(() => [
          (_ctx.loadData.status === 'QUOTING')
            ? (_openBlock(), _createBlock(_component_plan_carrier_load_bid, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["show-footer"]))
    : _createCommentVNode("", true)
}