import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-562338d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex space-x-3 w-full" }
const _hoisted_2 = { class: "flex-row w-full" }
const _hoisted_3 = { class: "flex flex-row items-end w-full flex-wrap lg:flex-nowrap space-x-0 md:space-x-5 lg:space-x-5" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col w-full md:w-6/12 lg:w-2/3"
}
const _hoisted_5 = {
  key: 0,
  class: "flex flex-row space-x-3 relative"
}
const _hoisted_6 = ["for"]
const _hoisted_7 = ["for"]
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "relative flex flex-row"
}
const _hoisted_10 = { class: "relative inline-flex w-[4.5rem]" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  class: "flex flex-col w-full md:w-5/12 lg:w-1/3"
}
const _hoisted_13 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanModalLocation = _resolveComponent("PlanModalLocation")!
  const _component_plan_input_load_location = _resolveComponent("plan-input-load-location")!
  const _component_Popper = _resolveComponent("Popper")!
  const _component_PlanCountrySelector = _resolveComponent("PlanCountrySelector")!
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!
  const _component_google_autocomplete = _resolveComponent("google-autocomplete")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_DatePickerComponent = _resolveComponent("DatePickerComponent")!
  const _component_warehouse_in_stop_data = _resolveComponent("warehouse-in-stop-data")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PlanModalLocation, {
      lat: _ctx.stop?.location?.lat ?? null,
      long: _ctx.stop?.location?.long ?? null,
      showModal: _ctx.showModalCoordinates,
      onSetLocation: _ctx.setLocation,
      onClose: _ctx.toggleModalCoordinates
    }, null, 8, ["lat", "long", "showModal", "onSetLocation", "onClose"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (!_ctx.isConsignee)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_plan_input_load_location, {
                  onAddFacility: _ctx.openAddFacilityModal,
                  onSearchWarehouse: _ctx.findWarehouse,
                  onGetLocationFormated: _ctx.getLocationFormated,
                  onDeleteWarehouse: _ctx.handleDeleteWarehouseSelected,
                  country: _ctx.currentCountryIso,
                  location: _ctx.zipSelected,
                  "old-mode": ""
                }, null, 8, ["onAddFacility", "onSearchWarehouse", "onGetLocationFormated", "onDeleteWarehouse", "country", "location"]),
                false
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("label", {
                        for: _ctx.stop.type + _ctx.index,
                        class: "mb-1 text-bold"
                      }, _toDisplayString(`${_ctx.$t('country')}`), 9, _hoisted_6),
                      _createElementVNode("label", {
                        for: _ctx.stop.type + _ctx.index,
                        class: "mb-1 text-bold"
                      }, _toDisplayString(`${_ctx.$t(_ctx.typeStopObject?.label)} *`), 9, _hoisted_7),
                      (!_ctx.locationText)
                        ? (_openBlock(), _createBlock(_component_Popper, {
                            key: 0,
                            hover: "",
                            arrow: "",
                            class: "popper-padding-1 w-max popper-dark cursor-pointer absolute right-7 -bottom-9 z-10",
                            content: _ctx.$t('introduce-as-coordinates')
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                alt: "Crosshair icon",
                                class: "w-7 h-7 m-width-none",
                                src: _ctx.getAsset.icCrosshair,
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleModalCoordinates(true)))
                              }, null, 8, _hoisted_8)
                            ]),
                            _: 1
                          }, 8, ["content"]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                false
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        (_openBlock(), _createBlock(_component_PlanCountrySelector, {
                          key: 'country',
                          country: _ctx.currentCountryIso,
                          countrySelectorClasses: 'custom-border-left inline-flex border-gray-300 bg-gray-50 text-gray-900 h-[46px] items-center px-4 py-2 border text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150',
                          dataCountries: _ctx.countriesList,
                          name: 'country',
                          onCallbackClick: _cache[1] || (_cache[1] = (country) => _ctx.handleClick(country))
                        }, null, 8, ["country", "dataCountries"]))
                      ]),
                      (_ctx.locationText !== '')
                        ? (_openBlock(), _createBlock(_component_XCircleIcon, {
                            key: 0,
                            onClick: _ctx.clearInput,
                            class: "w-4 h-4 text-corp absolute top-1/2 right-0 transform -translate-x-1/2 -translate-y-1/2"
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Field, {
                        name: 'location' + _ctx.index,
                        rules: "required",
                        modelValue: _ctx.locationText,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.locationText) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_google_autocomplete, {
                            class: "custom-border-right h-[46px] bg-gray-50",
                            id: 'location' + _ctx.index,
                            name: 'location' + _ctx.index,
                            ref: 'location' + _ctx.index,
                            onPlacechanged: _ctx.getLocationFormated,
                            placeholder: _ctx.$t(_ctx.typeStopObject?.placehoder),
                            country: _ctx.defaultCountrySelected?.iso?.toLowerCase(),
                            types: '(regions)',
                            value: _ctx.locationText,
                            onHandleChange: _ctx.findWarehouse,
                            onClearInput: _ctx.clearInput,
                            onKeydown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showCoordinates = false))
                          }, null, 8, ["id", "name", "onPlacechanged", "placeholder", "country", "types", "value", "onHandleChange", "onClearInput"])
                        ]),
                        _: 1
                      }, 8, ["name", "modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.locationText && _ctx.showCoordinates)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 2,
                      class: "text-xs text-gray-500 font-bold flex ml-[4.5rem] cursor-pointer",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleModalCoordinates(true)))
                    }, [
                      _createElementVNode("img", {
                        alt: "Crosshair icon",
                        class: "w-4 h-4 m-width-none",
                        src: _ctx.getAsset.icCrosshair
                      }, null, 8, _hoisted_11),
                      _createTextVNode(" " + _toDisplayString(`${_ctx.stop.location.lat}, ${_ctx.stop.location.long}`), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ErrorMessage, {
                  name: 'location' + _ctx.index,
                  class: "text-rose-500"
                }, null, 8, ["name"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hideInputDates)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("label", {
                  for: 'datepicker' + _ctx.index,
                  class: "mb-1 text-bold"
                }, _toDisplayString(`${_ctx.$t('date')} *`), 9, _hoisted_13),
                _createVNode(_component_Field, {
                  rules: "required",
                  modelValue: _ctx.executionDate,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.executionDate) = $event)),
                  name: 'datepicker' + _ctx.index
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DatePickerComponent, {
                      customClass: "'text-gray-900 bg-gray-50 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full'",
                      modelValue: _ctx.executionDate,
                      "onUpdate:modelValue": [
                        _cache[5] || (_cache[5] = ($event: any) => ((_ctx.executionDate) = $event)),
                        _ctx.setDate
                      ],
                      maxDate: _ctx.maxDate,
                      minDate: _ctx.minDate
                    }, null, 8, ["modelValue", "maxDate", "minDate", "onUpdate:modelValue"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "name"]),
                _createVNode(_component_ErrorMessage, {
                  name: 'datepicker' + _ctx.index,
                  class: "text-rose-500"
                }, null, 8, ["name"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_warehouse_in_stop_data, {
          ref: `warehouseStopDataRef${_ctx.index}`,
          onPortOrAirport: _ctx.setupAirportorPort,
          onUpdateWarehouse: _ctx.setupWarehouse,
          onUpdateSelectedAppointment: _ctx.updateSelectedAppointment,
          onUpdateSelectedTime: _ctx.updateSelectedTime,
          onUpdateObservations: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('updateObservations', $event, _ctx.stop))),
          onUpdatePoNumber: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('updatePoNumber', $event, _ctx.stop))),
          "warehouse-id": 
          _ctx.stop?.warehouseTemplate?.id !== undefined
            ? _ctx.stop?.warehouseTemplate?.id
            : _ctx.selectedWarehouse !== -1
            ? _ctx.selectedWarehouse
            : -1
        ,
          "warehouse-data": _ctx.warehouseData,
          zip: _ctx.zipSelected,
          "default-country-id": _ctx.selectedCountry.id,
          "default-country": _ctx.selectedCountry?.iso,
          "default-prefix": _ctx.selectedCountry?.phonePrefix,
          "default-zipcode-id": _ctx.selectedZipcodeId,
          index: _ctx.index,
          isConsignee: _ctx.isConsignee
        }, null, 8, ["onPortOrAirport", "onUpdateWarehouse", "onUpdateSelectedAppointment", "onUpdateSelectedTime", "warehouse-id", "warehouse-data", "zip", "default-country-id", "default-country", "default-prefix", "default-zipcode-id", "index", "isConsignee"])
      ])
    ])
  ], 64))
}