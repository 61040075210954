import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-start plan-gap-sm relative" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex justify-end items-center plan-gap-xs plan-t-prim-100 plan-t-xxs italic"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_carrier_tracking_google_map = _resolveComponent("plan-carrier-tracking-google-map")!
  const _component_plan_carrier_tracking_history = _resolveComponent("plan-carrier-tracking-history")!
  const _component_plan_icon = _resolveComponent("plan-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_plan_carrier_tracking_google_map, {
      lat: _ctx.initialLocation.lat,
      lon: _ctx.initialLocation.lon,
      height: 250
    }, null, 8, ["lat", "lon"]),
    _createVNode(_component_plan_carrier_tracking_history),
    (_ctx.loadingInitialLocation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_plan_icon, {
            name: "spinner",
            "icon-color": "#8A8B97",
            size: "15",
            "is-svg": "",
            rotate: ""
          }),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('getting-your-location')) + "...", 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}