
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanInputLocation from '@/components/forms/PlanInputLocation.vue';
import { useStore } from 'vuex';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';

interface warehouseForm {
  name: string;
  country: string;
  zip: string;
  placeId: string;
  address: string;
  prefix: string;
  phone: number | null;
  contactPerson: string;
}

export default defineComponent({
  name: 'WarehouseForm',
  components: { PlanInputNumber, PlanSelect, PlanInputLocation, PlanInputText },
  emits: ['update'],
  props: {
    isUpdate: { type: Boolean, required: false, default: false },
    hasDefaultLocation: { type: Boolean, required: false, default: false },
    defaultZip: { type: String, required: false, default: '' },
    defaultPrefix: { type: String, required: false, default: '' },
    defaultCountry: { type: String, required: false, default: '' },
    defaultZipcodeId: { type: String, required: false, default: '' },
  },
  setup(props, { emit }) {
    const store = useStore();

    const data = reactive<warehouseForm>({
      name: '',
      country: props.defaultCountry.length > 0 ? props.defaultCountry : '',
      zip: props.defaultZip.length > 0 ? props.defaultZip : '',
      address: '',
      placeId: props.defaultZipcodeId.length > 0 ? props.defaultZipcodeId : '',
      prefix: props.defaultPrefix.length > 0 ? props.defaultPrefix : '',
      phone: null,
      contactPerson: '',
    });

    let country = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'us' : 'es';
    data.country = props.hasDefaultLocation ? props.defaultCountry : country;
    let prefix = store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? '+1' : '+34';
    data.prefix = props.hasDefaultLocation ? props.defaultPrefix : prefix;

    const prefixes = computed(() => {
      let result: Array<{ label: string; value: string }> = [];
      const northAmericaRegionCountries = ['CA', 'MX', 'US'];
      const region = store.getters['UserStore/getRegion'];

      store.getters['CountryStore/getCountries'].forEach((country: any) => {
        if (region === 'NORTH_AMERICA') {
          if (
            northAmericaRegionCountries.includes(country.iso) &&
            !result.includes({ label: `+ ${country.phonePrefix}`, value: country.phonePrefix }) &&
            !result.find((prefix) => prefix.value === country.phonePrefix)
          ) {
            result.push({ label: `+ ${country.phonePrefix}`, value: country.phonePrefix });
          }
        } else {
          if (!northAmericaRegionCountries.includes(country.iso))
            result.push({ label: `+ ${country.phonePrefix}`, value: country.phonePrefix });
        }
      });

      return orderPrefixAsc(result);
    });

    const orderPrefixAsc = (prefixes: any) => {
      return prefixes.sort((a, b) => {
        let prefixA = parseInt(a.value, 10);
        let prefixB = parseInt(b.value, 10);

        if (prefixA < prefixB) {
          return -1;
        }
        if (prefixA > prefixB) {
          return 1;
        }
        return 0; // Si son iguales
      });
    };

    const changePrefix = (newPrefix) => {
      data.prefix = newPrefix;
      update();
    };

    const update = () => {
      emit('update', {
        name: data.name,
        country: data.country,
        zip: data.zip,
        address: data.address,
        placeId: data.placeId,
        prefix: data.prefix,
        phone: data.phone,
        contactPerson: data.contactPerson,
      });
    };

    const updateInputLocation = (newData: any, isUpdate: boolean) => {
      data.country = newData.country;
      data.zip = newData.location;
      data.placeId = newData.placeId;
      if (!isUpdate) {
        store.getters['CountryStore/getCountries'].forEach((country: any) => {
          if (country.iso === data.country.toUpperCase()) data.prefix = '+' + country.phonePrefix;
        });
      }
      update();
    };

    if (props.isUpdate) {
      const warehouse = store.getters['LoadsStore/getSelectedWarehouse'];
      data.country =
        warehouse.location?.state?.country?.iso ??
        (store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? 'us' : 'es');
      data.name = warehouse.companyWarehouse;
      data.address = warehouse.address;
      data.zip = warehouse.zipCode;
      data.prefix = warehouse.phonePrefix;
      data.phone = Number(warehouse.phoneNumber);
      data.contactPerson = warehouse.contactName || '';
      update();
    }

    onBeforeUnmount(() => {
      data.country = '';
      data.name = '';
      data.address = '';
      data.zip = '';
      data.prefix = '';
      data.phone = null;
      data.contactPerson = '';
    });

    return {
      ...toRefs(data),
      prefixes,
      changePrefix,
      update,
      updateInputLocation,
    };
  },
});
