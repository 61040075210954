
import { defineComponent } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanRating from '@/components/badges/PlanRating.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

export default defineComponent({
  name: 'LoadDetailDelivered',
  components: { PlanButton, PlanRating, PlanButtonIcon, PlanAvatar, PlanIcon },
})
