
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { saveAs } from 'file-saver';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanModalShare from '@/components/modals/PlanModalShare.vue';
// Resources
import i18n from '@/resources/locales';
export default defineComponent({
  name: 'LoadDocuments',
  mixins: [BaseComponent],
  components: { PlanButton, PlanButtonIcon, PlanIcon, PlanModalShare },
  props: {
    sections: { type: Array as any, required: true },
  },
  setup() {
    const $t = i18n.global.t;
    const store = useStore();

    let deliveryStopID = ref('');
    let fileType = ref('');
    let seeSendModal = ref(false);
    let stopID = ref('');

    // Computed
    const currentLoad: any = computed(() => {
      return store.getters['LoadsStore/currentLoad'];
    });

    // Methods
    const download = async (doc) => {
      const response = await store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: doc.id,
        class: doc.entityClass,
      });
      const fileName = `${doc.displayName}#${doc.id}`;
      let file = document.createElement('a');
      file.href = URL.createObjectURL(response);
      file.download = `documents-${fileName}`;
      file.click();
    };

    const deleteFile = (id) => {
      store.dispatch('LoadsStore/deleteLoadMediaObject', id).then(() => {
        store.dispatch('LoadsStore/loadById', currentLoad.value?.id).then(() => {
          store.dispatch('LoadsStore/getDocsList', currentLoad.value?.id);
          BaseComponent.methods?.showToastSuccess($t('ranking-quote-delete'));
        });
      });
    };

    const toggleModalShare = (isOpen: boolean, doc: any) => {
      if (doc) {
        deliveryStopID.value = doc.multiDeliveryIds ? doc.multiDeliveryIds[0] : null;
        fileType.value = doc.type;
        stopID.value = doc.entityId ?? doc.stopId ?? null;
      }
      seeSendModal.value = isOpen;
    };

    const resendAction = (document) => {
      switch (document.type) {
        case 'RATE_CONFIRMATION':
          resendRate();
          break;
        case 'BOL':
        case 'POD':
          resendBOLPOD();
          break;
      }
    };

    const resendBOLPOD = () => {
      // Change this call when BACK is ready
      store
        .dispatch('UserStore/resendEmail', {
          id: currentLoad.value.shipment.id,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess($t('message-rate-confirmation-resend'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    };

    const resendRate = () => {
      store
        .dispatch('LoadsStore/postEmailForRate', {
          shipment: currentLoad.value.shipment.id,
        })
        .then(() => {
          BaseComponent.methods?.showToastSuccess($t('message-rate-confirmation-resend'));
        })
        .catch((err) => {
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
        });
    };

    const viewDocumentUploaded = async (id, entityClass) => {
      const result = await store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: id,
        class: entityClass,
      });

      const url = URL.createObjectURL(result);
      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-${''}`;
        const blob = new Blob([result], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
    };

    return {
      currentLoad,
      deliveryStopID,
      deleteFile,
      download,
      fileType,
      toggleModalShare,
      resendAction,
      resendRate,
      seeSendModal,
      stopID,
      viewDocumentUploaded,
    };
  },
});
