
import { defineComponent } from 'vue';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';

export default defineComponent({
  name: 'LoadBillingData',
  components: { PlanSelect, PlanInputText, PlanButton, PlanDefaultModal },
})
