
import { defineComponent, onMounted, watch, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'PlanGoogleMap',
  props: {
    lat: { type: Number, required: false, default: -25.363 },
    lon: { type: Number, required: false, default: 131.044 },
    height: { type: Number, required: false, default: 400 },
    newCoordinatesSelected: { type: Boolean, required: false, default: false }
  },
  setup (props) {
    const store = useStore()

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const actualPositionIcon = require('@/resources/assets/icons/marker-truck.png')
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    let originIcon = require('@/resources/assets/icons/marker-pickup-initial.svg')
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    let deliveryIcon = require('@/resources/assets/icons/marker-delivery-final.svg')

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      preserveViewport: true
    });

    const currentTrackedPosition = computed(() => {
      let lat = 0;
      let lon = 0;

      const currentLoadShipment = store.getters['LoadsStore/currentLoad']?.shipment;
      const currentLoadTracking = currentLoadShipment?.currentTracking?.location;

        if(currentLoadTracking){
          lat = currentLoadTracking.lat ? +currentLoadTracking.lat : lat;
          lon = currentLoadTracking.long ? +currentLoadTracking.long : lon;
        }

      return {
        lat: lat,
        lon: lon
      }
    });

    const currentLoad = computed(() => {
      let latDelivery = 0
      let lonDelivery = 0
      let latPickup = 0
      let lonPickup = 0

      store.getters['LoadsStore/currentLoad'].stops.forEach((stopData: any) => {
        if (stopData.type === 'PICKUP_INITIAL') {
          latPickup = stopData.location.lat
          lonPickup = stopData.location.long
        } else if (stopData.type === 'DELIVERY_FINAL') {
          latDelivery = stopData.location.lat
          lonDelivery = stopData.location.long
        }
      })

      return {
        pickup: {
          lat: latPickup,
          lon: lonPickup
        },
        delivery: {
          lat: latDelivery,
          lon: lonDelivery
        }
      }
    })

    const styles =  [
      { featureType: 'all', elementType: 'labels.text.fill', stylers: [{ color: '#ffffff' }] },
      {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#000000' }, { lightness: 13 }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{ color: '#000000' }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#144b53' }, { lightness: 14 }, { weight: 1.4 }],
      },
      { featureType: 'landscape', elementType: 'all', stylers: [{ color: '#08304b' }] },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#0c4152' }, { lightness: 5 }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [{ color: '#000000' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#0b434f' }, { lightness: 25 }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [{ color: '#000000' }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#0b3d51' }, { lightness: 16 }],
      },
      { featureType: 'road.local', elementType: 'geometry', stylers: [{ color: '#000000' }] },
      { featureType: 'transit', elementType: 'all', stylers: [{ color: '#146474' }] },
      { featureType: 'water', elementType: 'all', stylers: [{ color: '#021019' }] },
    ]

    function initMap() {
      const centerPosition = currentTrackedPosition.value.lat != 0
      && currentTrackedPosition.value.lon != 0
      && !props.newCoordinatesSelected
        ? { lat: currentTrackedPosition.value.lat, lng: currentTrackedPosition.value.lon }
        : { lat: props.lat, lng: props.lon };
      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          zoom: 7,
          center: centerPosition,
          streetViewControl: false,
          mapTypeControl: false,
          gestureHandling: 'cooperative',
          styles: styles
        }
      );

      new google.maps.Marker({
        position: centerPosition as any,
        map: map,
        icon: {
          url: actualPositionIcon,
          scaledSize: new google.maps.Size(30, 30)
        }
      })

      new google.maps.Marker({
        position: { lat: currentLoad.value.pickup.lat, lng: currentLoad.value.pickup.lon },
        map: map,
        icon: {
          url: originIcon,
          scaledSize: new google.maps.Size(30, 30)
        }
      })

      new google.maps.Marker({
        position: { lat: currentLoad.value.delivery.lat, lng: currentLoad.value.delivery.lon },
        map: map,
        icon: {
          url: deliveryIcon,
          scaledSize: new google.maps.Size(30, 30)
        }
      })

      directionsRenderer.setMap(map);

      if (currentLoad.value.pickup.lat && currentLoad.value.delivery.lat) {
        const request = {
          origin: new google.maps.LatLng(currentLoad.value.pickup.lat, currentLoad.value.pickup.lon),
          destination: new google.maps.LatLng(currentLoad.value.delivery.lat, currentLoad.value.delivery.lon),
          travelMode: google.maps.TravelMode.DRIVING
        };

        directionsService.route(request, (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          }
        });
      }
    }

    onMounted(() => {
      if (typeof google !== 'undefined' && google.maps) {
        initMap();
      } else {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCiii4tHhdactsEZuTbFY9C9eY6OsYa8WQ`;
        script.async = true;
        script.onload = () => initMap();
        document.head.appendChild(script);
      }
    });

    watch([() => props.lat, () => props.lon], () => {
      initMap()
    })

    return {
      currentLoad
    }
  }
});
