
import { defineComponent } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
export default defineComponent({
  name: 'PlanCarrierPreAssignedBox',
  components: { PlanButton },
  props: {
    position: { type: Number, required: true, default: 1 }
  }
})
