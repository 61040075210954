
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import FileContainer from '@/modules/planning/contacts/ContactsImport/ImportFileContainer.vue';
import ContactsTable from '@/modules/planning/contacts/ContactsImport/ContactsTable.vue';
import store from '@/services/store';

export default defineComponent({
  components: {
    FileContainer,
    ContactsTable,
  },
  props: {
    projectId: Number,
  },
  setup(props) {
    const currentType = ref('createFromInterface');

    const ShowImportFile = ref(false);
    const router = useRouter();
    const route = useRoute();

    onBeforeMount(() => {
      store.commit('UserStore/setCurrentContacts', []);
    });

    /** Computed */
    const currentContacts = computed(() => {
      return store.getters['UserStore/getCurrentContacts'];
    });

    const deleteItem = (index: any) => {
      store.getters['UserStore/getCurrentContacts'].splice(index, 1);
    };

    const hideImportFile = () => {
      ShowImportFile.value = false;
    };

    return {
      currentType,
      currentContacts,
      deleteItem,
      hideImportFile,
      ShowImportFile,
    };
  },
});
