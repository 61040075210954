
import { defineComponent, reactive, toRefs } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

export default defineComponent({
  name: 'PlanTable',
  components: { PlanIcon },
  props: {
    headers: { type: Array, required: false },
    orderBy: { type: String, required: false, default: '' }
  },
  emits: ['changeOrder'],
  setup (props, { emit }) {
    const data = reactive({
      orderType: 'DESC'
    })

    const changeOrder = () => {
      data.orderType = data.orderType === 'DESC' ? 'ASC':'DESC'
    }

    const selectHeaderOrder = (header) => {
      if (header.showOrder) {
        if (header.value === props.orderBy) changeOrder()
        if (header.value !== props.orderBy) data.orderType = 'DESC'
        emit('changeOrder', { value: header.value })
      }
    }

    return {
      ...toRefs(data),
      selectHeaderOrder
    }
  }
})
