
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
export default defineComponent({
  name: 'PlanInputCheckbox',
  components: { PlanIcon },
  props: {
    label: { type: String, required: false, default: '' },
    labelClass: { type: String, required: false, default: '' },
    icon: { type: String, required: false, default: '' },
    preValue: { type: Boolean, required: false },
    id: { type: Number, required: false, default: 0 }
  },
  emits: ['change', 'changeWithId'],
  setup (props, { emit }) {
    const data = reactive({
      value: false
    })

    const change = () => {
      data.value = !data.value
      emit('change', data.value)
      emit('changeWithId', { id: props.id, value: data.value})
    }

    if (props.preValue) data.value = true

    watch(() => props.preValue, (newValue, oldValue) => {
      data.value = newValue
    })

    return {
      ...toRefs(data),
      change
    }
  }
})
