
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import LoadDetailRightDocumentationDocumentDetail
  from '@/components/loads/detail/rightColumn/documents/LoadDetailRightDocumentationDocumentDetail.vue';
import PlanDefaultTable from '@/components/tables/PlanDefaultTable.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import moment from 'moment-timezone';
import PlanButton from '@/components/buttons/PlanButton.vue';
import LoadDetailDocumentsTable from '@/components/loads/detail/rightColumn/documents/LoadDetailDocumentsTable.vue';

export default defineComponent({
  name: 'LoadDetailRightDocuments',
  components: { LoadDetailDocumentsTable, PlanButtonIcon },
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive({
      showLoadDocs: false,
      showStopDocs: false,
      showOtherDocs: false,
      showInvoiceDocs: false
    })

    const docs = computed(() => {
      return store.getters['LoadsStore/getLoadInfo'].docs
    })

    const showDate = (date: string) => {
      return moment(date).format('DD MMM YYYY HH:MM')
    }

    return {
      ...toRefs(data),
      docs,
      showDate
    }
  }
})
