
import { defineComponent, reactive, toRefs } from 'vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanRating from '@/components/badges/PlanRating.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';

export default defineComponent({
  name: 'LoadDetailAccepting',
  components: { PlanButtonIcon, PlanIcon, PlanRating, PlanButton, PlanAvatar },
  setup () {
    const data = reactive({
      acceptingStates: [
        { isComplete: false, message: 'The Carrier has the cargo insurance, valid until May 09, 2024' },
        { isComplete: true, message: 'The Carrier is covered by liability insurance, valid until Jun 12, 2024' },
        { isComplete: true, message: 'Pending driver and vehicle information' }
      ]
    })

    return {
      ...toRefs(data)
    }
  }
})
