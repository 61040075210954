
import { defineComponent, computed, reactive, toRefs } from 'vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import { useStore } from 'vuex';
import PlanSelect from '@/components/forms/PlanSelect.vue';

interface planCarrierLoadAcceptationMyDataForm {
  myDataForm: {
    type: string,
    firstName: string,
    lastName: string,
    email: string,
    prefix: string,
    phone: null | number,
    cif: string,
    companyName: string,
    companyAddress: string,
    dotNumber: string,
    mcNumber: string,
  }
}
export default defineComponent({
  name: 'PlanCarrierLoadAcceptationMyDataForm',
  components: { PlanSelect, PlanInputText },
  setup () {
    const store = useStore()

    const data = reactive<planCarrierLoadAcceptationMyDataForm>({
      myDataForm: {
        type: '',
        firstName: '',
        lastName: '',
        email: '',
        prefix: '',
        phone: null,
        cif: '',
        companyName: '',
        companyAddress: '',
        dotNumber: '',
        mcNumber: ''
      }
    })

    const actualRegion = computed(() => {
      return store.getters['UserStore/getRegion']
    })

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const changeCarrierType = async () => {
      updateMyDataInStore()
      await store.dispatch('CompanyStore/putCompanyUpdateType', {
        id: store.getters['UserStore/getCurrentUser'].company.id,
        type: { type: data.myDataForm.type }
      })
    }

    const changePrefix = (newPrefix: string) => {
      data.myDataForm.prefix = newPrefix
      updateMyDataInStore()
    }

    const getUser = () => {
      data.myDataForm.type = store.getters['CarrierStore/getMyDataForm'].type
      data.myDataForm.firstName = store.getters['CarrierStore/getMyDataForm'].firstName
      data.myDataForm.lastName = store.getters['CarrierStore/getMyDataForm'].lastName
      data.myDataForm.email = store.getters['CarrierStore/getMyDataForm'].email
      data.myDataForm.phone = store.getters['CarrierStore/getMyDataForm'].phone
      data.myDataForm.prefix = store.getters['CarrierStore/getMyDataForm'].prefix ? store.getters['CarrierStore/getMyDataForm'].prefix : ''
      data.myDataForm.cif = store.getters['CarrierStore/getMyDataForm'].cif
      data.myDataForm.companyName = store.getters['CarrierStore/getMyDataForm'].companyName
      data.myDataForm.companyAddress = store.getters['CarrierStore/getMyDataForm'].companyAddress
      data.myDataForm.dotNumber = store.getters['CarrierStore/getMyDataForm'].dotNumber
      data.myDataForm.mcNumber = store.getters['CarrierStore/getMyDataForm'].mcNumber
    }

    const updateMyDataInStore = () => {
      store.commit('CarrierStore/setMyDataForm', {
        hasModifications: true,
        type: data.myDataForm.type,
        firstName: data.myDataForm.firstName,
        lastName: data.myDataForm.lastName,
        email: data.myDataForm.email,
        prefix: data.myDataForm.prefix,
        phone: data.myDataForm.phone,
        cif: data.myDataForm.cif,
        companyName: data.myDataForm.companyName,
        companyAddress: data.myDataForm.companyAddress,
        dotNumber: data.myDataForm.dotNumber,
        mcNumber: data.myDataForm.mcNumber
      })
    }

    const prefixList = computed(() => {
      return store.getters['CarrierStore/getRegionPrefixes']
    })

    getUser()

    return {
      ...toRefs(data),
      actualRegion,
      actualWidth,
      changeCarrierType,
      changePrefix,
      updateMyDataInStore,
      prefixList
    }
  }
})
