
import { defineComponent, reactive, toRefs } from 'vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import LoadFormSpecialRequirements from '@/components/loads/form/LoadFormSpecialRequirements.vue';
import LoadFormStop from '@/components/loads/form/LoadFormStop.vue';
import LoadFormVehicle from '@/components/loads/form/LoadFormVehicle.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import LoadBillingData from '@/components/loads/detail/LoadBillingData.vue';

interface loadForm {
  additionalStops: Array<any>,
  loadForm: {
    po: string,
    reference: string
  },
  showBillingData: boolean
}

export default defineComponent({
  name: 'LoadForm',
  components: { LoadBillingData, PlanInputText, PlanButtonIcon, LoadFormVehicle, LoadFormStop, LoadFormSpecialRequirements, PlanButton },
  setup () {
    const data = reactive<loadForm>({
      additionalStops: [],
      loadForm: {
        po: '',
        reference: ''
      },
      showBillingData: false
    })

    const addAdditionalStop = () => {
      data.additionalStops.push({ id: 'id_' + Date.now() })
    }

    const removeAdditionalStop = (index: number) => {
      data.additionalStops.splice(index, 1)
    }

    return {
      ...toRefs(data),
      addAdditionalStop,
      removeAdditionalStop
    }

  }
})
