
import { computed, defineComponent, reactive, toRefs } from 'vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import { useStore } from 'vuex';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

interface loadFormCommodity {
  commodity: string,
  weight: number | null,
  units: number
}

export default defineComponent({
  name: 'LoadFormCommodity',
  components: { PlanIcon, PlanSelect, PlanInputNumber, PlanInputText, PlanButtonIcon },
  props: {
    index: { type: Number, required: true, default: -1 },
    showTrash: { type: Boolean, required: false, default: false }
  },
  emits: ['remove'],
  setup (props, { emit }) {
    const store = useStore()

    const data = reactive<loadFormCommodity>({
      commodity: '',
      weight: null,
      units: 0
    })

    const unitTypes = computed(() => {
      let result: Array<{label: string, value: string}> = []
      store.getters['LoadsStore/packageTypes'].FTL.forEach(function(unit: any) {
        result.push({
          label: unit.fullName,
          value: unit.id
        })
      })
      return result
    })

    const remove = () => {
      emit('remove', props.index)
    }

    return {
      ...toRefs(data),
      unitTypes,
      remove
    }
  }
})
