import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex justify-start items-start plan-gap-xl" }
const _hoisted_2 = { class: "flex flex-col plan-gap-sm w-1/3" }
const _hoisted_3 = { class: "w-full flex justify-between items-start box-content plan-gap-sm" }
const _hoisted_4 = { class: "w-full flex flex-col plan-gap-sm" }
const _hoisted_5 = { class: "w-full flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_select = _resolveComponent("plan-select")!
  const _component_plan_input_number = _resolveComponent("plan-input-number")!
  const _component_load_form_commodity = _resolveComponent("load-form-commodity")!
  const _component_plan_button = _resolveComponent("plan-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_plan_select, {
        label: "Vehicle",
        value: "",
        items: _ctx.vehicles,
        placeholder: "Select a vehicle",
        mandatory: ""
      }, null, 8, ["items"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_plan_input_number, {
          "model-value": _ctx.minTemp,
          "onUpdate:model-value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.minTemp) = $event)),
          label: "Min. temp. ºF",
          mandatory: ""
        }, null, 8, ["model-value"]),
        _createVNode(_component_plan_input_number, {
          "model-value": _ctx.maxTemp,
          "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.maxTemp) = $event)),
          label: "Max. temp. ºF",
          mandatory: ""
        }, null, 8, ["model-value"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commodities, (commodity, index) => {
        return (_openBlock(), _createBlock(_component_load_form_commodity, {
          key: index,
          onRemove: _ctx.removeCommodity,
          index: index,
          "show-trash": index === 0
        }, null, 8, ["onRemove", "index", "show-trash"]))
      }), 128)),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_plan_button, {
          onOnClick: _ctx.addCommodity,
          type: "primary-white",
          label: "Add more freight"
        }, null, 8, ["onOnClick"])
      ])
    ])
  ]))
}