
import { defineComponent, computed, reactive, toRefs, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import i18n from '@/resources/locales';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import PlanFlag from '@/components/icons/PlanFlag.vue';
import { localAssets } from '@/resources/assets/assets';
import PlanDefaultModal from '@/components/defaults/PlanDefaultModal.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import { debounce } from 'lodash';

interface planInputLocation {
  addressData: object;
  coordinates: {
    latitude: string;
    longitude: string;
  };
  loadingLocation: boolean;
  locations: Array<any>;
  showCoordinates: boolean;
  showFlags: boolean;
  showResults: boolean;
  selectedCountry: string;
  selectedFromGoogle: boolean;
  selectedLocation: string;
  selectedPlaceId: string;
  zipToFind: string;
  thereAreChanges: boolean;
}

export default defineComponent({
  name: 'PlanInputLocation',
  components: { PlanIcon, PlanFlag, PlanButton },
  props: {
    mandatory: { type: Boolean, required: false, default: false },
    type: { type: String, required: false, default: 'stop' },
    preCountry: { type: String, required: false, default: '' },
    preLocation: { type: String, required: false, default: '' },
    showCheck: { type: Boolean, required: false, default: false },
    hideLabel: { type: Boolean, required: false, default: false },
    oldMode: { type: Boolean, required: false, default: false },
    transparent: { type: Boolean, required: false, default: false },
    isDisabled: { type: Boolean, required: false, default: false },
  },
  emits: ['change'],
  setup(props, { emit }) {
    let debounceTimer;
    const store = useStore();
    const $t = i18n.global.t;

    const data = reactive<planInputLocation>({
      addressData: {},
      coordinates: {
        latitude: '0',
        longitude: '0',
      },
      loadingLocation: false,
      locations: [],
      showCoordinates: false,
      showFlags: false,
      showResults: false,
      selectedCountry: '',
      selectedFromGoogle: false,
      selectedLocation: '',
      selectedPlaceId: '',
      thereAreChanges: false,
      zipToFind: '',
    });

    const flagStyle = computed(() => {
      if (props.oldMode) return 'height: 46px;  background: #f9fafb; width: 75px';
      if (props.transparent) return '';
      return 'height: 42px; width: 75px';
    });

    const flags = computed(() => {
      let result: Array<any> = [];
      const countries = store.getters['CountryStore/getCountries'];

      result = countries
        .filter(
          (item) =>
            item?.region?.name.includes(store.getters['UserStore/getRegion']) &&
            data.selectedCountry !== item.iso,
        )
        .map((obj) => obj.iso.toLowerCase());

      return result;
    });

    const label = computed(() => {
      if (props.type === 'pickup') return $t('pickup-location');
      if (props.type === 'delivery') return $t('delivery-location');
      return $t('stop-location');
    });

    const placeholder = computed(() => {
      if (props.type === 'pickup') return $t('placeholder-enter-your-origin-zip');
      if (props.type === 'delivery') return $t('placeholder-enter-your-destination-zip');
      return $t('placeholder-enter-your-zip-code');
    });

    const validationResult = computed(() => {
      let result = '';
      if (data.thereAreChanges) {
        result = data.selectedLocation.length > 0 ? '' : 'The field cannot be empty';
      }
      return result;
    });
    const change = (isUpdate, fromClick) => {
      emit(
        'change',
        {
          ...data.addressData,
          country: data.selectedCountry,
          location: data.selectedLocation,
          placeId: data.selectedPlaceId,
          zipSelected: data.zipToFind,
        },
        isUpdate,
        fromClick,
      );
    };

    const changeCountry = (isoCountry: string) => {
      data.selectedCountry = isoCountry;
      data.selectedLocation = '';
      change(false, true);
    };

    const changeLocation = (location: any, isUpdate: boolean, fromClick: boolean) => {
      data.thereAreChanges = true;
      data.selectedFromGoogle = true;
      if (!isUpdate && isManualUpdate) {
        data.selectedLocation = location.address;
      }
      data.selectedPlaceId = location.placeId;
      data.zipToFind = location.mainText;
      // getPlaceId()
      change(isUpdate, fromClick);
    };

    const debounce = (func, delay) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(func, delay);
    };

    const handleInput = () => {
      data.selectedFromGoogle = false;
      debounce(() => {
        getAutocompleteGoogle(false);
      }, 1000);
    };

    const getPlaceId = () => {
      data.loadingLocation = true;
      store
        .dispatch('LoadsStore/getPlaceDetailsGoogle', {
          placeId: data.selectedPlaceId,
        })
        .then((response) => {
          //
        });
    };

    const createAdressData = (data) => {
      const addressComponents = data.address_components;
      const addressObject = {
        postal_code: '',
        sublocality: '',
        locality: '',
        administrative_area_level_2: '',
        administrative_area_level_1: '',
        country: '',
        place_id: data.place_id,
        latitude: data?.geometry?.location?.lat,
        longitude: data?.geometry?.location?.lng,
      };

      addressComponents.forEach((component) => {
        if (component.types.includes('postal_code')) {
          addressObject.postal_code = component.long_name;
        }
        if (
          component.types.includes('sublocality') ||
          component.types.includes('sublocality_level_1')
        ) {
          addressObject.sublocality = component.long_name;
        }
        if (component.types.includes('locality')) {
          addressObject.locality = component.long_name;
        }
        if (component.types.includes('administrative_area_level_2')) {
          addressObject.administrative_area_level_2 = component.long_name;
        }
        if (component.types.includes('administrative_area_level_1')) {
          addressObject.administrative_area_level_1 = component.short_name;
        }
        if (component.types.includes('country')) {
          addressObject.country = component.long_name;
        }
      });

      return addressObject;
    };

    data.selectedCountry = store.getters['UserStore/getRegion'] === 'EUROPE' ? 'es' : 'us';
    const getAutocompleteGoogle = (fromWatch) => {
      data.loadingLocation = true;
      data.zipToFind = data.selectedLocation;
      store
        .dispatch('LoadsStore/getAutocompleteGoogle', {
          zip: data.selectedLocation,
          country: data.selectedCountry,
        })
        .then(async (response) => {
          if (!response?.data[0]?.place_id) {
            return;
          }
          await store
            .dispatch('LoadsStore/getPlaceDetailsGoogle', {
              placeId: response?.data[0]?.place_id,
            })
            .then((response) => {
              data.addressData = createAdressData(response.data);
            });

          let result: Array<any> = [];
          response.data.forEach(function (responseResult: any) {
            result.push({
              address: responseResult.description,
              mainText: responseResult.structured_formatting.main_text,
              placeId: responseResult.place_id,
            });
          });
          data.locations = result;

          let resultSelected = result.find((place) => place.placeId === data.selectedPlaceId);
          if (!resultSelected) {
            resultSelected = result[0];
          }
          data.zipToFind = resultSelected.mainText;
          if (props.preLocation.length > 0 && result.length > 0) {
            changeLocation(resultSelected, fromWatch ? false : true, false);
          }
          data.loadingLocation = false;
        })
        .finally(() => {
          data.loadingLocation = false;
        });
    };

    if (props.preCountry.length > 0) data.selectedCountry = props.preCountry.toLowerCase();
    if (props.preLocation.length > 0) {
      data.selectedLocation = props.preLocation + '';
      getAutocompleteGoogle(true);
    }

    let isManualUpdate = false;

    watch(
      () => props.preLocation,
      (newValue, oldValue) => {
        if (newValue.length > 0 && newValue !== oldValue) {
          if (!isManualUpdate) {
            isManualUpdate = true;
            data.selectedLocation = newValue + '';
            getAutocompleteGoogle(true);
          }
        }
      },
    );

    let isManualUpdateCountry = false;

    watch(
      () => props.preCountry,
      (newValue, oldValue) => {
        if (props.preCountry.length > 0) data.selectedCountry = props.preCountry.toLowerCase();
        if (props.preLocation.length > 0) {
          if (!isManualUpdateCountry) {
            isManualUpdateCountry = true;
            data.selectedLocation = props.preLocation + '';
            getAutocompleteGoogle(true);
          }
        }
      },
    );

    return {
      ...toRefs(data),
      flags,
      flagStyle,
      label,
      placeholder,
      validationResult,
      changeCountry,
      changeLocation,
      handleInput,
      getAutocompleteGoogle,
    };
  },
});
