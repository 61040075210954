import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "truncate" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex flex-col plan-gap-md"
}
const _hoisted_4 = { class: "truncate" }
const _hoisted_5 = {
  key: 1,
  class: "w-full flex flex-col plan-gap-md"
}
const _hoisted_6 = { class: "rounded plan-b-sec-50 flex justify-between items-center plan-p-sm" }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "plan-t-xxs truncate plan-t-prim-100" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = { class: "w-full flex flex-col" }
const _hoisted_11 = { class: "plan-t-xxs plan-t-prim-100 text-right" }
const _hoisted_12 = { class: "w-full flex justify-end items-center plan-gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plan_button_icon = _resolveComponent("plan-button-icon")!
  const _component_popper = _resolveComponent("popper")!
  const _component_plan_text_button = _resolveComponent("plan-text-button")!
  const _component_plan_input_text_area = _resolveComponent("plan-input-text-area")!
  const _component_plan_input_file = _resolveComponent("plan-input-file")!
  const _component_plan_button = _resolveComponent("plan-button")!
  const _component_plan_carrier_default_modal = _resolveComponent("plan-carrier-default-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_popper, {
      placement: "left",
      hover: "",
      class: "plan-carrier-fixed-button-popper",
      offsetDistance: "2"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('notify-incidence')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_plan_button_icon, {
          onOnClick: _ctx.openModal,
          icon: "simple-alert",
          type: "error",
          color: "white",
          style: {"min-width":"38px"}
        }, null, 8, ["onOnClick"])
      ]),
      _: 1
    }),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_plan_carrier_default_modal, {
          key: 0,
          onClose: _ctx.close,
          title: _ctx.$t('notify-incidence'),
          size: "sm-auto",
          "hide-close-button": ""
        }, {
          content: _withCtx(() => [
            (_ctx.selectedStop.value === -1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('select-stop')) + ":", 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stops, (stop) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: stop.value,
                      class: "rounded border plan-border-prim-50 plan-p-sm flex justify-between items-center plan-p-md"
                    }, [
                      _createElementVNode("p", _hoisted_4, _toDisplayString(stop.label), 1),
                      _createVNode(_component_plan_text_button, {
                        onOnClick: ($event: any) => (_ctx.selectedStop = { label: stop.label, value: stop.value }),
                        label: _ctx.$t('select')
                      }, null, 8, ["onOnClick", "label"])
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('selected-stop')), 1),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.selectedStop.label), 1)
                    ]),
                    _createVNode(_component_plan_button_icon, {
                      onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedStop = { label: '', value: -1 })),
                      icon: "close",
                      type: "transparent-gray-800"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_plan_input_text_area, {
                      "model-value": _ctx.incidence,
                      "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.incidence) = $event)),
                      label: _ctx.$t('describe-incidence'),
                      "max-characters": 200,
                      rows: 4
                    }, null, 8, ["model-value", "label"]),
                    _createElementVNode("p", _hoisted_11, _toDisplayString(200 - _ctx.incidence.length) + " " + _toDisplayString(_ctx.$t('characters-left')), 1)
                  ]),
                  _createVNode(_component_plan_input_file, {
                    onChange: _ctx.changeFiles,
                    types: ['application/pdf', 'image/*'],
                    maxSize: 10,
                    multiple: ""
                  }, null, 8, ["onChange"])
                ]))
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_plan_button, {
                onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModal = false)),
                disabled: _ctx.loadingPost,
                type: "cancel",
                label: _ctx.$t('cancel')
              }, null, 8, ["disabled", "label"]),
              _createVNode(_component_plan_button, {
                onOnClick: _ctx.postNotifyIncidence,
                type: "primary",
                loading: _ctx.loadingPost,
                disabled: _ctx.selectedStop.value === -1 || _ctx.incidence.length === 0,
                label: _ctx.$t('send'),
                style: {"min-width":"100px"}
              }, null, 8, ["onOnClick", "loading", "disabled", "label"])
            ])
          ]),
          _: 1
        }, 8, ["onClose", "title"]))
      : _createCommentVNode("", true)
  ]))
}