
import { defineComponent, computed, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import PlanSelect from '@/components/forms/PlanSelect.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import LoadFormCommodity from '@/components/loads/form/LoadFormCommodity.vue';
import PlanInputText from '@/components/forms/PlanInputText.vue';
import PlanInputNumber from '@/components/forms/PlanInputNumber.vue';

export default defineComponent({
  name: 'LoadFormVehicle',
  components: { PlanInputNumber, PlanSelect, LoadFormCommodity, PlanButton },
  setup () {
    const store = useStore()

    const data = reactive({
      commodities: [
        { commodity: null, weight: null, unit: null, type: null }
      ],
      minTemp: 0,
      maxTemp: 0
    })

    const vehicles = computed(() => {
      let result: Array<{label: string, value: number|string}> = []
      store.getters['LoadsStore/getTrailerTypes'].forEach(function(vehicle: any) {
        result.push({
          label: vehicle.description,
          value: vehicle.id
        })
      })
      return result
    })

    const addCommodity = () => {
      data.commodities.push({ commodity: null, weight: null, unit: null, type: null })
    }

    const removeCommodity = (index: number) => {
      data.commodities.splice(index, 1)
    }

    store.dispatch('LoadsStore/packageTypes')

    return {
      ...toRefs(data),
      vehicles,
      addCommodity,
      removeCommodity
    }
  }
})
