
import { defineComponent, computed } from 'vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';
import { useStore } from 'vuex';
import moment from 'moment'
import PlanFormattedNumber from '@/components/labels/PlanFormattedNumber.vue';

export default defineComponent({
  name: 'PlanCarrierLoadStopData',
  components: { PlanFormattedNumber, PlanIcon },
  props: {
    type: { type: String, required: false, default: 'pickup' },
    position: { type: Number, required: false, default: 0 },
    stop: { type: Object, required: true },
    toCity: { type: String, required: true, default: '' }
  },
  setup (props) {
    const store = useStore()

    const actualWidth = computed(() => {
      return store.getters['CarrierStore/getActualWidth']
    })

    const appointmentDate = computed(() => {
      return store.getters['UserStore/getRegion'] === 'NORTH_AMERICA' ? moment(props.stop.executionDate).format('MMM DD, YYYY') : moment(props.stop.executionDate).format('DD MMM, YYYY')
    })

    const loadData = computed(() => {
      return store.getters['CarrierStore/getLoadData']
    })

    return {
      actualWidth,
      appointmentDate,
      loadData
    }
  }

})

